.table-tabs {
    background: $white;

    .nav {
        padding: 0 rem(20px);
        gap: rem(12px);

        @media (min-width: 768px) {
            gap: rem(24px);
        }
    }

    .nav-link {
        position: relative;
        display: inline-flex;
        align-items: center;
        color: $dark;
        min-height: rem(50px);
        padding: 0;
        transition: color .2s ease-in-out;

        &::after {
            content: "";
            display: block;
            width: 100%;
            height: rem(3px);
            border-radius: rem(2px);
            background: $blue;
            position: absolute;
            left: 0;
            bottom: 0;
            opacity: 0;
            transition: opacity .2s ease-in-out;
        }

        &.active {
            background: transparent;
            color: $blue;

            &::after {
                opacity: 1;
            }
        }
    }

    .nav-link__text {
        display: none;
        margin-left: rem(10px);

        @media (min-width: 768px) {
            display: inline-block;
        }
    }


    table.expenses-table tr:first-child th:first-child,
    table.dashboard-table tr:first-child th:first-child,
    table.products-table tr:first-child th:first-child {
        border-top-left-radius: 0;
    }

    table.expenses-table tr:first-child th:last-child,
    table.dashboard-table tr:first-child th:last-child,
    table.products-table tr:first-child th:last-child {
        border-top-right-radius: 0;
        padding-right: rem(20px);
    }

    .expenses-wrapper .expenses-table thead > tr > th:first-child,
    .expenses-wrapper .products-table thead > tr > th:first-child,
    .expenses-wrapper .dashboard-table thead > tr > th:first-child {
        border-left: 0;
        padding-left: rem(20px);
    }

    table.expenses-table tr th:last-child,
    table.expenses-table tr td:last-child,
    table.dashboard-table tr th:last-child,
    table.dashboard-table tr td:last-child,
    table.products-table tr th:last-child,
    table.products-table tr td:last-child {
        border-right: 0;
        padding-right: rem(20px);
    }

    .expenses-wrapper .table-condensed.dashboard-table tbody tr td:last-child {
        padding-right: rem(20px);
    }

    table.expenses-table tr td:first-child,
    table.dashboard-table tr td:first-child,
    table.products-table tr td:first-child {
        border-left: 0;
        padding-left: rem(20px);
    }

    .status-tag {
        width: 100%;
    }

    .warehouse-table td:first-child,
    .warehouse-table th:first-child {
        border-left: 0;
    }

    .warehouse-table td:last-child,
    .warehouse-table th:last-child,
    .warehouse-table td:nth-child(12),
    .warehouse-table th:nth-child(12) {
        border-right: 0;
    }
}

.table-tabs__ico {
    flex-shrink: 0;
}

.table-accordion__trigger {
    flex-shrink: 0;
    width: rem(15px);
    height: 100%;
    border: 0;
    background: transparent;
    padding: 0 0 0 rem(2px);
    display: inline-flex;
    align-items: center;

    &::after {
        content: "";
        width: 0;
        height: 0;
        border-color: transparent transparent transparent $blue;
        border-style: solid;
        border-width: rem(4px) 0 rem(4px) rem(6px);
        flex-shrink: 0;
        transition: transform .3s ease-in-out 0s;
    }

    &[aria-selected=true]::after {
        transform: rotate(90deg);
    }

    &::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.admin-users-table {
    .table-accordion__trigger::before {
        width: 1em;
    }
}

.admin-transactions-table {
    th,
    td {
        width: auto;
        min-width: 0;
    }
}

.table-tabs__actions {
    padding: 0;
    margin-left: auto;
    min-height: rem(50px);
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    gap: rem(12px);

    @media (min-width: 768px) {
        gap: rem(24px);
    }

    .input {
        margin: 0;
    }

    .btn-link {
        position: relative;
        // width: rem(30px);
        // height: rem(30px);
    }

    .btn-link__ico {
        font-size: rem(16px);
    }

    .btn-link__text {
        display: none;

        @media (min-width: 992px) {
            display: inline;
        }
    }
}

@media (min-width: 576px) {
    .table-tabs__actions {
        margin-left: auto;
    }
}


.expenses-wrapper .table-condensed.dashboard-table tbody .table-accordion__content {
    .product_wrapper {
        padding-left: rem(20px);
    }
}

.products-ungrouped {
    .product_wrapper {
        padding-left: rem(20px);
    }
}

.products-table {
    .products-ungrouped {
        .product_wrapper {
            padding-left: 0;
        }
    }
}

@media (min-width: 768px) {
    .table-tabs {
        border-radius: rem(8px);
        border: 1px solid $border_color;

        table.expenses-table tr th,
        table.expenses-table tr td,
        table.dashboard-table tr th,
        table.dashboard-table tr td,
        table.products-table tr th,
        table.products-table tr td {
            border-right: 1px solid $border_color;
        }

        .expenses-wrapper .table-condensed.dashboard-table tbody tr td:last-child {
            padding-right: rem(5px);
        }

        .nav-link {
            padding: rem(8px) 0;

            // &::after {
            //     width: calc(100% - rem(40px));
            //     left: rem(20px);
            // }
        }
    }

}

@media (min-width: 861px) {
    .products-table {
        .products-ungrouped {
            .product_wrapper {
                padding-left: rem(32px);
            }
        }
    }
}

.products-grouped.active {
    background: #fbfbfb;
}

.table-accordion__content {
    background: #fbfbfb;
}

.products-grouped td {
    position: relative;
}

.modal-basic {
    .modal-dialog {

        @media (min-width: 576px) {
            max-width: rem(460px);
        }
    }

    .modal-header {
        min-height: rem(50px);
        padding: rem(20px);
        color: $white;
        border-radius: rem(8px) rem(8px) 0 0;
        background: linear-gradient(92.5deg, #425AF0 0.41%, #51B1FA 100%);
        border: 0;
        justify-content: flex-start;
        align-items: center;
    }

    .modal-back,
    .modal-close {
        width: rem(38px);
        height: rem(38px);
        background: transparent;
        color: $white;
        border: 0;
        padding: 0;
        font-size: rem(16px);
        margin: rem(-11px);
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    .modal-back {
        display: none;
        margin-right: 0;
    }

    .modal-close {
        margin-left: rem(16px);
    }

    .modal-title {
        margin: 0 rem(16px) 0 0;
        font-weight: 700;
        font-size: rem(16px);
        line-height: 1.2;
        flex: 1;
    }

    .modal-content {
        border: 0;
        border-radius: rem(8px);
    }

    .modal-body {
        padding: rem(20px);
        line-height: 1.5;

        .embed-responsive {
            margin: rem(-20px) rem(-20px) rem(20px) rem(-20px);
            width: auto;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    .modal-content_grey {
        background: $light_grey;

        .modal-footer {
            background: $white;
        }
    }

    .modal-footer {
        padding: rem(20px);
        border: 0;

        .btn-default {
            width: 100%;
        }
    }

    .modal-footer_center {
        justify-content: center;

        .btn-default {
            width: auto;
        }
    }

    .modal-footer > :not(:last-child) {
        margin-right: rem(8px);
    }

    .modal-footer > :not(:first-child) {
        margin-left: rem(8px);
    }

    .modal-header__inner {
        flex: 1;
        margin: 0 rem(16px) 0 0;

        .modal-title {
            margin: 0;
        }
    }

    .modal-footer_center {
        justify-content: center;

        .btn-default {
            width: auto;
        }
    }
}

.dashboard-nav {
    position: relative;
    margin: rem(-20px) rem(-20px) 0;

    &::after {
        content: "";
        display: block;
        background: linear-gradient(90deg, rgba(249, 249, 249, 0) 0%, #F9F9F9 53.13%);
        width: rem(120px);
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        pointer-events: none;
    }
}

.dashboard-nav__list {
    display: grid;
    grid-template-columns: auto;
    grid-auto-flow: column;
    overflow: auto;
    padding: 0 rem(64px) 0 rem(8px);
    list-style: none;
    margin: 0;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;

    @media (min-width: 1081px) {
        display: none;
    }
}

.dashboard-nav__item {
    scroll-snap-align: start;
}

.dashboard-nav__link {
    color: $dark;
    font-size: rem(13px);
    line-height: 1.2;
    padding: rem(24px) rem(12px);
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &:focus {
        text-decoration: none;
        color: $dark;
    }

    &.active {
        color: $blue;
    }


}

.dashboard-nav__btn {
    width: rem(38px);
    height: rem(38px);
    position: absolute;
    z-index: 2;
    top: 50%;
    right: rem(20px);
    margin-top: rem(-19px);
    border-radius: 50%;
    background: $blue;
    color: $white;
    font-size: rem(16px);
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &.active::after {
        content: "";
        display: block;
        width: rem(16px);
        height: rem(16px);
        border-radius: 50%;
        background: $accent;
        position: absolute;
        top: rem(-2px);
        right: rem(-2px);
    }
}

.dashboard-nav__btn_desktop {
    display: none;
}

.dashboard-nav__btn_products {
    top: rem(13px);
    margin-top: 0;
}

.dashboard-nav__search {
    display: none;
    flex: 1;
    margin-right: auto;

    .search_outer {
        margin-right: rem(20px);

        & > label {
            left: rem(20px);
        }
    }
}

.top_filter_row__column {
    margin-right: rem(20px);

    .scrollbar {
        max-height: 50dvh;
        overflow: auto;
    }
}

@media (min-width: 768px) {
    .dashboard-nav__btn_products {
        top: rem(-19px);
        right: rem(40px);
    }

    .table_outer_products {
        .dashboard-nav__btn-container {
            padding: 0;
            margin-bottom: rem(16px);
            display: flex;
            justify-content: flex-end;

            .dashboard-nav__btn {
                position: static;
                margin: 0;
            }
        }
    }
}

@media (min-width: 1081px) {
    .dashboard-nav__btn {
        width: rem(48px);
        height: rem(48px);
        right: rem(48px);
        margin-top: rem(-24px);
    }

    .dashboard-nav__btn_products {
        top: rem(-24px);
        margin-top: 0;
    }

    .dashboard-nav__btn-container {
        padding: 0 20px;
        margin-bottom: rem(16px);
        display: flex;
        justify-content: flex-end;
        gap: rem(20px);

        .dashboard-nav__btn {
            position: relative;
            right: 0;
            margin: 0;
        }
    }

    .table_outer_products {
        .dashboard-nav__btn-container {
            padding: 0;
        }
    }

    .dashboard-nav__search {
        display: flex;
    }

    .dashboard-nav__btn_desktop {
        display: inline-flex;
    }

    .dashboard-nav__btn_mob {
        display: none;
    }
}

/* mouse, touch pad */
@media (hover: hover) and (pointer: fine) {
    .dashboard-nav__btn:hover {
        background: $blue_hover;
    }
}


.filter-panel {
    padding: 0 rem(20px);

    .input {
        margin-bottom: 0;
        flex: 1;
        margin-right: rem(16px);
    }

    .dashboard-nav__btn {
        position: relative;
        top: 0;
        right: 0;
        margin-left: auto;
    }
}

.filter-panel__inner {
    padding: rem(20px) 0;
    border-bottom: 1px solid $border_color;
    display: flex;
    align-items: center;
}

@media (min-width: 768px) {
    .filter-panel__inner {
        border-bottom: 0;
    }

    .filter-panel {
        .input {
            max-width: 50%;
        }
    }
}

.filter_button_align-center {
    display: inline-flex;
    align-items: center;

    .input {
        margin-bottom: 0;
    }
}


.top_filter_row__column_expenses-category {
    display: none;

    @media (min-width: 1081px) {
        display: block;
    }
}

.dashboard-nav__clear-filter-button {
    .btn-default {
        padding: rem(5px) rem(20px);
        gap: rem(8px);
        color: $title-color;
        min-width: 0;

        span {
            font-size: rem(16px);
        }
    }
}

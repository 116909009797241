.self-purchase-modal__row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: rem(20px);
}

.self-purchase-modal {
    .multi-select__dropdown {
        top: calc(100% + rem(8px));
        bottom: auto;
    }

    .multi-select__item__copy-item {
        position: static;

        button {
            position: relative;
            z-index: 2;
        }
    }
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1051;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.65;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1052;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

  .mfp-zoom, .mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {cursor: pointer;}

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close {
  overflow: visible;
  cursor: pointer;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

.mfp-close {
  width: 30px;
  height: 32px;
  position: absolute;
  right: -8px;
  top: -27px;
  opacity: .95;
  background-color: transparent;
  cursor: pointer;
  z-index: 10;
}
  .mfp-close:hover,
  .mfp-close:focus {
    opacity: 1; }
.mfp-close:before {
  content: '\ea1d';
  font-family: 'sellerboard', serif ;
  color: #eaeaea;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all .4s ease-in-out 0s;
  font-size: 14px;
}
.mfp-close:hover:before,
.mfp-close:focus:before {
  color: #fff;
  transform: translate(-50%, -50%) rotate(180deg);
}
.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  border: none;
  background-color: #F6CE36;
  position: absolute;
  opacity: 0.95;
  top: 50%;
  padding: 0;
  height: 30px;
  width: 30px;
  transform: translateY(-50%);
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  z-index: 10;
}

.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow-left {
  left: 0;
}
.mfp-arrow-left:after {
  content: '\e92f';
  font-family: 'toffi-font', serif !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.mfp-arrow-right {
  right: 0;
}
.mfp-arrow-right:after {
  content: '\e930';
  font-family: 'toffi-font', serif !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 1200px; }

.mfp-container  .mfp-content {

}
.mfp-container  .mfp-content .mfp-iframe-scaler {
  position: relative;
  width: 100%;
}
.mfp-content .mfp-iframe-scaler:before {
  content: '';
  position: relative;
  display: block;
  padding-top: 56.25%;
}

  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

@-webkit-keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@-moz-keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@-o-keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
/*.mfp-content {*/
/*  -webkit-animation-name: fadeIn;*/
/*  -moz-animation-name: fadeIn;*/
/*  -o-animation-name: fadeIn;*/
/*  animation-name: fadeIn;*/
/*  -webkit-animation-duration: .3s;*/
/*  -moz-animation-duration: .3s;*/
/*  -o-animation-duration: .3s;*/
/*  animation-duration: .3s;*/
/*  -webkit-animation-fill-mode: both;*/
/*  -moz-animation-fill-mode: both;*/
/*  -o-animation-fill-mode: both;*/
/*  animation-fill-mode: both;*/
/*  -webkit-backface-visibility: hidden;*/
/*  -moz-backface-visibility:    hidden;*/
/*}*/

/**
 * Fade-zoom animation for first dialog
 */

/* start state */
.my-mfp-zoom-in .mfp-content {
  opacity: 0;

  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;



  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}

/* animate in */
.my-mfp-zoom-in.mfp-ready .mfp-content {
  opacity: 1;

  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

/* animate out */
.my-mfp-zoom-in.mfp-removing .mfp-content {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);

  opacity: 0;
}

/* Dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}
/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.6;
}
/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}
.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}
.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}
/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 95vh;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 0;
  border-radius: 5px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0;
    padding: 0;
  }


.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

.mfp-image-holder .mfp-content {
  max-width: 100%;
}
.mfp-gallery .mfp-image-holder .mfp-content {
  padding: 0;
}
.mfp-gallery .mfp-wraper {
  width: auto;
  max-width: 100%;
  min-width: 250px;
  background-color: transparent;
  border-radius: 5px;
  overflow: hidden;
}
.mfp-gallery .mfp-info {
  padding: 40px;
}
.mfp-gallery .mfp-info .mfp-title {
  margin: 0 0 10px;
  font-size: 24px;
  line-height: 26px;
  color: #221f20;
}
.mfp-gallery img.mfp-img {
  padding: 0;
  display: block;
  width: auto;
  max-height: 95vh;
  max-width: 100%;
}
.mfp-gallery .mfp-close {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #F6CE36;
  width: 30px;
  height: 30px;
}
.mfp-gallery .mfp-close:after {
  content: '\ea0e';
  position: absolute;
  font-family: 'toffi-font', serif !important;
  color: #000;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.doc__popup {
  width: 100%;
  max-width: 810px;
  margin: 0 auto;
  padding: 30px;
  position: relative;
}
.doc__popup--wraper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 2.5em;
  -webkit-box-align: center;
  -ms-flex-align: center;
  flex-direction: column;
  align-items: center;
  line-height: .7em;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.doc__popup--img {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}
.doc__popup--img .video-block {
  line-height: 0.6em;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  cursor: pointer;
}
.doc__popup--text {
  padding-top: 30px;
  color: #444444;
  line-height: 1.5em;
}
.doc__popup--text h4 {
  margin: 0 0 10px;
  color: #221f20;
  font-size: 24px;
}
.doc__popup .mfp-close,
.case__popup .mfp-close {
  top: 23px;
  right: 7px;
}
.case__popup {
  width: 100%;
  max-width: 810px;
  margin: 0 auto;
  padding: 30px;
  position: relative;
}
.case__popup--wraper {
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.case__popup--text {
  padding: 40px;
  color: #444444;
}
.case__popup--text h4 {
  margin: 0 0 10px;
  color: #221f20;
  font-size: 24px;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75) translateY(-50%);
    transform: scale(0.75) translateY(-50%); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; }
}
@media all and (max-width: 740px) {
  .mfp-container {
    padding-top: 5px;
  }
}

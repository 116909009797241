.buttons-panel {
    position: sticky;
    bottom: 0;
    width: 100%;
    z-index: 5;
    background: $white;
    padding: rem(10px) rem(20px);
    box-shadow: 0 rem(-6px) rem(12px) rgba(0, 0, 0, .06);
    transition: transform .2s ease-in-out;

    &.active {
        transform: translateX(100px);
    }

    @media (min-width: 769px) {
        padding-left: rem(120px);
    }

    .btn-default {
        min-width: 0;
        padding: rem(5px) rem(20px);
        text-align: center;
    }

    .download_button {
        position: relative;
    }


}

.buttons-panel__inner {
    display: flex;
    gap: rem(16px);
}

.buttons-panel_add-expense,
.buttons-panel__redeem-add {
    margin: 0 auto;

    @media (min-width: 768px) {
        margin: 0;
    }
}

.buttons-panel__absTooltip {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

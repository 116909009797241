.settings__accounts {
    display: grid;
    grid-template-columns: 100%;
    gap: rem(16px);
    margin-bottom: rem(24px);
}

.settings__accounts__item {
    padding: rem(16px);
    border-radius: rem(16px);
    background: $light_grey;
    display: grid;
    grid-template-columns: 100%;
    gap: rem(16px);
    align-items: center;
}

.settings__accounts__item__header {
    .popover_block {
        width: 100%;
    }
}

.settings__accounts__item__header__service {
    color: $title-color;
}

.settings__accounts__item__header__serviceName {
    font-size: rem(16px);
}

.settings__tax-checkbox {

    .input__checkbox-label {
        font-size: rem(16px);
        font-weight: 500;
    }

    .input__checkbox-label::before {
        top: 0;
    }

    .input__inner_info {
        display: inline-block;
    }
}

.settings__block {
    margin: 0 0 rem(24px);

    &:last-child {
        margin-bottom: 0;
    }

    .input {
        margin-bottom: rem(16px);
    }

    .over__ellipse {
        margin-bottom: rem(16px);
    }
}

.settings__block__title {
    font-size: rem(16px);
    line-height: 1.2;
    color: $dark;
    font-weight: 500;
    margin: 0 0 rem(5px);
}

.settings__confirm-section {
    margin: rem(16px) 0;
    padding: rem(16px);
    background: $light_grey;
    border-radius: rem(16px);

    p {
        font-size: rem(15);
    }
}

.settings__block__inner-content {
    max-width: rem(290px);
}

.settings__taxGrid {
    display: grid;
    grid-template-columns: 100%;
    gap: rem(16px);

    @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 992px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1600px) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.settings__taxBlock {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: rem(20px) rem(16px);
    background-color: $light_grey;
    padding: rem(24px);
    border-radius: rem(10px);

    .input {
        margin-bottom: 0;
    }

    .daterangepicker {
        left: auto !important;
        right: 0 !important;

        &::before {
            left: auto;
            right: rem(20px);
        }

        &::after {
            left: auto;
            right: rem(21px);
        }
    }

    .input_select {
        grid-column: span 2;
    }

    @media (min-width: 768px) {
        grid-template-columns: 100%;

        .input {
            margin-bottom: 0;
        }

        .daterangepicker {
            right: auto !important;
            left: 0 !important;

            &::before {
                right: auto;
                left: rem(20px);
            }

            &::after {
                right: auto;
                left: rem(21px);
            }
        }

        .input_select {
            grid-column: span 1;
        }
    }

    @media (min-width: 1400px) {
        grid-template-columns: repeat(2, 1fr);

        .input_select {
            grid-column: span 2;
        }
    }
}

.settings__taxBlock__removeBtn {
    position: absolute;
    top: 0;
    right: 0;
    padding: rem(16px);
    font-size: rem(20px);
    z-index: 1;
    color: $dark_grey;

    transition: color .2s ease-in-out;

    /* mouse, touch pad */
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            color: $title-color;
        }
    }
}

.settings__tax__actions {
    margin-top: rem(16px);
    display: grid;
    grid-template-columns: 100%;
    gap: rem(16px);

    @media (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1600px) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.settings__tax__actions__inner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: rem(16px);
}

.settings__block__title__info {
    vertical-align: middle;
    margin-left: rem(8px);
    position: relative;
    top: rem(-1px);
}

@media (min-width: 992px) {
    .settings__accounts__item {
        grid-template-columns: rem(240px) 1fr auto;
        padding: rem(24px);
    }

    .settings__accounts {
        margin-bottom: rem(48px);
    }

    .settings__accounts__item__header {
        .popover_block {
            width: auto;
        }
    }

    .settings__block {
        margin: 0 0 rem(48px);
    }

    .settings__confirm-section {
        padding: rem(24px);
    }
}

.settings__accounts__actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: rem(24px);

    .fm-trash,
    .fm-edit {
        font-size: rem(16px);
    }

    & > a {
        display: inline-flex;
        align-items: center;
        gap: rem(8px);
        text-decoration: none;
    }

    @media (min-width: 992px) {
        justify-content: flex-end;
    }
}

.settings__accounts__list {
    display: flex;
    flex-wrap: wrap;
    gap: rem(8px);
}

@import 'functions';
@import 'mixin';
@import 'variables';
input::placeholder, textarea::placeholder {
    color:$inputPlaceholder;
}
.container {
  max-width: 1208px;
}
.container_inner {
  max-width: 1175px;
  margin: 0 auto;
}
.table-responsive {
  position: relative;
  width: 100%;
  overflow-x: auto;
}
.size-11 {
  font-size: rem(11);
}
.size-12 {
  font-size: rem(12);
}
.size-15 {
  font-size: rem(15);
}
.size-18 {
  font-size: rem(18);
}
.size-24 {
  font-size: rem(24);
}
.c-dark_grey {
  color: $dark_grey;
}
.pt-20 {
  padding-top: rem(20)!important;
}
a, a:not([href]):not([tabindex]) {
  color: $blue;
  &:hover, &:active, &:focus {
    color: $blue-hover;
  }
}
.over__ellipse {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: rem(290);
}
.ml-auto {
  margin-left: auto;
}
.more_link {
  display: inline-block;
  padding-right: rem(20);
  position: relative;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $blue;
  &:hover, &:focus, &:active {
    text-decoration: none;
    color: $blue-hover;
  }
  &:after {
    content: '\e914';
    font-family: 'sellerboard', sans-serif;
    color: inherit;
    font-size: inherit;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
.owl-carousel {
  touch-action: manipulation;
}
.days_period_row.owl-carousel .owl-stage {
  padding: 0 0 rem(20px);
}
.days_period_row.owl-carousel .owl-nav {
  display: none;
  margin: 0;
  button[class*=owl-] {
    display: inline-flex;
    display: -webkit-inline-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    position: absolute;
    top: 50%;
    height: rem(48);
    width: rem(48);
    overflow: hidden;
    border: 0 none;
    margin: 0;
    padding: 0;
    border-radius: 0;
    font-size: rem(30);
    color: $dark_grey;
    transform: translateY(-50%);
    background-color: transparent;
    &.disabled {
      opacity: 0;
    }
    &:hover, &:focus {
      outline: none;
    }
    &.owl-prev {
      left: rem(-9);
      justify-content: flex-start;

      span {
        margin-left: rem(-6px);
      }
    }
    &.owl-next {
      right: rem(-9);
      justify-content: flex-end;

      span {
        margin-right: rem(-6px);
      }
    }
  }
}
.form_group {
  margin-bottom: rem(20);
  position: relative;
}

.dark_link {
  color: $dark;
  text-decoration: underline;
}

.hasTooltip label {
  padding-right: rem(20);
}
.hasTooltip:after {
  content:'';
  display:block;
  clear: both;
}
.table-responsive, .select2-container--default .select2-results>.select2-results__options,
.dropDown-parent .dropdown--menu ul, .feedback__item__body__inner,
td.inner-text-block .text_block.comment-block .comment-text .comment-text-inner {
  scrollbar-width: thin;
  scrollbar-color: #C8C8C8 #F2F3F3;
  &::-webkit-scrollbar {
    width: 7px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 10px rgba(129, 140, 136, 0.2);
    box-shadow: inset 0 0 10px rgba(129, 140, 136, 0.2);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(100,100,100,.3);
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    opacity: .3;
    transition: opacity .3s ease-in-out;
  }
}
input.form-control, textarea.form-control, input.select-control {
  &::-webkit-input-placeholder {
    color: $dark_grey;
    opacity: 1;
    transition: opacity .3s ease-in-out 0s;
  }

  &::-moz-placeholder {
    color: $dark_grey;
    opacity: 1;
    transition: opacity .3s ease-in-out 0s;
  }

  &:-ms-input-placeholder {
    color: $dark_grey;
    opacity: 1;
    transition: opacity .3s ease-in-out 0s;
  }
}
input.form-control, textarea.form-control {
  &:focus::-webkit-input-placeholder, &.form__error:focus::-webkit-input-placeholder {
    opacity: 0;
  }
  &:focus::-moz-placeholder, &.form__error:focus:focus::-moz-placeholder {
    opacity: 0;
  }
  &:focus:-ms-input-placeholder, &.form__error:focus:-ms-input-placeholder {
    opacity: 0;
  }
  &:-webkit-autofill {
    -webkit-text-fill-color: #000!important;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
}
.compare_button {
  position: relative;
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  align-items: center;
  -webkit-align-items: center;
  cursor: pointer;
  color: $dark_grey;
  .fm-shuffle {
    font-size: rem(16);
  }
  &:hover, &:active {
    color: $dark_grey;
    text-decoration: none;
  }
  &.active {
    color: $blue;
  }
}
[data-bm-tooltip] {
  position: relative;
  &:after {
    position: absolute;
    border: 0;
    border-radius: rem(4);
    box-shadow: 4px 4px 8px rgba(0,0,0,.2);
    color: #232323;
    content: attr(data-bm-tooltip);
    font-size: 12px;
    line-height: 1.25;
    font-family: 'Open Sans', sans-serif;
    max-width: 200px;
    padding: 5px 8px;
    pointer-events: none;
    transition: all 1.5s ease;
    background-color: $white;
    margin-top: 7px;
    top: 105%;
    right: 0;
    display: none;
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0,0,0);
    z-index: 100000;
  }
}

/* mouse, touch pad */
@media (hover: hover) and (pointer: fine) {
  [data-bm-tooltip]:hover::after {
    display: block;
    opacity: 1;
    overflow: visible;
    visibility: visible;
  }
}
.notification-message {
  display: none;
  position: fixed;
  background: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, .2);
  font-family: 'Open Sans',sans-serif;
  color: #555;
  padding: rem(15);
  text-shadow: none;
  font-weight: 300;
  font-size: rem(13px);
  margin: 0;
  text-align: left;
  bottom: rem(20);
  right: rem(20);
  width: auto;
  max-width: calc(100vw - rem(40px));
  z-index: 9999;
}
.notification-message.error {
    background: $error-300;
    color: #fff;
}
.notification-message_offset {
  bottom: rem(70px);
}

.tooltip-inner {
  max-width: rem(320);
  padding: rem(8);
  color: #000;
  text-align: left;
  font-size: 11px;
  line-height: rem(16);
  background-color: $white;
  border-radius: rem(8);
  box-shadow: 0 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12);
}
.tooltip .arrow::before {
  top: 0;
  border-width:0;
}
.status-badge {
  color: #fff;
  text-transform: uppercase;
  padding: 0 3px;
}
.status-badge.label-no {
  background-color: #49ad70;
}
.status-badge.label-yes {
  background-color: #f55244;
}
.index_filter_button, .select_filter_button {
  height: rem(40);
  display: grid;
  grid-auto-flow: column;
  grid-gap: rem(10);
  align-items: center;
  -webkit-align-items: center;
  color: $dark_grey;
  padding: rem(5) rem(20);
  background-color: $white;
  border: 1px solid #F0F0F0;
  border-radius: rem(8);
  &:focus {
    outline: 0;
    box-shadow: none;
  }
  &.active {
    color: $white;
    background-color: $blue;
    border-color: $blue;
  }
}

.index_select_button {
    display: flex;
    gap: rem(8px);
    align-items: center;
    height: rem(40px);
    padding: rem(5px) rem(20px);
    border: 1px solid $border_color;
    border-radius: rem(8px);
    background: $white;
    color: $title-color;

    .fm-chevron-down {
        color: $dark_grey;
    }
}

.select_filter_button {
  height: auto;
  display: flex;
  display: -webkit-flex;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  align-items: center;
  -webkit-align-items: center;
  color: white;
  padding: rem(5) rem(20);
  background-color: $blue;
  border: 1px solid $blue;
  border-radius: 0;
  >* ~ * {
    margin-left: rem(10);
  }
  &:focus {
    outline: 0;
    box-shadow: none;
  }
}
.btn-default {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: rem(40);
  background-color: $white;
  border: 1px solid $border_color;
  color: $dark;
  border-radius: rem(4);
  min-width: rem(145);
  padding: rem(5) rem(25);
  line-height: .9;
  &:hover, &:active {
    text-decoration: none;
    color: $dark;
    background-color: #ededed;
  }

  &.disabled,
  &:disabled {
    background-color: #eaeaea;
    color: $dark_grey;
    border: 1px solid $border_color;
    cursor: not-allowed;
    pointer-events: none;
    &:hover, &active {
      background-color: #eaeaea;
      color: $dark;
      cursor: not-allowed;
    }
  }
}

.btn-regular {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: rem(40);
  background-color: $blue;
  border: 1px solid $blue;
  color: $white;
  border-radius: rem(4);
  min-width: rem(145);
  padding: rem(5) rem(25);
  line-height: .9;
  text-decoration: none;
  &:hover, &:active {
    background-color: $blue-hover;
    color: $white;
    text-decoration: none;
  }
  &:focus {
    color: $white;
    outline: none;
    text-decoration: none;
  }
  &.disabled,
  &:disabled {
    background-color: #eaeaea;
    color: $dark;
    border: 1px solid #f0f0f0;
    pointer-events: none;
    cursor: not-allowed;
    &:hover, &:active {
      background-color: #eaeaea;
      color: $dark;
      cursor: not-allowed;
    }
  }
}

.btn_lg {
  height: rem(60px);
  font-size: rem(16px);
  padding: rem(10px) rem(50px);
  border-radius: rem(8px);

  .btn-loader {
    border-radius: rem(8px);
  }

  .spinner-border {
    width: 1em;
    height: 1em;
    border-width: 2px;
  }
}

.loading_button {
  position: relative;
  .ring_loader {
    position: absolute;
    right: rem(15);
    display: none;
  }
}
.modal-dialog.change-modal {
  max-width: rem(460);
  .modal-content{
    border: 0 none;
    border-radius: rem(12);
  }
  .modal-header {
    height: rem(68);
    position: relative;
    background: linear-gradient(92.5deg, #425AF0 0.41%, #51B1FA 100%);
    padding: rem(15) rem(26);
    @include prefix((
            align-items: center,
    ), webkit);
    h5 {
      font-size: rem(15);
      color: $white;
      font-family: 'Open Sans', sans-serif;
      font-weight: 500;
    }
    .modal-close {
      border: 0 none;
      background-color: transparent;
      height: auto;
      width: auto;
      position: absolute;
      right: rem(23);
      top: 50%;
      transform: translateY(-50%);
      padding: 0;
      &:focus, &:active {
        outline:  none;
        box-shadow: none;
      }
      span {
        color: $white;
        font-size: rem(16);
      }
    }
  }
  .modal-body {
    padding: rem(30) rem(30) 0;
  }
  .modal-title {
    color: #232323;
    font-size: rem(20);
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    @include prefix_val_inn((
            display: flex,
    ), webkit);
    @include prefix((
            align-items: center,
            justify-content: space-between
    ), webkit);
    padding-top: rem(13);
    padding-bottom: rem(18);
  }
  .drop-icon {
    position: relative;
    padding-right: rem(20);
    &:after {
      content: '\e930';
      font-family: 'sellerboard', sans-serif;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      line-height: .8;
      color: $dark;
      font-size: rem(15);
    }
  }
  .modal-body  {
    .date-row {
      padding-bottom: rem(10);
      @include prefix_val_inn((
              display: flex,
      ), webkit);
      @include prefix((
              flex-wrap: wrap,
              align-items: center,
              justify-content: flex-start
      ), webkit);
      > div:not(:last-of-type) {
        margin-right: rem(18);
      }
      > div {
        margin-bottom: rem(10);
      }
    }
    .icon_first_col {
      display: inline-grid;
      grid-auto-flow: column;
      grid-gap: 10px;
      justify-content: start;
      align-items: center;
    }
    .icon_select_col {
      display: inline-grid;
      grid-template-columns: auto 1fr;
      grid-gap: 10px;
      justify-content: start;
      align-items: center;
      width: 100%;
    }
    .square_icon {
      height: rem(16);
      width: rem(16);
      display: inline-block;
      border: 1px solid #999999;
      border-radius: 2px;
    }
  }
  .modal-footer {
    padding-left: rem(26);
    padding-right: rem(26);
    background-color: $white;
    border-top: 0;
    .modal-footer-buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      -webkit-align-items: center;
      grid-gap: rem(20);
      width: 100%;
      > a.btn-default {
        width: 100%;
      }
    }
  }

  .modal-footer_center {
    justify-content: center;
  }
}
.modal-dialog.info-modal, .modal-dialog.warning-modal {
  .modal-content {
    padding: rem(40) rem(30);
    border: 0 none;
    .modal-close {
      border: 0 none;
      background-color: transparent;
      height: auto;
      width: auto;
      position: absolute;
      right: rem(16);
      top: rem(16);
      padding: 0;
      span {
        color: $dark_grey;
        font-size: rem(15);
      }
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    .modal-body {
      width: 100%;
      padding: 0;
      margin-bottom: rem(15);
      .info-text {
        font-size: rem(17);
        text-align: center;
        line-height: rem(23.15);
      }
      .warning-text {
        font-size: rem(17);
        padding-bottom: rem(38);
      }
      .error__title {
        font-size: rem(17);
        color: #EC3434;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: rem(12);
        align-items: center;
        -webkit-align-items: center;
        span {
          font-size: rem(24);
          line-height: .9;
        }
      }
      .error__text {
        font-size: rem(15);
        line-height: rem(21.93);
        padding-top: rem(14);
      }
    }
    .modal-footer-buttons {
      text-align: center;
      margin-top: rem(5);
      &.two-buttons {
        @include prefix_val_inn((
                display: flex,
        ), webkit);
        @include prefix((
                justify-content: center
        ), webkit);
        > * ~ * {
          margin-left: rem(15);
        }
        .btn-default {
          width: rem(127);
        }
      }
    }
  }
}

@media (min-width: 576px) {
  .modal-dialog.info-modal,
  .modal-dialog.warning-modal {
    max-width: rem(365px);
  }
}

.modal-dialog.warning-modal {
  .modal-content {
    padding-bottom: rem(20);
  }
}
.modal-dialog.feedback_modal {
  .modal-content {
    border: 0 none;
    border-radius: rem(12);
    .modal-header {
      height: auto;
      position: relative;
      background: transparent;
      padding: rem(35) rem(26) rem(15);
      border-bottom: 0;
      display: flex;
      display: -webkit-flex;
      @include prefix((
              align-items: center,
              justify-content: flex-start
      ), webkit);
      .modal_thumbnail {
        height: rem(50);
        border-radius: 50%;
        overflow: hidden;
        width: rem(50);
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .title-outer {
        padding-left: rem(15);
      }
      h5 {
        font-size: rem(22);
        color: #232323;
        font-family: 'Open Sans', sans-serif;
        font-weight: 500;
        line-height: 1.2;
      }
      .modal-close {
        border: 0 none;
        background-color: transparent;
        height: auto;
        width: auto;
        position: absolute;
        right: rem(16);
        top: rem(16);
        padding: 0;
        &:focus, &:active {
          outline:  none;
          box-shadow: none;
        }
        span {
          color: $dark_grey;
          font-size: rem(16);
        }
      }
    }
    .modal-body {
      padding: rem(30) rem(25) rem(20);
      article {
        font-size: rem(15);
        line-height: 1.5;
      }
    }
  }
}

.owl-carousel:not(.owl-loaded) {
  display: none;
}

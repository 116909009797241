.add-marketplace {
    display: grid;
    grid-template-columns: 1fr;
    gap: rem(24px);

    @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        gap: rem(48px);

        &.single {
            grid-template-columns: 1fr;
            margin: 0 100px;
        }
    }
}

.add-marketplace__item {
    padding: rem(24px);
    border-radius: rem(16px);
    background: $light_grey;
    border: 1px solid $border_color;

    @media (min-width: 768px) {
        padding: rem(48px);
    }

    .form-control {
        font-size: rem(16px);
        height: rem(60px);
    }

    .error~.invalid-feedback,
    .form_error~.invalid-feedback {
        font-size: rem(13px);
    }
}

.add-marketplace__logo {
    margin-bottom: rem(24px);
}

.add-marketplace__links {
    list-style: none;
    padding: 0;
    margin: rem(24px) 0 0;
}

.add-marketplace-actions {
    display: grid;
    grid-template-columns: 1fr;
    gap: rem(16px);
    margin-top: rem(24px);

    @media (min-width: 768px) {
        display: flex;
        justify-content: center;
        gap: rem(24px);
        margin-top: rem(48px);
        padding-bottom: rem(48px);
    }
}

.loading-wrapper {
    &:where(._panel) {
        background-color: $white;
        border: 1px solid $border_color;
        border-radius: rem(10px);

        .loading-wrapper__inner {
            display: block;
            padding-inline: rem(20px);
        }

        .spinner-border {
            width: rem(24px);
            height: rem(24px);
            color: $blue;
            margin-bottom: rem(16px);
            border-width: 2px;
        }
    }
}

.loading-wrapper__container {
    max-width: rem(360px);
    margin-inline: auto;
    text-align: center;
}

.loading-wrapper__title {
    font-size: rem(16px);
    line-height: 1.2;
    color: $title-color;
    font-weight: 600;
    margin-bottom: rem(8px);
}

.item {
    position: relative;
    padding-left: rem(15px);
    display: flex;
    align-items: center;

    [data-bm-tooltip]:after {
        right: auto;
        left: 0;
    }
}

.item__img {
    margin-right: rem(6px);
    flex-shrink: 0;
    border-radius: rem(4px);
    overflow: hidden;
    text-indent: -9999px;
}

.item__title {
    line-height: 1.2;
    color: $title-color;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.item__barcode {
    line-height: 1.2;
    color: $title-color;
}

.item__data {
    font-size: rem(13px);
    line-height: 1.2;
    color: $dark;
}

.item__product-id {
    line-height: 1.2;

    .copy-btn {
        position: relative;
        z-index: 2;
    }
}

.copy-btn {
    width: rem(16px);
    height: rem(16px);
    font-size: rem(13px);
    background: transparent;
    padding: 0;
    border: 0;
    margin-right: rem(4px);
    flex-shrink: 0;
    color: $blue;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.item__link {
    color: $blue;
    font-size: rem(13px);
    background: transparent;
    border: 0;
    padding: 0;
    &.disabled_link {
        color: $dark;
        cursor: default;
        &:hover {
            color: $dark;
        }
    }
    &:hover {
        color: $blue_hover;
        text-decoration: none;
    }
}

a.item__link,
button.item__link,
a.item__product-id__link,
button.item__product-id__link {
    position: relative;
    z-index: 1;
}

.item__data_desktop {
    display: none;
}

.item__order {
    display: flex;
    align-items: center;

    .copy-btn {
        position: relative;
        z-index: 1;
    }

    [data-bm-tooltip]:after {
        right: auto;
        left: 0;
    }
}

.item__order__title {
    max-width: rem(100px);
    color: $title-color;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.products-grouped {
    .item {
        padding-left: 0;
    }

    .item__title {
        -webkit-line-clamp: 2;
    }

    .item__product-id {
        display: none;
    }
}


.products-table {
    .products-grouped {
        .item__title {
            -webkit-line-clamp: 1;
        }

        .item__product-id {
            display: block;
        }
    }
}

.item__data_orders {
    .item__title {
        -webkit-line-clamp: 2;
    }
}

@media (min-width: 768px) {
    .dashboard-table {
        .item__data_desktop {
            display: block;
        }

        .item__data_mob {
            display: none;
        }
    }

    .products-grouped {
        .item__product-id {
            display: block;
        }

        .item__title {
            -webkit-line-clamp: 1;
        }
    }

}

@media (min-width: 992px) {
    .products-table, .redeems-table {
        .item__data_desktop {
            display: block;
        }

        .item__data_mob {
            display: none;
        }
    }
}

@media (min-width: 1440px) {
    .item {
        padding-left: rem(15px);
    }
}

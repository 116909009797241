.notification {
    position: relative;
}

.notification__trigger {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: transparent;
    border: 0;

    i {
        font-size: rem(16px);
        color: $blue;
    }
}

.notification__counter {
    margin-left: rem(2px);
    font-size: rem(10px);
    font-weight: 600;
    line-height: 1;
    color: $white;
    background: $blue;
    border-radius: rem(8px);
    min-width: rem(16px);
    height: rem(16px);
    padding: 0 rem(4px);
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:empty {
        display: none;
    }
}

@media (min-width: 576px) {
    .notification__trigger {
        min-width: rem(38px);
        min-height: rem(38px);
        padding: rem(11px);
    }

    .notification__counter {
        margin-left: rem(4px);
    }
}

.notification-list {
    position: absolute;
    z-index: 5;
    top: calc(100% + rem(8px));
    right: 0;
    background: $white;
    padding: rem(16px);
    border-radius: rem(8px);
    overflow: auto;
    max-height: 60vh;
    width: calc(100vw - 36px);
    max-width: rem(375px);
    margin-right: -48px;
    box-shadow: 0 rem(8px) rem(18px) rem(-6px) rgba(24, 39, 75, .12), 0 rem(12px) rem(42px) rem(-4px) rgba(24, 39, 75, .12);
}

.notification-list__items {
    display: grid;
    grid-template-columns: 100%;
    gap: rem(4px);

    &:not(:first-child) {
        margin-top: rem(4px);
    }
}

.notification-list__footer {
    text-align: center;
    margin-top: rem(16px);
}

@media (min-width: 768px) {
    .notification-list {
        left: 50%;
        margin-right: auto;
        margin-left: rem(-187px);
        max-height: rem(474px);

        scrollbar-width: thin;
        scrollbar-color: #C8C8C8 #F2F3F3;
        &::-webkit-scrollbar {
            width: 7px;
            height: 5px;
        }
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 10px rgba(129, 140, 136, 0.2);
            box-shadow: inset 0 0 10px rgba(129, 140, 136, 0.2);
            border-radius: 4px;
        }
        &::-webkit-scrollbar-thumb {
            background: rgba(100,100,100,.3);
            border-radius: 4px;
            cursor: pointer;
            position: relative;
            opacity: .3;
            transition: opacity .3s ease-in-out;
        }
    }
}


.notification-item {
    position: relative;
    padding: rem(8px);
    border-radius: rem(8px);
    border: 1px solid $border_color;
    display: flex;
    align-items: flex-start;
    gap: rem(8px);

    &._product,
    &:where(._shop) {
        .notification-item__title {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }

    &._alert {
        .notification-item__ico {
            color: $danger;
        }
    }

    &:where(._success) {
        .notification-item__ico {
            color: $success;
        }
    }
}

.notification-item__ico {
    width: rem(40px);
    height: rem(40px);
    flex-shrink: 0;
    font-size: rem(16px);
    color: $warning;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notification-item__body {
    flex: 1;
}

.notification-item__image {
    width: rem(40px);
    height: rem(40px);
    flex-shrink: 0;
    border-radius: rem(4px);
    object-fit: cover;
}

.notification-item__title {
    font-size: rem(13px);
    font-weight: 600;
    color: $title-color;
    line-height: 1.2;
}

.notification-item__content {
    font-size: rem(13px);
    line-height: 1.5;
    color: $dark;
    margin-top: rem(2px);
}

.notification-item__link {
    width: rem(40px);
    height: rem(40px);
    font-size: rem(16px);
    color: $blue !important;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
    }
}

.notification-item_new {
    background: $light_grey;
}

/* mouse, touch pad */
@media (hover: hover) and (pointer: fine) {
    .notification-item:not(:where(._noHover)):hover .notification-item__title {
        color: $blue;
    }
}

.notification-list__itemsGroupDate {
    line-height: 1.1;
    color: $dark;
    padding: rem(4px) 0;
    margin-top: rem(4px);
}

.notification-item__datetime {
    font-size: rem(10px);
    line-height: 1.56;
    text-align: center;
    color: $dark;
    margin-bottom: rem(2px);
}

.select-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(16px);

    @media (min-width: 1200px) {
        display: none;
    }

    .input {
        margin: 0;
    }
}

.select-pagination__btn {
    width: rem(40px);
    height: rem(40px);
    font-size: rem(18px);
    color: $blue;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &:focus {
        text-decoration: none;
        color: $blue;
    }

    &.disabled {
        color: $dark;
        opacity: .4;
        pointer-events: none;
    }
}

.select-pagination__select {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
}

.modal-fullscreen_filter-modal {

}

.filter-list {
    margin: rem(-20px);
}

.filter-list__item {
    width: 100%;
    line-height: 1.2;
    padding: rem(16px) rem(20px);
    color: $title-color;
    text-align: left;
    border-bottom: 1px solid $border_color;
    display: flex;
    align-items: center;

    .fm-chevron-right {
        color: $blue;
        margin-left: rem(16px);
    }

    &:last-child {
        border-bottom: 0;
    }
}

.filter-list__ico {
    font-size: rem(16px);
    flex-shrink: 0;

    &:first-child {
        margin-right: rem(16px);
    }
}

.filter-list__title {
    margin-right: auto;
    flex: 1;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.filter-periods {
    .periodSelectBlocks {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: rem(8px);
    }

    .periodSelectBlocks_list {
        grid-template-columns: 100%;
    }

    .periodSelectBlocks_grid-3  {
        grid-template-columns: repeat(3, 1fr);
    }

    .periodSelectBlock {
        position: relative;
        background: $white;
        border: 1px solid $border_color;
        padding: rem(16px) rem(32px) rem(16px) rem(16px);
        border-radius: rem(8px);
        cursor: pointer;

        &.active {
            background: $blue;
            border-color: $blue;
            color: $white;

            .periodSelectBlock__ico {
                opacity: 1;
                color: $white;
            }
        }
    }

    .periodSelectBlock__ico {
        position: absolute;
        top: rem(16px);
        right: rem(16px);
        font-size: rem(16px);
        color: #ddd;
        opacity: 0;
    }

    .periodSelectBlock_custom {
        text-align: center;
        grid-column: span 2;

        .periodSelectBlock__ico {
            position: static;
            margin-right: rem(8px);
            opacity: 1;
            vertical-align: middle;
        }
    }

    .daterangepicker {
        position: static;
        grid-column: span 2;
        width: auto;

        .drp-calendar {
            float: none;
            max-width: 100%;
            padding: rem(16px);

            .calendar-table {
                padding: 0;
            }
        }
    }



}

.filter-products,
.filter-countries,
.filter-categories {
    margin: rem(-20px);

    .multi-select__dropdown {
        position: static;
        box-shadow: none;
    }

    .multi-select__dropdown__body {
        overflow: visible;
        max-height: none;
    }
}

.filter-countries,
.filter-categories {
    .multi-select__dropdown__body {
        padding: rem(20px);
    }

    .input {
        margin-bottom: rem(16px);
    }
}

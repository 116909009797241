.status-tag {
    font-size: rem(13px);
    font-weight: 500;
    line-height: 1.462;
    border-radius: rem(4px);
    padding: rem(2px) rem(8px);
    text-align: center;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.status-tag_dimmed {
    background: $dark_grey;
    color: $white;
}

.status-tag_success {
    color: $white;
    background: $success;
}
.status-tag_partial {
    color: $white;
    background: $warning;
}

.status-tag_danger {
    color: $white;
    background: $danger;
}

.status-tag_white {
    color: $dark;
    background: $white;
}

.status-dot {
    display: inline-block;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    vertical-align: middle;
    margin-left: rem(4px);
}

.status-dot_dimmed {
    background: $dark_grey;
}

.status-dot_success {
    background: $success;
}
.status-dot_partial {
    background: $warning;
}

@media (min-width: 768px) {
    .product_wrapper .status-dot {
        display: none;
    }
}

@media (min-width: 1400px) {
    .item .status-dot {
        display: none;
    }
}

.table-tabs .status-tag_responsive,
.status-tag_responsive {
    @media (max-width: 576px) {
        overflow: hidden;
        text-indent: -9999px;
        width: rem(13px);
        height: rem(13px);
        border-radius: 50%;
        padding: 0;
    }
}

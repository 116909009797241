.modal-import-errors {
    .info-list {
        margin-bottom: 0;
    }

    .modal-close {
        display: inline-flex;
    }
}

.modal-import-errors__header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: rem(20px);
    padding: rem(20px);
    border-bottom: 1px solid $border_color;
    line-height: 1.2;
}

.modal-import-errors__value {
    font-size: rem(18px);
    font-weight: 700;
    color: $title-color;
    margin-top: rem(8px);
}

.modal-import-errors__title {
    padding: rem(20px);
    border-bottom: 1px solid $border_color;
    line-height: 1.2;
    font-size: rem(16px);
    font-weight: 700;
    color: $title-color;

    &:has(+ .modal-import-errors__list) {
        border-bottom: 0;
    }
}

.modal-import-errors__list {
    width: 100%;
    padding-bottom: rem(16px);

    &:where(._fileNotParsed) {
        padding-inline: rem(20px);
        display: grid;
        grid-template-columns: 100%;
        gap: rem(8px);
    }
}

.modal-import-errors__item {
    padding: rem(8px) rem(16px);
    border-radius: rem(10px);
    background: $grey-100;
    margin-bottom: rem(8px);

    &:last-child {
        margin-bottom: 0;
    }
}

.modal-import-errors__item__title {
    color: $title-color;
}

.modal-import-errors__show-more {
    margin-top: rem(16px);
    text-align: center;
    margin-left: rem(-26px);
}

.modal-import-errors__show-more_btn {
    padding: 0;
    border: 0;
    background: transparent;
    color: $blue;
    height: auto;
    position: relative;

    &:hover {
        color: $blue_hover;
    }
}

.modal-import-errors__actions {
    text-align: center;
    margin-top: rem(24px);
}

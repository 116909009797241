.sep-title {
    font-size: rem(18px);
    font-weight: 500;
    color: $title-color;
    line-height: 1.2;
    margin: 0 0 rem(16px);
    padding-bottom: rem(8px);
    border-bottom: 1px solid $border_color;
}

.show-info-btn {
    color: $blue;
    padding: 0;
    border: 0;
    background: transparent;
    display: inline-flex;
    align-items: center;

    i {
        margin-left: rem(2px);
        flex-shrink: 0;
    }
}

.nowrap {
    white-space: nowrap;
}

.scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #C8C8C8 #F2F3F3;
    &::-webkit-scrollbar {
        width: 7px;
        height: 5px;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 10px rgba(129, 140, 136, 0.2);
        box-shadow: inset 0 0 10px rgba(129, 140, 136, 0.2);
        border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(100,100,100,.3);
        border-radius: 4px;
        cursor: pointer;
        position: relative;
        opacity: .3;
        transition: opacity .3s ease-in-out;
    }
}

@media (max-width: 767px) {
    .hide-xs {
        display: none !important;
    }
}

@keyframes loading-placeholder {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}

.loading-placeholder {
    position: relative;
    display: inline-block;
    height: 1em;
    width: 100%;
    max-width: 6em;
    border-radius: rem(4px);
    background: rgba(0,0,0, .05);
    overflow: hidden;
    vertical-align: middle;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, rgba(0,0,0, 0) 0%, rgba(0,0,0,.05) 50%, rgba(0,0,0, 0) 100%);
        animation: loading-placeholder 2s ease-in-out infinite forwards;
    }
}

p:last-child {
    margin-bottom: 0;
}
.dropdown-menu {
    font-size: rem(13px);
}

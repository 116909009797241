.info-list__header {
    padding: rem(16px) rem(20px);
    border-bottom: 1px solid $border_color;
    display: flex;
    align-items: center;
}

.info-list__header__body {
    line-height: 1.2;
    display: grid;
    grid-template-columns: 100%;
    gap: rem(4px);
}

.info-list__header__img {
    width: rem(40px);
    height: rem(40px);
    flex-shrink: 0;
    margin-right: rem(12px);
    border-radius: rem(4px);
    object-fit: contain;
}

.info-list__header__title {
    line-height: 1.2;
    color: $title-color;
}

.info-list__accordion__item {
    width: 100%;
    display: flex;
    align-items: center;
}

.info-list__subheader {
    display: flex;
    border-bottom: 1px solid $border_color;
}

.info-list__subheader__column {
    padding: rem(16px) rem(20px);
    flex: 1;

    > .info-list__subheader__item:not(:last-child) {
        margin-bottom: rem(4px);
    }
}

.info-list__subheader__column:not(:last-child) {
    border-right: 1px solid $border_color;
}


.info-list__accordion__group {
    border-left: 2px solid transparent;
    border-bottom: 1px solid $border_color;

    &:last-of-type {
        border-bottom: 0;
    }
}

.info-list__accordion__item {
    display: none;
    align-items: center;
}

.info-list__accordion__item_header {
    display: flex;
}

.info-list__accordion__group.active {
    border-left-color: $blue;

    .info-list__accordion__item {
        display: flex;
    }

    .info-list__accordion__toggle-btn::after {
        transform: rotate(90deg);
    }
}

.info-list__accordion__ico {
    width: rem(38px);
    height: rem(38px);
    font-size: rem(16px);
    padding: 0;
    border: 0;
    flex-shrink: 0;
    color: $dark_grey;
    background: transparent;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.info-list__accordion__toggle-btn {
    width: rem(38px);
    height: rem(38px);
    font-size: rem(16px);
    padding: 0;
    border: 0;
    flex-shrink: 0;
    color: $dark_grey;
    margin-right: rem(8px);
    background: transparent;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &::after {
        content: "";
        width: 0;
        height: 0;
        border-color: transparent transparent transparent $blue;
        border-style: solid;
        border-width: rem(4px) 0 rem(4px) rem(6px);
        flex-shrink: 0;
        transition: transform .3s ease-in-out 0s;
    }
}

.info-list__accordion__ico_warning {
    color: $warning;
}

.info-list__accordion__inner {
    position: relative;
    min-height: rem(48px);
    padding: rem(5px) rem(20px) rem(5px) rem(46px);
    flex: 1;
    line-height: 1.2;
    display: flex;
    align-items: center;
    gap: rem(8px);
}

.info-list__accordion__item_btn {
    .info-list__accordion__inner {
        padding: rem(5px) rem(20px) rem(5px) 0;
    }
}

.info-list__accordion__title {
    color: $title-color;
    position: relative;
}

.info-list__accordion__value {
    color: $dark;
    margin-left: auto;
    white-space: nowrap;
}

.info-list__accordion__group_bg {
    background: $grey-100;

    @media (min-width: 576px) {
        &:last-of-type {
            border-bottom-left-radius: rem(8px);
            border-bottom-right-radius: rem(8px);
        }
    }
}

.info-list__subheader__item_label {
    color: $blue;
}

.info-list__subheader__item_value {
    color: $dark;
}

.info-list__accordion__no-data {
    display: inline-block;
    padding-right: 1.155em;
}

.info-list__accordion__group_highlighted {
    .info-list__accordion__title,
    .info-list__accordion__value {
        font-weight: 700;
    }
}

.info-list__input {
    padding: rem(16px) rem(20px);
    border-bottom: 1px solid $border_color;

    .options-group {
        margin-bottom: rem(-8px);
    }

    .input__inner_info {
        position: relative;
    }
}

.info-list__input_last {
    border-bottom: 0;
}

.info-list__input__inner {
    position: relative;

    .input__checkbox-label {
        width: 100%;
        padding-left: 0;
        padding-right: rem(30px);

        &::before {
            left: auto;
            right: 0;
        }
    }
}

.info-list__input__label {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    font-size: rem(13px);
    line-height: 1.231;
    margin: 0;
    display: flex;
    align-items: center;
    color: $title-color;

    > label {
        font-size: rem(13px);
        line-height: 1.231;
        margin: 0;
        color: $title-color;
    }
}

.info-list__input__text-input {
    padding: rem(20px) 0 0 0;
    border: 0;
    background: transparent;
    font-size: rem(13px);
    line-height: 1.2;
    width: 100%;
    height: rem(40px);
    color: $dark;

    &:hover,
    &:focus {
        box-shadow: none;
        outline: none;
    }

    &.error {
        border-bottom: 1px solid $error-300;
    }
}

.info-list__input__basic-label {
    font-size: rem(13px);
    line-height: 1.231;
    margin: 0 0 rem(4px);
    display: flex;
    align-items: center;
    color: $title-color;
}

.info-list__input__fake-textarea {
    font-size: rem(13px);
    line-height: 1.5;
    color: $dark;
    min-height: rem(40px);

    &:empty::before {
        opacity: .5;
        content: "Комментарий к себестоимости";
        pointer-events: none;
        user-select: none;
    }
    &:focus-within::before {
        display: none;
    }
}

.info-list__input_combo {
    position: relative;

    display: flex;
    align-items: center;

    .info-list__input__inner {
        flex: 1;
        margin-right: rem(16px);
    }

    .info-list__input__btn::after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .item__product-id {
        button,
        a {
            position: relative;
            z-index: 1;
        }

    }

    .product-summary {
        position: relative;
        z-index: 1;
    }
}

.info-list__input__btn {
    width: rem(38px);
    height: rem(38px);
    padding: 0;
    border: 0;
    background: transparent;
    color: $blue;
    margin-right: rem(-11px);
    font-size: rem(16px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.info-list__row {
    display: grid;
    grid-template-columns: 2fr 1fr;
}

.info-list__input_dasibled {
    background: $grey-100;
}

.info-list-orders {
    margin: rem(-20px);

    .multi-select__dropdown {
        position: static;
        box-shadow: none;
    }

    .multi-select__dropdown__body {
        overflow: visible;
        max-height: none;
    }
}

.info-list__input__placeholder {
    opacity: .5;
}

.info-list__more {
    padding: rem(20px);
    text-align: center;

    .btn-default {
        i {
            margin-right: rem(8px);
            font-size: rem(16px);
        }
    }
}

.info-list__more__btn {
    border: 0 none;
    background: transparent;
    color: $blue;
    border-bottom: 1px dashed $blue;
    padding: 1px 0;
}

.modal {
    .info-list {
        margin: rem(-20px);
    }
}

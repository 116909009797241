.partners-table {
    position: relative;

    thead {
        position: sticky;
        top: rem(50px);
        z-index: 1;

        th {
            background: $white;
            border-top: 0;
        }
    }
}

.slider-panels {

    .days_period_row {
        .day_item_outer {
            width: auto;
            flex: 1;
        }
    }

    .day_item_outer {

        .day_block_item {
            cursor: default;

            .block_item_head {
                cursor: default;
                padding: rem(16px);

                .block_item_title {
                    display: block;
                    margin-bottom: 0;
                }
            }

            .item_content {
                @media (min-width: 1200px) {
                    grid-template-columns: repeat(3, 1fr);
                }

                .item_content_inner:first-child {
                    grid-column: auto;

                    .item_content_data {
                        font-size: rem(13px);
                        font-weight: 400;
                    }
                }
            }

        }
    }


}


.slider-panels_warehouse {
    swiper-slide:nth-child(1) .block_item_head {
        background: #6778F4;
    }

    swiper-slide:nth-child(2) .block_item_head {
        background: #525DB5;
    }

    swiper-slide:nth-child(3) .block_item_head {
        background: #15259D;
    }
}

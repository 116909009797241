.error-page {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: rem(48px) rem(16px);
    background: $light_grey;
}

.error-page__logo {
    flex-shrink: 0;
    margin-bottom: rem(48px);
}

.error-page__body {
    display: grid;
    grid-template-columns: 100%;
    gap: rem(24px);
    width: 100%;
    padding: rem(24px);
    border-radius: rem(16px);
    background: $white;
    max-width: rem(860px);
    margin: auto;
    font-size: rem(16px);
    line-height: 1.5;

    @media (min-width: 768px) {
        grid-template-columns: auto rem(290px);
        gap: rem(48px);
        padding: rem(48px);
    }
}

.error-page__body_center {
    align-items: center;
}
.error-page__title {
    font-size: rem(24px);
    line-height: 1.2;
    margin: 0 0 rem(16px);
    color: $title-color;
    font-weight: 600;

    @media (min-width: 768px) {
        font-size: rem(36px);
        margin: 0 0 rem(24px);
    }
}

.error-page__actions {
    display: flex;
    gap: rem(16px);
    margin: rem(24px) 0 0;
    font-size: rem(13px);

    @media (min-width: 768px) {
        margin: rem(48px) 0 0;
    }

    .btn-default {
        gap: rem(8px);
        min-width: auto;
        flex: 1;

        @media (min-width: 768px) {
            flex: none;
        }

        .fm-arrow-left {
            font-size: rem(16px);
        }
    }
}

.error-page__image {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
}

@import 'variables';
@import 'functions';
@import 'mixin';
@import '_type';
@import '_bootstrap';
@import '_select2';
@import '_swiper.min';
@import '_feedback';
@import '_magnific-popup';
@import '_owl.carousel.min';
@import '_owl.theme.default.min';
@import '_daterangepicker';
@import '_footable.bootstrap';
@import 'icon-font';

@import 'common';
@import 'forms';
@import 'assets';
@import 'settings';
@import 'self-purchase';

@import 'table-tabs';
@import 'status-tag';

@import 'modal-basic';
@import 'modal-fullscreen';
@import 'dashboard-nav';
@import 'item';
@import 'filter-modal';
@import 'info-list';

@import 'notifications';
@import 'sd-select';

@import 'slider-panels';
@import 'select-pagination';
@import 'partners-table';

@import 'importErrorsModal';

@import 'header-tabs';
@import 'panels-chart';
@import 'pricing';
@import 'payment-success';

@import 'buttons-panel';

@import 'reports-import-modal';

@import 'error-page';

@import 'add-marketplace-form';

@import 'notice';

@import 'loading-wrapper';

*:focus {
    outline: none;
}

button:focus,
input:focus,
a:focus {
    outline: none;
}

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
}

.fixed__body {
    overflow: hidden;
}

body {
    font-family: Open Sans, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 13px;
    line-height: 19px;
    font-weight: 400;
    color: $dark;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    // min-height: 100vh;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

h3,
.h3 {
    font-size: 17px;
    font-weight: 400;
}

.main-wrapper {
    flex-grow: 1;
    background-color: #F9F9F9;
}

.main-wrapper .dash__board__wrapper {
    display: flex;
    display: -webkit-flex;
    width: 100%;
}

.dash__board__container {
    @include prefix_val_inn((display: flex,
        ), webkit);
    @include prefix((flex-direction: column), webkit);
    //min-height: 100vh;
    width: calc(100% - 100px);
    margin-left: rem(100);
    background-color: #F9F9F9;
    padding-top: rem(50);
}

footer {
    margin-left: rem(100);
    background-color: #F9F9F9;

    &.long-bottom-padding {
        padding-bottom: rem(61);
    }
}

.main_container {
    @include prefix((flex-grow: 1), webkit);
    width: 100%;
}

.toggle_filter {
    display: none;
    @include prefix((align-items: center,
            justify-content: center), webkit);
}

.top_hidden_text {
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
    position: relative;
    padding: rem(20px) rem(9px) rem(20px) rem(20px);
    font-size: rem(13);
    line-height: 1.2;

    .hideText {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: rem(38px);
        height: rem(38px);
        flex-shrink: 0;
        font-size: rem(16px);
        color: $dark_grey;
        cursor: pointer;
        margin-left: rem(12px);
    }

    .video_text {
        flex: 1;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    &.with_video {
        background-color: white;
    }
}

.dashboard_panel {
    position: relative;
    padding: 20px;
    width: 100%;
    background-color: #F9F9F9;

    &.settings {
        background-color: $white;
        padding: 0;
    }
}

.top_hidden_text + .dashboard_panel.settings {
    border-top: 1px solid $border_color;
}

@media (min-width: 768px) {
    .dashboard_panel {
        &.settings {
            background-color: $light_grey;
            padding: 20px;
        }
    }

    .top_hidden_text + .dashboard_panel.settings {
        border-top: 0;
    }
}

.dashboard_panel_pt {
    padding-top: 0;
}

.dashboard_panel_dashboard {
    padding-bottom: 0;

    @media (min-width: 1081px) {
        margin-top: rem(16px);
    }
}

.table_outer {
    background-color: transparent;
    border-radius: 12px;
}

.table_outer_products {
    position: relative;
}

@media (min-width: 768px) {
    .table_outer {
        padding: 0 rem(20px);
    }

    .table_outer_products {
        margin-top: rem(16px);
    }

    // .dashboard_panel_dashboard {
    //     margin-top: rem(38px);
    // }
}

// @media (min-width: 1200px) {
//     .dashboard_panel_dashboard {
//         margin-top: rem(16px);
//     }
// }

.dashboard_panel_inner {
    background-color: $white;
    border-radius: 12px;
    padding: 25px 20px 20px;
}

.text_video_block {
    @include prefix_val_inn((display: flex,
        ), webkit);
    @include prefix((align-items: center), webkit);
}

.text_video_block {
    .text_block {
        flex-grow: 1;
        padding-right: rem(75);

        p {
            margin-bottom: 0;
        }
    }
}

.video_block_videoButton {
    flex: 0 0 auto;
    display: block;
    position: relative;
    width: auto;
    max-width: 140px;

    &:before {
        content: '';
        display: block;
        position: relative;
        width: rem(140);
        padding-top: 55%;
    }

    .videoThumb {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }

    span.play_button {
        position: absolute;
        right: rem(10);
        top: 50%;
        transform: translateY(-50%);
        display: block;
        height: rem(40);
        width: rem(40);
        border: 2px solid $white;
        border-radius: 50%;

        &:after {
            content: '\ea1d';
            font-family: 'sellerboard', sans-serif !important;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-37%, -50%);
            font-size: rem(17);
            color: $white;
        }
    }
}

.modal.video_modal {
    .modal-dialog {
        max-width: 595px;
    }

    button.close {
        border: 0 none;
        background-color: transparent;
    }
}

.panel-body {
    padding: rem(19) 0 rem(20);

    &.table-responsive {
        min-height: 200px;
    }
}

.other_body {
    display: inline-block;
}

.settings-mail {
    font-size: rem(15);
}

table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 18px;
    border-collapse: collapse;
    border-spacing: 0;
}

.dashboard_panel table thead th:last-of-type,
.dashboard_panel table tbody tr td:last-of-type {
    text-align: center;
}

table tbody tr td:last-of-type .icon-delete {
    font-size: rem(17);
    cursor: pointer;
    transition: color .3s ease-in-out 0s;

    &:hover {
        color: $danger;
    }
}

.dashboard_panel table.table-hover tr:hover td {
    background-color: #fafafa;
}

table thead th,
table tbody td {
    font-size: 13px;
    font-weight: 400;
    white-space: normal;
    vertical-align: middle;
}

table.settings_table thead th {
    padding: 5px 12px 20px;
    border-bottom: 1px solid #F0F0F0;
}

table.settings_table thead th:first-of-type,
table.settings_table tbody td:first-of-type {
    padding-left: 0;
}

table.settings_table thead th:last-of-type,
table.settings_table tbody td:last-of-type {
    padding-right: 0;
}

table.settings_table tbody td {
    padding: 12px;
    border-bottom: 1px solid #F0F0F0;
}

.popover_block {
    position: relative;
    height: 100%;
    max-width: 100%;

    .popover_block_link {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: rem(190);
    }

    &_item {
        display: none;
        position: absolute;
        left: 0;
        top: calc(100% + 8px);
        padding: rem(8) rem(15) rem(15);
        width: rem(250);
        background-color: $white;
        box-shadow: 0 8px 18px -6px rgba(24, 39, 75, 0.12), 0 12px 42px -4px rgba(24, 39, 75, 0.12);
        border: 0;
        border-radius: 8px;
        z-index: 3;

        &_key {
            color: $dark_grey;
            padding-top: rem(5);

            >span {
                display: block;
                padding-bottom: 5px;
                line-height: 1.4615;
            }

            .key_data {
                display: block;
                color: $black;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &_inner {
            display: grid;
            grid-template-columns: 1fr auto;
            grid-gap: 15px;
        }
    }

    &.active {
        .popover_block_item {
            display: block;
        }
    }
}

.price_popover {
    position: relative;
    height: 100%;
    max-width: 100%;

    .price_popover_link {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: rem(190);
    }

    .open_price_popover {
        display: inline-grid;
        grid-auto-flow: column;
        grid-gap: rem(6);
        align-items: center;

        [class^="fm-"],
        [class*=" fm-"] {
            font-size: 15px;
        }
    }

    &_item {
        display: none;
        position: absolute;
        left: rem(20);
        top: 100%;
        padding: rem(8) rem(15) rem(15);
        width: rem(250);
        background-color: $white;
        box-shadow: 0 8px 18px -6px rgba(24, 39, 75, 0.12), 0 12px 42px -4px rgba(24, 39, 75, 0.12);
        border: 0;
        border-radius: 8px;
        z-index: 3;

        &_inner {
            display: grid;
            grid-template-columns: 1fr auto;
            grid-gap: 15px;
        }
    }

    &.active {
        .price_popover_item {
            display: block;
        }
    }
}

.popover_title {
    width: 100%;

    .popover_input {
        display: block;
        width: 100%;
        font-size: rem(16px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 10px 0 8px;
        color: $black;
        border-color: #DDDDDD;
        border-width: 0 0 1px 0;
        border-radius: 0;

        &:focus {
            outline: none;
            border-color: $blue;
        }
    }

    @media (min-width: 768px) {
        .popover_input {
            font-size: rem(13px);
        }
    }
}

.price_popover_title {
    width: 100%;

    >label {
        display: none;
    }

    .price_popover_input {
        display: block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: right;
        padding: 10px 5px 8px 0;
        color: $black;
        border-color: #DDDDDD;
        border-width: 0 0 1px 0;
        border-radius: 0;
        -webkit-border-radius: 0;
        font-size: rem(16px);

        &:focus {
            outline: none;
            border-color: $blue;
            box-shadow: none;
        }

        &::placeholder,
        &::-webkit-input-placeholder {
            color: $black;
        }
    }

    @media (min-width: 768px) {
        .price_popover_input {
            font-size: rem(13px);
        }
    }
}

.controls_buttons {

    .popover_status,
    .close_popover {
        @include prefix_val_inn((display: flex,
            ), webkit);
        @include prefix((align-items: center,
                justify-content: center), webkit);
        height: 22px;
        width: 22px;
        font-size: rem(15);
        color: $dark_grey;
        border-radius: 4px;
    }

    .popover_status {
        background: $blue;

        .fm-check {
            color: $white;
        }
    }

    .close_popover {
        cursor: pointer;
        border: 1px solid #E4E4E4;
        margin-top: rem(5);
    }
}

.popover_link_button {
    display: inline-flex;
    align-items: center;
    height: rem(40px);
    text-decoration: none;
    border-radius: rem(20px);
    padding: rem(8px) rem(16px) rem(8px) rem(8px);
    color: $title-color;
    background-color: $white;
    border: 1px solid $grey-200;

    // &:hover,
    // &:focus {
    //     text-decoration: none;
    //     color: $dark;
    //     border-color: $dark_grey;
    // }

    &.success {

        .popover_link_button__ico {
            background-color: $success;
            color: $white;
        }
    }

    &.error {

        .popover_link_button__ico {
            background-color: $danger;
            color: $white;
        }
    }

    &.warning {
        background-color: $light_grey;
        border-color: $grey-400;
        color: $dark;

        .popover_link_button__ico {
            background-color: $danger;
            color: $white;
        }
    }

    .popover_link_button__ico {
        width: rem(24px);
        height: rem(24px);
        border-radius: 50%;
        font-size: rem(16px);
        line-height: rem(16px);
        flex-shrink: 0;
        margin-right: rem(8px);
        display: flex;
        align-items: center;
        justify-content: center;

    }

    &.placeholder {
        border-style: dashed;
        border-color: $grey-400;

        // &:hover,
        // &:focus {
        //     border-color: $dark_grey;
        // }
    }

    &._disabled {
        background-color: $light_grey;
        border-color: $grey-400;
        color: $dark;
        padding: rem(8px) rem(16px);
    }
}

.popover_info {
    font-size: 11px;
    padding-top: rem(3);
    display: none;

    &.error {
        color: $danger;
    }

    &.connect {
        color: $success;
    }
}

.popover_block_item.connecting {
    .popover_title {
        .popover_input {
            color: $black;
        }
    }

    .popover_info.connect {
        display: block;
    }
}

.popover_block_item.error_key {
    .popover_title {
        .popover_input {
            color: $danger;
            border-color: $danger;
        }
    }

    .popover_info.error {
        display: block;
    }
}

.popover_block_item.success_key {
    .popover_title {
        .popover_input {
            color: $black;
        }
    }
}

.add_button_outer {
    @include prefix_val_inn((display: flex,
        ), webkit);
    @include prefix((align-items: center,
            justify-content: flex-end), webkit);
    padding: 12px 0 30px;
}

.add_button {
    height: 37px;
    @include prefix_val_inn((display: flex,
        ), webkit);
    @include prefix((align-items: center,
        ), webkit);
    background-color: #F0F0F0;
    color: $dark_grey;
    text-decoration: none;
    padding: rem(5) rem(40);

    &:hover {
        color: $dark_grey;
        text-decoration: none;
    }
}

.other_panel h3 {
    padding-bottom: rem(10);
    border-bottom: 1px solid $border_color;
}

.more_popover_outer {
    color: $blue;
    position: relative;
    height: 100%;
    width: 100%;
    @include prefix_val_inn((display: flex,
        ), webkit);
    @include prefix((align-items: center,
            justify-content: center), webkit);

    .open_more_popover {
        position: relative;
        display: inline-block;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            border-bottom: 1px dotted;
        }

        &:hover,
        &:active {
            text-decoration: none;
        }
    }

    .more_popover_item {
        display: none;
        position: absolute;
        left: 50%;
        margin-left: rem(-157.5);
        top: 0;
        height: auto;
        background-color: $white;
        width: rem(315);
        padding: rem(28) rem(15) rem(10);
        box-shadow: 0 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12);
        border-radius: 8px;
        z-index: 10;

        .close_more_popover {
            position: absolute;
            top: rem(7);
            right: rem(7);
            height: rem(25);
            width: rem(25);
            @include prefix_val_inn((display: flex,
                ), webkit);
            @include prefix((align-items: center,
                    justify-content: center), webkit);
            cursor: pointer;

            span {
                color: $dark_grey;
                font-size: rem(12);

                &:hover {
                    color: $black;
                }
            }
        }
    }

    &.active .more_popover_item {
        display: block;
    }
}

.dashboard-table .more_popover_item {
    left: auto;
    right: 0;
    top: 0;
}

@media (min-width: 768px) {
    .dashboard-table .more_popover_item {
        top: 65%;
    }
}

.more_item_list {
    li {
        font-weight: 600;
        position: relative;
        padding: 0 0 rem(1) rem(17);

        &:not(:first-of-type)>div {
            padding-top: rem(3);
        }

        &:not(:last-of-type) {
            border-bottom: 1px solid #E9E9E9;
        }

        >div {
            @include prefix_val_inn((display: flex,
                ), webkit);
            @include prefix((align-items: center,
                    justify-content: space-between), webkit);
        }

        &.has-children {
            cursor: pointer;

            ul {
                display: none;
                padding-top: rem(5);
                padding-bottom: rem(5px);

                li {
                    font-weight: 400;
                    border: 0;
                    line-height: 1.2;
                    padding-left: 0;

                    &:not(:last-child) {
                        margin-bottom: rem(4px);
                    }
                }
            }

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: rem(10);
                width: 0;
                height: 0;
                border-color: $blue transparent transparent transparent;
                border-style: solid;
                border-width: 7px 4px 0 4px;
                flex-shrink: 0;
                transition: transform .3s ease-in-out 0s;
            }

            &.active:before {
                transform: rotate(180deg);
            }
        }
    }

    >li:nth-last-of-type(-n+5) {
        background-color: #F5F5F5;
    }
}

label {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 8px;
}

input.form-control,
textarea.form-control {
    -webkit-appearance: none;
}

.form-control {
    border: 1px solid #EEEEEE;
    background-color: #fff;
    padding: 12px 24px;
    border-radius: 8px;
    font-size: rem(16px);
}

@media (min-width: 768px) {
    .form-control {
        font-size: rem(13px);
    }
}

.modal_change_form {
    .form-group {
        position: relative;
        margin-bottom: rem(20);

        &.picker_group label {
            position: absolute;
            right: rem(20);
            top: 50%;
            transform: translateY(-50%);
            line-height: 1;
            margin-bottom: 0;
            cursor: pointer;

            span {
                font-size: rem(24);
                color: $dark_grey;
            }
        }
    }

    .form-control {
        border: 1px solid #F0F0F0;
        background-color: $white;
        font-size: rem(16px);
        padding: 10px 20px;

        &:focus {
            border-color: $blue;

            &~label span {
                color: $blue;
            }
        }
    }

    @media (min-width: 768px) {
        .form-control {
            font-size: rem(13px);
        }
    }
}

.form-control[disabled],
fieldset[disabled] .form-control {
    cursor: not-allowed;
}

//.tooltip-icon {
//    position: absolute;
//    transform: translateY(-4px);
//    height: 23px;
//    width: 23px;
//    display: inline-block;
//    margin-left: rem(5);
//}
//
//.tooltip-icon>span {
//    font-size: rem(20);
//    color: $dark_grey;
//}

.tippy-box {
    background-color: $white;
    color: $dark;
    font-size: rem(12);
    border-radius: rem(8);
    line-height: rem(17.54);
    text-align: left;
    padding: rem(12) rem(15) rem(15);
    // max-width: 392px !important;
    box-shadow: 0 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12);
    word-break: break-word;

    .tippy-content {
        padding: 0;

        hr {
            border-top: 1px solid $border_color;
        }
    }
}

.tippy-box[data-placement^='top'] > .tippy-arrow {
    border-top-color: $white;
}

.tippy-box[data-placement^='bottom'] > .tippy-arrow {
    border-bottom-color: $white;
}

.tippy-box[data-placement^='left'] > .tippy-arrow {
    border-left-color: $white;
}

.tippy-box[data-placement^='right'] > .tippy-arrow {
    border-right-color: $white;
}

.tippy-tooltip {
    background-color: $white;
    color: $dark;
    font-size: rem(12);
    border-radius: rem(8);
    line-height: rem(17.54);
    text-align: left;
    padding: rem(12) rem(15) rem(15);
    // max-width: 392px !important;
    box-shadow: 0 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12);
    word-break: break-word;

    .tippy-content {
        padding: 0;
    }
}

.tippy-tooltip[data-placement^='top'] > .tippy-arrow {
    border-top-color: $white;
}

.tippy-tooltip[data-placement^='bottom'] > .tippy-arrow {
    border-bottom-color: $white;
}

.tippy-tooltip[data-placement^='left'] > .tippy-arrow {
    border-left-color: $white;
}

.tippy-tooltip[data-placement^='right'] > .tippy-arrow {
    border-right-color: $white;
}

.feedback_block {
    padding: rem(20) rem(15);
    text-align: center;

    p {
        margin-bottom: 0;
    }
}

/* mouse, touch pad */
@media (hover: hover) and (pointer: fine) {
    .feedback_block a:hover {
        color: $black;
    }
}

//Dashboard
.dashboard_panel .top_filter_row {
    @include prefix_val_inn((display: flex,
        ), webkit);
    @include prefix((align-items: center), webkit);
    margin-left: rem(-20);
    margin-right: rem(-20);
    margin-bottom: rem(20px);

    >div {
        padding: 0 rem(20);
    }
}

.dashboard_panel .top_filter_row:last-child {
    margin-bottom: 0;
}

.search_outer {
    position: relative;
    flex: 1;
    -webkit-flex: 1;

    >label {
        position: absolute;
        left: rem(39);
        top: rem(12);
        margin-bottom: 0;
        line-height: 1;
        cursor: pointer;
        z-index: 3;

        span {
            font-size: rem(17);
            color: $dark_grey;
        }
    }

    .select2 {

        .select2-selection {
            display: grid !important;
            grid-auto-flow: column;
            grid-auto-columns: 1fr auto;
            grid-gap: 3px;
            border: 1px solid #F0F0F0;
            background-color: $white;
            padding: 0;
            border-radius: rem(8);

            .product_counter {
                position: relative;
                display: grid;
                grid-auto-flow: column;
                grid-gap: rem(20);
                @include prefix((align-items: center), webkit);
                font-weight: 500;
                color: #000;
                padding-right: rem(10);
                padding-left: rem(12);
                background-color: #f0f0f0;
                margin: 2px;
                border-radius: 8px;

                .clear_all {
                    @include prefix_val_inn((display: flex,
                    ), webkit);
                    @include prefix((align-items: center,
                        justify-content: center), webkit);
                    line-height: 16px;
                    height: rem(15);
                    cursor: pointer;
                    position: relative;
                    width: rem(15);

                    &:before {
                        content: '\ea13';
                        font-family: 'sellerboard', sans-serif;
                        font-size: rem(18);
                        color: $dark_grey;
                    }
                }
            }
        }

        .select2-selection__rendered {
            position: relative;
            height: 100%;
            padding: 0 !important;

            .select2-search {
                position: relative;
                height: 100%;
                width: 100%;

                input.select2-search__field {
                    margin-top: 0;
                    height: rem(40);
                    width: 100% !important;
                    padding: rem(5) 0 rem(6) rem(46);
                    border-radius: rem(8);
                    background-color: $white;
                }
            }
        }
    }

    .select2-container {
        width: 100% !important;

        &.modal_select {
            width: 100%;
            padding: rem(3) 60px rem(3) rem(3);
            height: 40px;
            border: 1px solid #F0F0F0;
            border-radius: rem(8);

            .select2-selection {
                height: 100%;
                width: 100%;
            }
        }
    }
}

.search_outer.desktop_select {
    max-width: 50%;
    min-width: rem(300);
}

.search_outer.disable_select .select2-container,
.search_outer.disable_select>label {
    pointer-events: none;
}

.select2-container.check-select {
    padding-top: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    @include prefix_val_inn((display: flex,
        ), webkit);
    @include prefix((align-items: center,
        ), webkit);
    position: absolute;
    right: 2px;
    max-width: 200px;
    width: 42%;
    min-width: 170px;
    top: 50%;
    transform: translateY(-50%);
    height: 90%;
    background: #F0F0F0;
    border-radius: 8px;
    overflow: hidden;
    border: 0 none;
    padding-right: rem(30);
    margin: 0;
    z-index: 3;

    span.opt_image {
        width: rem(30);

        img {
            width: 100%;
            height: auto;
        }
    }

    .opt_text {
        display: block;
        max-width: calc(100% - 27px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #000;
        padding-left: 5px;
    }

    .select2-selection__choice__remove {
        position: absolute;
        right: rem(7);
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
        font-size: 24px;
        font-weight: 400;
        line-height: 1;
    }
}

.select__outer {
    .select2-container--default.modal_select {
        width: 100% !important;
        border: 1px solid #f0f0f0;
        border-radius: 8px;

        .select2-selection--single:after {
            content: '\e930';
            font-family: 'sellerboard', sans-serif;
            position: absolute;
            right: rem(15);
            top: 50%;
            transform: translateY(-50%);
            font-size: 18px;
            color: $dark_grey;
            transition: transform .3s ease-in-out 0s;
        }

        &.select2-container--open .select2-selection--single:after {
            transform: translateY(-50%) rotateX(180deg);
        }

        .select2-selection--single {
            border: 0 none;
            border-radius: 8px;
            height: 40px;
            padding: 2px 60px 2px 2px;
            display: flex;
            display: -webkit-flex;
            align-items: stretch;

            .select2-selection__arrow {
                width: 0;
                height: 0;
                display: none;
            }

            .select2-selection__rendered {
                position: relative;
                line-height: rem(38);
                display: flex;
                align-items: center;
                flex-grow: 1;
                border-radius: 8px;
                //text-transform: lowercase;
                padding-left: 0;
                background-color: #F0F0F0;
                padding-right: 0;

                .opt_image {
                    display: block;
                    position: relative;
                    height: rem(38);
                    width: rem(38);
                    flex-shrink: 0;
                    margin-right: 10px;
                    margin-top: -1px;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }

                .opt_text {
                    font-size: 12px;
                    width: calc(100% - 48px);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding-right: rem(24);
                }

                .select2-selection__clear {
                    position: absolute;
                    right: 0;
                    padding: 0 9px;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 12px;
                    color: $dark_grey;
                }
            }

            .select2-selection__placeholder {
                text-transform: lowercase;
                display: block;
                height: 100%;
                font-size: rem(16px);
                line-height: 35px;
                background-color: $white;
                padding-left: 18px;
                width: 100%;
            }

            @media (min-width: 768px) {
                .select2-selection__placeholder {
                    font-size: rem(13px);
                }
            }

            .select2-selection__placeholder:first-letter {
                text-transform: capitalize !important;
            }
        }
    }



    .select2-container--default.expense-type-select {
        width: 100% !important;
        border: 1px solid #f0f0f0;
        border-radius: 8px;

        .select2-selection--single:after {
            content: '\e930';
            font-family: 'sellerboard', sans-serif;
            position: absolute;
            right: rem(15);
            top: 50%;
            transform: translateY(-50%);
            font-size: 18px;
            color: $dark_grey;
            transition: transform .3s ease-in-out 0s;
        }

        &.select2-container--open .select2-selection--single:after {
            transform: translateY(-50%) rotateX(180deg);
        }

        .select2-selection--single {
            border: 0 none;
            border-radius: 8px;
            height: 40px;
            padding: 2px 60px 2px 2px;
            display: flex;
            display: -webkit-flex;
            align-items: stretch;

            .select2-selection__arrow {
                width: 0;
                height: 0;
                display: none;
            }

            .select2-selection__rendered {
                position: relative;
                line-height: rem(38);
                display: flex;
                align-items: center;
                flex-grow: 1;
                border-radius: 8px;
                padding-left: 18px;
                padding-right: 0;
            }

            .select2-selection__placeholder {
                text-transform: lowercase;
                display: block;
                height: 100%;
                font-size: rem(16px);
                line-height: 35px;
                background-color: $white;
                padding-left: 18px;
                width: 100%;
            }

            @media (min-width: 768px) {
                .select2-selection__placeholder {
                    font-size: rem(13px);
                }
            }

            .select2-selection__placeholder:first-letter {
                text-transform: capitalize !important;
            }
        }
    }

}

.modal_select {
    .select2-search--dropdown {
        display: block;
        padding: 2px;

        .select2-search__field {
            border: 0 none;
            padding-left: 18px;
            height: rem(40px);
            border-radius: 8px;
            font-size: rem(16px);

            &:focus {
                outline: none;
                box-shadow: none;
            }

            &::-webkit-input-placeholder {
                opacity: 1;
                color: #999;
            }

            &:-ms-input-placeholder {
                opacity: 1;
                color: #999;
            }

            &::placeholder {
                opacity: 1;
                color: #999;
            }
        }

        @media (min-width: 768px) {
            .select2-search__field {
                font-size: rem(13px);
            }
        }
    }

    .select2-results__option {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        padding-left: rem(10);
        min-height: 3.125rem;
        position: relative;
    }

    .select2-results__option:not(:last-of-type) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    .select2-results__option span.opt_image {
        flex-shrink: 0;
        width: 2.5rem;
    }

    .select2-results__option span.opt_text {
        margin-left: rem(10);
        display: block;
    }

    .select2-results__option span.opt_text_block {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

}

.modal_select .select2-dropdown,
.modal_select.select2-container--open .select2-dropdown--below {
    display: block;
    margin-top: 3px;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    box-shadow: 0 8px 18px -6px rgba(24, 39, 75, 0.12), 0 12px 42px -4px rgba(24, 39, 75, 0.12);
}

.search_outer.modal_select {
    width: 100%;
    max-width: rem(422);
    position: relative;

    label {
        left: rem(20);
    }

    .select2-container--default .select2-selection--multiple .select2-selection__choice {
        width: rem(190);
    }

    .fake-input {
        box-sizing: border-box;
        width: 100%;
        height: 40px;
        background-color: $white;
        border: 1px solid #F0F0F0;
        border-radius: rem(8);
        padding: rem(5) rem(20) rem(5) rem(47);
        pointer-events: none;
    }

    .selected_item {
        height: calc(100% - 4px);
        display: inline-grid;
        grid-auto-flow: column;
        align-items: center;
        grid-gap: rem(8);
        position: absolute;
        right: 0;
        top: 0;
        margin: 2px;
        background: #F0F0F0;
        border-radius: 8px;
        padding: rem(2) rem(8) rem(2) rem(10);

        img {
            height: rem(30);
            width: rem(29);
        }

        .product-name {
            font-size: 12px;
            font-family: 'Open Sans', sans-serif;
            font-weight: 500;
            color: #232323;
        }

        .fm-close {
            font-size: rem(10);
            color: $dark_grey;
        }
    }

    .disable_select input,
    .disable_select label {
        pointer-events: none;
    }

}

.select2-dropdown,
.select2-container--open .select2-dropdown--below {
    border: 2px solid #eaeaea;
    min-width: 400px;
}

.select2-container.check-select {
    .select2-results__option {
        @include prefix_val_inn((display: flex,
            ), webkit);
        @include prefix((align-items: center,
                justify-content: flex-start), webkit);
        padding-left: rem(45);
        min-height: rem(50);
        position: relative;

        &:not(:last-of-type) {
            border-bottom: 1px solid rgba(0, 0, 0, .15);
        }

        span.opt_image {
            flex-shrink: 0;
            width: rem(40);

            img {
                width: 100%;
                height: auto;
            }
        }

        .opt_text {
            margin-left: rem(6);
        }

        &:not(.select2-results__message):before {
            content: '';
            position: absolute;
            left: rem(15);
            top: 50%;
            transform: translateY(-50%);
            display: block;
            height: rem(16);
            width: rem(16);
            border: 1px solid rgba(0, 0, 0, .2);
            background: #fff;
            cursor: pointer;
            font-size: 0;
            border-radius: 2px;
        }

        &[aria-selected=true]:before {
            border: 1px solid white;
            background: #4e91ff url("../img/alerts-check-w.svg") no-repeat center center / contain;
        }

        &.select2-results__message {
            padding-left: rem(15);
        }
    }
}

.select2-container.check-select .select2-results__option.loading-results {
    display: none;
}

.select2-container--default.check-select .select2-results__option--highlighted[aria-selected=false] {
    background: -webkit-linear-gradient(left, #fff 15%, #f5f5f5 100%);
    background: linear-gradient(to right, #fff 15%, #f5f5f5 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f5f5f5', GradientType=1);
    color: #555;
}

.select2-dropdown .choose_buttons {
    display: none;
    padding: 3px rem(15);
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    cursor: pointer;

    button {
        border: 0 none;
        background-color: transparent;
        font-size: rem(15);
        text-decoration: underline;
        padding-bottom: rem(4);

        &:focus {
            outline: none;
        }
    }
}

/*DropDowns*/
.select_outer {
    //flex-basis: 0;
    //-webkit-box-flex: 1;
    //flex-grow: 1;
    max-width: 100%;
}

@media (min-width: 1025px) {
    .select_outer_ml-auto {
        margin-left: auto;
    }

    .datePicker_outer_ml-auto {
        margin-left: auto;
    }
}

.dropDown-parent,
.dropDown__parent {
    position: relative;
}

.dropDown-parent .input-dropDown {
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 42px;
    font-size: 13px;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #EEEEEE;
    border-radius: 8px;
    padding: 0 rem(32px) 0 3rem;

    //&.periodSelectInput {
    //  padding: 0 rem(17) 0 rem(25);
    //}
    >label {
        margin: 0;
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        line-height: 1;

        span {
            font-size: rem(15);
            color: $dark_grey;
        }
    }
}

.datePicker_inner {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 42px;
    font-size: 13px;
    background-color: #fff;
    border: 1px solid #EEEEEE;
    border-radius: 8px;
    padding: 0 rem(32px) 0 3rem;
    cursor: pointer;

    span {
        color: $dark_grey;
    }

    &:before {
        content: '\e929';
        font-family: 'sellerboard', sans-serif;
        position: absolute;
        color: $dark_grey;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        font-size: rem(15);
    }

    &:after {
        content: '\e930';
        font-family: 'sellerboard', sans-serif;
        position: absolute;
        color: $dark_grey;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.dropDown-parent .input-dropDown input {
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    background-color: transparent;
    font-size: 13px;
    color: $dark_grey;
    border-radius: 0;
    border: 0 none;
    padding: rem(10) rem(10) rem(10) rem(27);

    &:focus {
        outline: 0;
        box-shadow: none;
    }
}

.dropDownData {
    display: inline-block;
    //padding-bottom: rem(2);
    color: $dark_grey;
}

.dropDown-parent .input-dropDown:after {
    content: '\e930';
    font-family: 'sellerboard', sans-serif;
    position: absolute;
    color: $dark_grey;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
}

.dropDown-parent .dropdown--menu {
    opacity: 0;
    visibility: hidden;
    display: none;
    position: absolute;
    top: 150%;
    left: 0;
    right: auto;
    box-shadow: 0 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12);
    border-radius: rem(8);
    z-index: 10;
    //&:before {
    //  content: '';
    //  position: absolute;
    //  left: rem(23);
    //  background: #fff;
    //  width: 11px;
    //  height: 11px;
    //  border: 1px solid #d9d9d9;
    //  transform: rotate(45deg);
    //  top: -5px;
    //  border-radius: 1px;
    //  z-index: -1;
    //}
}

.dropDown-parent .dropdown--menu ul {
    height: 100%;
    padding: rem(10);
    text-align: left;
    //max-height: 280px;
    //overflow-y: auto;
    background-color: $white;
    margin-bottom: 0;
    border-radius: rem(8);
    width: 100%;
}

.dropList li {
    font-size: 13px;
    background: #f0f0f0;
    border: 1px solid #f0f0f0;
    color: #555;
    padding: rem(6.5) rem(12);
    -webkit-border-radius: rem(8);
    -moz-border-radius: rem(8);
    border-radius: rem(8);
    cursor: pointer;
    transition: background-color .2s ease-in-out 0s, border-color .2s ease-in-out 0s;

    &:hover,
    &.active {
        background-color: $blue;
        border-color: $blue;
    }

    &:not(:last-of-type) {
        margin-bottom: rem(8);
    }
}

.dropDown-parent .dropdown--menu ul li span {
    display: block;
    padding: 0;
    background-color: transparent;
    border: 1px solid transparent;
    color: $dark;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
    width: 100%;
    transition: color .2s ease-in-out 0s;
}

.dropDown-parent.disabled .input-dropDown input {
    pointer-events: none;
    cursor: pointer;
    color: var(--main-text);
    opacity: .6;
}

.dropDown-parent .dropdown--menu ul li.active span,
.dropDown-parent .dropdown--menu ul li:hover span {
    background: inherit;
    color: $white;
}

.input-dropDown .sup_items {
    padding-top: 3px;
}

.input-dropDown .sup_items li+li {
    margin-left: 0;
}

.input-dropDown .sup_items li {
    margin-bottom: .3em;
}

.input-dropDown .sup_items li:not(:last-of-type) {
    margin-right: 10px;
}

.dropDown-parent.dropMenu-opened+label {
    color: var(--main-elem-color);
}

.dropDown-parent.dropMenu-opened .dropdown--menu {
    display: block;
    opacity: 1;
    visibility: visible;
    top: 115%;
}

.days_period_row {
    margin-left: rem(-5);
    margin-right: rem(-5);
    @include prefix_val_inn((display: flex,
        ), webkit);
    margin-bottom: rem(16);
    position: relative;
    justify-content: center;

    .day_item_outer {
        width: 25%;
        // flex: 1;
        max-width: 100%;
        padding: 0 rem(5);
    }
}

.day_item_outer {
    .day_block_item {
        position: relative;
        background: $white;
        border: 1px solid $border_color;
        border-radius: rem(8);
        //overflow: hidden;
        height: 100%;
        display: flex;
        flex-direction: column;
        box-shadow: none;
        cursor: pointer;
        transition: box-shadow .2s ease-in-out 0s;

        .block_item_head {
            position: relative;
            padding: rem(11px) rem(56px) rem(11px) rem(16px);
            color: $white;
            line-height: 1.231;
            border-radius: rem(8) rem(8) 0 0;
            cursor: pointer;
            min-height: rem(38px);

            .block_item_title {
                font-weight: 600;
                margin-bottom: rem(5);
                display: none;

                @media (min-width: 1081px) {
                    display: block;
                }
            }
        }

        &.active {
            box-shadow: 0 6px 12px rgba(0, 0, 0, .12);
            border-bottom: 0;
            border-top: 0;
        }

        &.current_day .block_item_head {
            background-color: #6778F4;
        }

        &.current_day .block_item_footer:after {
            background: linear-gradient(92.5deg, rgba($blue, 0.8) 0.41%, rgba(81, 149, 250, 0.8) 100%);
        }

        &.yesterday .block_item_head {
            background-color: #757DC4;
        }

        &.yesterday .block_item_footer:after {
            background: linear-gradient(94.29deg, rgba(82, 93, 181, .8) 0%, rgba(142, 152, 233, .8) 100%);
        }

        &.current_month .block_item_head {
            background-color: #4451B1;
        }

        &.current_month .block_item_footer:after {
            background: linear-gradient(93.73deg, rgba(21, 37, 157, .8) 0.8%, rgba(89, 107, 238, .8) 100%);
        }

        &.month_forecast .block_item_head,
        &.month_forecast .block_item_footer:after {
            background: linear-gradient(94.29deg, rgba(48, 123, 165, .8) 0%, rgba(118, 183, 219, .8) 100%);
        }

        &.past_month .block_item_head {
            background-color: #777bfe;
        }

        &.past_month .block_item_footer:after {
            background: linear-gradient(94.29deg, #777bfe 0%, rgba(185, 193, 255, .8) 100%);
        }

        .item_content {
            padding: rem(16px) rem(20px);
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: rem(16px);

            .item_content_inner {
                &:first-child {
                    grid-column: span 2;

                    .item_content_data {
                        font-size: rem(16px);
                        font-weight: 600;
                    }
                }

            }

            .item_content_title {
                position: relative;
                z-index: 2;
                display: inline-grid;
                grid-auto-flow: column;
                align-items: center;
                grid-gap: rem(6);
                color: $dark;
                font-size: rem(12);
                margin-bottom: rem(2);

                span[class^="fm-"] {
                    font-size: rem(17.5);
                    color: #E9C02E;
                    cursor: pointer;
                }
            }

            .item_content_title__info {
                font-size: rem(17.5);
                color: #E9C02E;
                cursor: pointer;
            }

            .item_content_data {
                color: #232323;

                .spinner-border {
                    width: 1em;
                    height: 1em;
                    border-width: 2px;
                    vertical-align: middle;
                }
            }
        }

        &:after {
            content: '';
            display: block;
            height: rem(6);
            width: rem(6);
            background-color: transparent;
            position: absolute;
            left: 50%;
            bottom: 0;
            opacity: 0;
            transform: translate(-50%, 50%) rotate(45deg);
        }

        &.current_day:after {
            background-color: rgba(110, 147, 248, 1);
        }

        &.yesterday:after {
            background-color: rgba(139, 147, 215, 1);
        }

        &.current_month:after {
            background-color: rgba(96, 109, 210, 1);
        }

        &.month_forecast:after {
            background-color: rgba(117, 173, 205, 1);
        }

        &.past_month:after {
            background-color: rgba(169, 174, 244, 1);
        }

    }

    .block_item_footer {
        position: relative;
        padding: 0 rem(16) rem(16);
        text-align: center;
        border-radius: 0 0 rem(8) rem(8);
        margin-top: auto;

        a {
            position: relative;
            padding: 0 0 rem(2);
            color: #4154F1;

            &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: rem(1);
                border-bottom: 1px dotted #4154F1;
            }

            &:hover,
            &:active {
                text-decoration: none;
            }
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            height: rem(6);
            background-color: transparent;
            opacity: 0;
            border-radius: 0 0 rem(8px) rem(8px);
        }

        .block_item_footer__btn,
        .popover_button {
            border: 0 none;
            box-shadow: none;
            background: transparent;
            color: $blue;
            border-bottom: 1px dashed $blue;
            padding: 1px 0;
            position: relative;
            z-index: 2;

            &:focus {
                outline: none;
            }
        }
    }

    .day_block_item.active:after,
    .day_block_item.active .block_item_footer:after {
        opacity: 1;
    }
}


// @media (min-width: 768px) {
//     .day_item_outer .day_block_item .block_item_head .block_item_title {
//         display: block;
//     }
// }

.popover,
.popover.custom-popover {
    width: rem(340);
    max-width: rem(340);
    box-shadow: 0 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12);
    border: 1px solid rgba(0, 0, 0, 0.02);
    border-radius: 8px;
    z-index: 4;

    .popover-body {
        color: $dark;
    }
}

.popover.custom-popover .more_item_list li.has-children:before {
    top: rem(10);
}

.custom-popover-heading {
    position: relative;
    padding: rem(5);

    span {
        position: absolute;
        top: rem(-16);
        right: 0;

    }
}

.popover-body {
    padding: rem(28) rem(15) rem(10);
}

.product_table_outer {
    background-color: $white;
    border-radius: 8px;
}

.product_table_nav {
    @include prefix_val_inn((display: flex,
        ), webkit);
    @include prefix((align-items: center,
            justify-content: space-between), webkit);

    .table_nav_switcher {
        @include prefix_val_inn((display: flex,
            ), webkit);
        @include prefix((align-items: center,
                justify-content: flex-start), webkit);

        li {
            padding: 0 20px;
        }

        a {
            height: rem(50);
            @include prefix_val_inn((display: flex,
                ), webkit);
            @include prefix((align-items: center,
                    justify-content: flex-start), webkit);
            text-decoration: underline;
            padding: 10px 0;
        }

        .switcher_item {
            position: relative;
            display: grid;
            grid-auto-flow: column;
            grid-gap: 10px;
            color: $dark_grey;
            text-decoration: none;

            span {
                font-size: 20px;
            }

            &:hover,
            &:active {
                text-decoration: none;
                color: $dark_grey;
            }

            &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 3px;
                background-color: transparent;
            }

            &.active {
                color: $blue;
            }

            &.active:after {
                background-color: $blue;
            }
        }
    }
}

.expenses-wrapper {
    &.no_items {
        // display: -webkit-flex;
        // display: flex;
    }
}

.empty_banner {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 100%;
    max-width: rem(350);
    padding: rem(20px);

    &_search {
        height: rem(48);
        display: inline-flex;
        display: -webkit-inline-flex;
        align-items: center;
        -webkit-align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        width: rem(48);
        border-radius: 50%;
        background-color: #F4F4F4;
        margin-bottom: rem(17);

        span {
            font-size: rem(20);
            color: $dark_grey;
        }
    }

    &_transparent {
        background: transparent;
    }

    &_title {
        font-size: rem(17);
        color: $title-color;
        font-weight: 600;
        margin-bottom: rem(11);
    }

    &_description {
        line-height: rem(17.7);
        color: $dark;
    }
}

.empty_banner-wrapper {
    width: 100%;

    & + .loading-wrapper {
        display: none;
    }
}

@media (min-width: 768px) {
    .expenses-wrapper {
        &.no_items {
            // padding-top: rem(70);
            // padding-bottom: rem(64);
        }
    }

    .empty_banner-wrapper {
        padding-top: rem(70);
        padding-bottom: rem(64);
    }
}

.expenses-wrapper {
    position: relative;

    // .table-condensed.expenses-table tbody tr td,
    // .table-condensed.expenses-table thead tr th,
    .table-condensed.dashboard-table tbody tr td,
    .table-condensed.dashboard-table thead tr th {
        height: 50px;
        padding: 5px;
        vertical-align: middle;
        color: $dark;
    }

    // @media (max-width: 760px) {
    //     .table-condensed.expenses-table tbody tr td,
    //     .table-condensed.expenses-table thead tr th {
    //         padding: 5px 15px;
    //     }
    // }

    .tooltip_outer {
        display: inline-block;
        margin-right: rem(7);
        position: relative;
        z-index: 1;
        transform: translateY(3px);
        touch-action: manipulation;

        // &::after {
        //     content: "";
        //     display: block;
        //     position: absolute;
        //     top: rem(-8px);
        //     left: rem(-8px);
        //     width: rem(32px);
        //     height: rem(32px);
        // }

        span[class^="fm-"] {
            font-size: 1.09375rem;
            color: #E9C02E;
            cursor: pointer;
        }
    }

    // .expenses-table thead,
    // .products-table thead,
    .dashboard-table thead {
        position: -webkit-sticky;
        position: sticky;
        background-color: $white;
        top: 50px;
        z-index: 3;

        >tr>th {
            border-bottom: 1px solid #f0f0f0;
            border-top: 1px solid #f0f0f0;
        }

        >tr>th:first-child {
            border-top-left-radius: 8px;
            border-left: 1px solid #f0f0f0;
        }

        >tr>th:last-of-type {
            border-top-right-radius: 8px;
        }
    }

    .expenses-table>tbody>tr {
        cursor: pointer;
    }

    .expenses-table>tbody>tr>td:last-of-type {
        text-align: center;
    }

    // .products-table tbody tr td {
    //     height: rem(60);
    // }

    .expenses-table,
    .products-table,
    .dashboard-table {
        tbody tr td {
            border-top: 0 none;
            vertical-align: middle;

            &.inner-text-block_top {
                vertical-align: top;
            }
        }

        tbody>tr:hover {
            background-color: #f9f9f9;

            >td {
                background-color: transparent;
            }
        }

        tbody>tr.total_table_row {
            background: #F6F6F6;
            cursor: default;

            &:hover {
                background: #F6F6F6;
            }

            >td:first-of-type {
                text-align: right;
                padding-right: rem(20);
                border-right-color: transparent;
                font-weight: 600;
            }

            >td:last-of-type, >td.total_td {
                text-align: left;
                font-weight: 600;
            }
        }
    }

    .products-table tbody>tr:hover,
    .dashboard-table tbody>tr:hover {
        background-color: #fbfbfb;
    }
}

.expenses-wrapper .products-table thead,
.expenses-wrapper .dashboard-table thead {
    &:before {
        content: '';
        height: rem(50);
        width: rem(20);
        position: absolute;
        // left: -20px;
        top: 0;
        background-color: transparent;
    }

    &:after {
        content: '';
        height: rem(50);
        width: rem(20);
        position: absolute;
        // right: -20px;
        top: 0;
        background-color: transparent;
    }

    &.nearTop {
        box-shadow: 0 6px 12px rgba(0, 0, 0, .06);

        &:before,
        &:after {
            background-color: $white;
        }

        >tr {
            position: relative;
            z-index: 2;
        }

        >tr>th {
            border-color: transparent;
        }
    }

    &.fixedTop {
        box-shadow: 0 6px 12px rgba(0, 0, 0, .06);

        &:before,
        &:after {
            background-color: $white;
        }

        >tr {
            position: relative;
            z-index: 5;

            >th {
                border-top-color: transparent;
            }
        }

        >tr:first-child th:first-child {
            border-top-left-radius: 0 !important;
        }

        >tr:first-child th:last-child {
            border-top-right-radius: 0 !important;
        }

    }
}

.expenses-table>tbody>tr>td {
    .inner-relative {

        >a,
        >a:hover,
        >a:active,
        >a:focus {
            //display: inline-flex;
            text-decoration: none;
            color: $dark;
        }

        .have_image {
            display: flex;
            align-items: center;
            width: 100%;

            img.product-thumbnail {
                height: auto;
                width: rem(28);
            }

            span {
                margin-left: 5px;
            }
        }

        span.fm-more-horizontal {
            font-size: rem(26);
            color: $blue;
            line-height: 1.2;
            opacity: .7;
        }

        .dropdown_toggle {
            height: auto;
            width: auto;
            border: 0 none;
            background-color: transparent;

            &:hover,
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }

        .dropdown-menu>li>a {
            display: block;
            padding: 9px 20px;
            clear: both;
            font-size: rem(13);
            font-weight: 400;
            line-height: 1.42857143;
            color: #333;
            white-space: nowrap;

            &:hover,
            &:active {
                background-color: #4e91ff;
                color: #fff;
            }
        }

        .dropdown-menu>li.disabled {
            opacity: .5;
            cursor: not-allowed;

            >a {
                pointer-events: none;
            }
        }
    }

    .inner-relative.show span.fm-more-horizontal,
    .dropdown_toggle:hover span.fm-more-horizontal {
        opacity: 1;
    }

    .inner-relative {
        .dropdown-menu {
            border: 0 none;
            border-radius: rem(8);
            box-shadow: 0 8px 18px -6px rgba(24, 39, 75, 0.12), 0 12px 42px -4px rgba(24, 39, 75, 0.12);
            padding: 0;
            overflow: hidden;

            li {
                &:not(:last-of-type) {
                    border-bottom: 1px solid #F0F0F0;
                }

                button {
                    min-height: rem(50);
                    display: flex;
                    display: -webkit-flex;
                    align-items: center;
                    -webkit-align-items: center;
                    justify-content: flex-start;
                    -webkit-justify-content: flex-start;
                    font-size: 13px;
                    padding: rem(5) rem(15);

                    &:focus,
                    &:hover {
                        outline: none;
                        box-shadow: none;
                        background-color: #f9f9f9;
                    }
                }
            }
        }
    }
}

// table.expenses-table,
table.dashboard-table {
    border-collapse: separate;
    border-spacing: 0;
    min-width: 210px;

    tr {

        th,
        td {

            border-bottom: 1px solid #f0f0f0;
            padding: 5px;
        }
    }

    tr:first-child th:first-child {
        border-top-left-radius: 8px;
    }

    tr:first-child th:last-child {
        border-top-right-radius: 8px;
    }

    tr:last-child td:first-child {
        border-bottom-left-radius: 8px;
    }

    tr td:first-child {
        border-left: 1px solid #f0f0f0;
    }

    tr:last-child td:last-child {
        border-bottom-right-radius: 8px;
    }
}

table.dashboard-table {
    margin-bottom: 0;

    tr:last-child td:first-child {
        border-bottom-left-radius: 0;
    }

    tr:last-child td:last-child {
        border-bottom-right-radius: 0;
    }
}

table.products-table {
    margin-bottom: 0;
}

table.products-table tr td.inner-text-block {
    .text_block {
        height: 100%;
        width: 100%;
        border-radius: 0;
    }
}

td.inner-text-block .text_block {
    label {
        position: relative;
        height: 100%;
        margin: 0;
        width: 100%;
        padding: 0;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;
    }

    &.price-block {
        label {
            span {
                position: absolute;
                left: rem(6);
                top: 50%;
                transform: translateY(-50%);
                font-size: 13px;
                border-bottom: 1px solid $dark;
                line-height: 1.2;
            }

            input {
                width: 100%;
                border: 1px solid #eee;
                border-radius: 0;
                text-align: right;
                font-size: rem(16px);
                padding: rem(6) rem(6) rem(6) rem(25);
                color: $dark;
                background-color: transparent;
                cursor: pointer;
                min-height: rem(42px);

                &:focus {
                    outline: none;
                    box-shadow: none;
                    border-color: #D3CECE;
                    background-color: $white;
                }
            }

            @media (min-width: 768px) {
                input {
                    font-size: rem(13px);
                }
            }

            @media (min-width:861px) {
                input {
                    height: rem(68px);
                }
            }
        }
    }

    &.comment-block {
        position: relative;

        .comment-text {
            // height: 100%;
            // min-height: rem(60);
            // max-height: rem(60);
            // border: 1px solid #f0f0f0;
            // border-radius: 0;
            // font-size: rem(13);
            // line-height: rem(17.7);
            // display: -webkit-flex;
            // display: flex;
            // align-items: center;
            // -webkit-align-items: center;
            // width: 100%;
            // padding: rem(6) rem(30) rem(6) rem(6);
            // color: $dark;

            &.focused {
                border-color: #D3CECE;
                background-color: $white;
            }

            .comment-text-inner {
                height: rem(78px);
                //overflow: hidden;
                display: -webkit-box;
                width: 100%;
                padding: rem(6) rem(30) rem(6) rem(6);
                border: 1px solid #f0f0f0;
                font-size: rem(16px);
                line-height: 1.4;
                color: $dark;
                resize: none;
                user-select: text;
                -webkit-user-select: text;
                background-color: transparent;
                cursor: pointer;

                &:focus {
                    outline: none;
                    box-shadow: none;
                }

                &.safari_block {
                    display: none;
                }

                &.desktop_block.iosText {
                    display: none;
                }

                &.safari_block.iosText {
                    display: block;
                }
            }

            @media (min-width: 768px) {
                .comment-text-inner {
                    height: rem(68px);
                    font-size: rem(13px);
                }
            }

            .comment-text__label {
                display: none;
                margin-bottom: rem(20px);
                text-align: center;
                font-size: rem(16px);
                color: #000;
                font-weight: 600;
            }

            @media (max-width: 860px) {
                .comment-text__label {
                    display: block;
                }

                .comment-text-inner::-webkit-input-placeholder {
                    opacity: 0;
                }

                .comment-text-inner:-ms-input-placeholder {
                    opacity: 0;
                }

                .comment-text-inner::placeholder {
                    opacity: 0;
                }
            }
        }

        .delete-comment {
            display: none;
            position: absolute;
            height: 100%;
            right: 0;
            top: 0;
            @include prefix((align-items: center,
                    justify-content: center), webkit);
            cursor: pointer;
            padding: rem(15) rem(15) rem(15) rem(6);

            span {
                font-size: rem(10);
                color: $dark_grey;
            }
        }

        @media (max-width: 860px) {
            .delete-comment {
                padding-top: rem(52px);
            }
        }
    }

    &.have_comment {
        .delete-comment {
            @include prefix_val_inn((display: flex,
                ), webkit);
        }
    }

    &.text-block {
        label {

            input {
                width: 100%;
                border: 1px solid #eee;
                border-radius: 0;
                font-size: rem(16px);
                padding: rem(6);
                color: $dark;
                background-color: transparent;
                cursor: pointer;
                min-height: rem(42px);

                &:focus {
                    outline: none;
                    box-shadow: none;
                    border-color: #D3CECE;
                    background-color: $white;
                }
            }

            @media (min-width: 768px) {
                input {
                    font-size: rem(13px);
                }
            }
        }
    }
}

.inner-text-block__btn {
    position: relative;
    width: 100%;
    padding: rem(6px);
    min-height: rem(42px);
    background: transparent;
    border: 1px solid #f0f0f0;
    font-size: rem(16px);
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .feather {
        width: rem(16px);
        height: rem(16px);
        flex-shrink: 0;
        margin-left: rem(8px);
        color: #4154F1;
    }

    span:first-child {
        border-bottom: 1px solid #555555;
        font-size: rem(13px);
        margin-right: 8px;
    }

    input {
        border: 0;
        text-align: right;
        background-color: transparent;
        padding: 0;

        &:focus {
            outline: none;
            border-color: transparent;
            box-shadow: none;
        }
    }

    &[disabled],
    &.disabled {
        background: $border_color;
        pointer-events: none;

        input {
            text-align: center;
            width: 100%;
        }

        span:first-child {
            position: absolute;
            top: 50%;
            left: rem(6px);
            transform: translateY(-50%);
        }

    }
}

@media (min-width: 768px) {
    .inner-text-block__btn {
        font-size: rem(13px);
    }
}

@media (min-width: 861px) {
    .inner-text-block__btn {
        min-height: rem(68px);
    }
}

.expenses-wrapper .table-condensed.products-table,
.expenses-wrapper .table-condensed.dashboard-table,
.expenses-wrapper .table-condensed.expenses-table,
.expenses-wrapper .products-table,
.expenses-wrapper .table-condensed.warehouse-table {
    thead th.reorder_outer {
        position: relative;

        .reorder_button {
            border: 0 none;
            background: $white url('../img/reorder-arrow.svg') no-repeat center center / contain;
            width: rem(14);
            height: rem(14);
            padding: 1px;
            cursor: pointer;
            position: absolute;
            left: rem(10);
            bottom: rem(-6px);
            border-radius: 0 0 3px 3px;

            &:focus {
                outline: none;
            }

            &.active {
                transform: rotate(180deg);
            }
        }
    }
}

.product_td {
    width: 45%;
}

table.table {
    color: $dark;

    .product_wrapper {
        display: grid;
        grid-template-columns: rem(40px) auto;
        gap: rem(6px);
        width: 100%;

        .product_image {
            height: rem(40);
            width: rem(40);
            flex: 0 0 rem(40);

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;

                overflow: hidden;
                text-indent: -9999px;
            }
        }

        .product_content {
            // width: calc(100% - 40px - 10px);
            // flex: 0 0 calc(100% - 40px - 10px);
            // margin-left: rem(10);
            width: 100%;

            >div.name,
            .product_content__inner > .name {
                width: 100%;
                // overflow: hidden;
                // white-space: nowrap;
                // text-overflow: ellipsis;
                // display: block;
                // align-items: center;
                line-height: rem(17.7);

                span {
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }

            .popover_outer {
                display: inline-block;
            }

            button {
                border: 0 none;
                padding: 0;
                background-color: transparent;
                width: auto;
                line-height: 1;
                margin-right: rem(5);

                span {
                    color: rgba($blue, .7);
                }

                [class^="fm-"],
                [class*=" fm-"] {
                    font-size: 15px;
                }

                &:focus {
                    outline: none;
                    box-shadow: none;
                }

                &:hover,
                &:active {
                    span {
                        color: rgba($blue, 1);
                    }
                }

                &.popover_button {
                    margin-right: 0;
                    font-size: rem(13);
                }
            }
        }

        .product_content_grid {
            display: grid;
            grid-template-columns: rem(40px) auto;
            gap: rem(8px);
            align-items: center;
        }
    }

    .product_wrapper_d-block {
        display: block;
    }
}

.dashboard-table .copy_button:active::after,
.dashboard-table .copy_button:hover::after {
    display: none;
}

/* mouse, touch pad */
@media (hover: hover) and (pointer: fine) {
    .dashboard-table .copy_button:active::after,
    .dashboard-table .copy_button:hover::after {
        display: block;
    }
}

table.table.dashboard-table {
    .product_wrapper {
        align-items: center;
        -webkit-align-items: center;

        button,
        a {
            color: rgba($blue, .7);
            cursor: pointer;

            &:hover,
            &:active {
                text-decoration: none;
                color: rgba($blue, 1);
            }
        }
    }
}

.daterangepicker {
    z-index: 1500;
}

.daterangepicker::before {
    content: "";
    // position: absolute;
    // bottom: 100%;
    // left: rem(10px);
    // width: rem(12px);
    // height: rem(8px);
    // background: url('/img/arrow-top.svg') no-repeat center center / 100% 100%;
}

.buttons-row {
    padding: rem(10) rem(20);
    @include prefix_val_inn((display: flex,
        ), webkit);
    @include prefix((align-items: center,
            justify-content: space-between), webkit);
    position: sticky;
    bottom: 0;
    background-color: $white;
    z-index: 600;
    box-shadow: 0 -6px 12px rgba(0, 0, 0, .06);

    &.right_position {
        @include prefix((justify-content: flex-end), webkit);
    }
}

@media (max-width: 767px) {
    .buttons-row.buttons-row_hidden {
        display: none;
    }
}

.single-button-row {
    padding: rem(10) rem(20);
    @include prefix_val_inn((display: flex,
        ), webkit);
    @include prefix((align-items: center,
            justify-content: flex-end), webkit);
    background-color: $white;
    z-index: 4;
}

.fixed_bottom {
    position: sticky;
    bottom: 0;
}

.fixed_bottom_row {
    position: fixed;
    right: 0;
    width: calc(100% - 100px);
    bottom: 0;
}

/* mouse, touch pad */
@media (hover: hover) and (pointer: fine) {
    .modal-open .fixed_bottom_row {
        right: 17px;
    }
}

.table-condensed.expenses-table tr td.action-td {
    // padding: 0 !important;

    .inner-relative {
        display: -webkit-flex;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        position: relative;

        button {
            height: 100%;
            width: 100%;
        }
    }
}

.info-banner-outer {
    &.warning {
        .info-banner {
            border: 1px solid $warning;

            .icon__block {
                background-color: $warning;

                &:after {
                    border-left-color: $warning;
                }
            }
        }
    }

    &.attention {
        .info-banner {
            border: 1px solid #EE3131;

            .icon__block {
                background-color: #EE3131;

                &:after {
                    border-left-color: #EE3131;
                }
            }
        }
    }

    background-color: $white;
    padding: rem(14) rem(20);

    .info-banner {
        position: relative;
        height: auto;
        min-height: rem(75);
        border: 1px solid #17B787;
        display: grid;
        grid-template-columns: rem(44) 1fr;
        overflow: hidden;
        border-radius: 8px;
        width: 100%;

        .icon__block {
            height: 100%;
            width: 100%;
            position: relative;
            background-color: #17B787;

            span {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                color: $white;
                font-size: rem(22);
            }

            &:after {
                position: absolute;
                display: block;
                content: "";
                border-color: transparent;
                border-style: solid;
                top: 50%;
                right: -8px;
                transform: translateY(-50%);
                border-width: rem(15) 0 rem(15) rem(15);
                border-left-color: #17B787;
            }
        }

        .info-banner-text {
            padding: rem(11) rem(50) rem(11) rem(24);
            @include prefix_val_inn((display: flex,
                ), webkit);
            @include prefix((align-items: center,
                ), webkit);

            p {
                font-size: rem(12);
                line-height: rem(18.02);
                margin-bottom: 0;
            }
        }

        .hide_banner {
            position: absolute;
            top: rem(16);
            right: rem(16);
            @include prefix_val_inn((display: flex,
                ), webkit);
            @include prefix((align-items: center,
                    justify-content: center), webkit);
            height: rem(14);
            width: rem(14);
            font-size: rem(20);
            color: $dark_grey;
            cursor: pointer;
        }
    }
}

.welcome_title_section {
    width: 100%;
    text-align: center;
    padding: rem(30) rem(20);

    h1 {
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        font-size: rem(28);
        color: $title-color;
        line-height: rem(42.05);
        margin-bottom: rem(3);
    }

    p {
        font-size: rem(17);
        font-weight: 400;
        color: $dark_grey;
        margin-bottom: 0;
        line-height: rem(25.53);
    }
}

.table-last-section {
    background-color: $white;
    // min-height: rem(78);
    position: relative;
    @include prefix_val_inn((display: flex,
        ), webkit);
    @include prefix((flex-wrap: wrap,
            align-items: center,
            justify-content: center), webkit);
    border-radius: 0 0 rem(8) rem(8);
    // border: solid #f0f0f0;
    // border-width: 0 1px 1px 1px;
    padding: rem(20) 0;

    &:empty {
        display: none;
    }

    .download_button {
        position: absolute;
        left: rem(20);
    }
}

.upload-table-btn {

    .btn-default {
        position: relative;
        left: auto;
        right: auto;
        font-size: 13px;
        margin: 0;

        input {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            opacity: 0;
            max-width: 100%;
            min-width: 0;
        }
    }
}

.welcome_button_section {
    background-color: $white;
    min-height: rem(78);
    position: relative;
    @include prefix_val_inn((display: flex,
        ), webkit);
    @include prefix((flex-wrap: wrap,
            align-items: center,
            justify-content: center), webkit);
    border-radius: 0;
    padding: rem(16) 0 0;

    .btn-regular {
        height: rem(50);
        border-radius: rem(8);
        font-size: rem(15);
        font-weight: 500;
    }
}

.page_nav .navigation {
    display: none;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    @media (min-width: 1200px) {
        display: flex;
    }
}

.page_nav .navigation li {
    width: rem(40);
    text-align: center;
}

.page_nav .navigation li a,
.page_nav .navigation li span {
    position: relative;
    display: inline-block;
    font-size: 15px;
    line-height: 1;
    color: $dark_grey;
    transition: all .3s ease-in-out 0s;
}

.page_nav .navigation li a:hover {
    text-decoration: none;
    color: $blue;
}

.page_nav .navigation li span.current {
    font-weight: 700;
    color: $blue;
}

.page_nav .navigation li {

    a.prev,
    a.next {
        width: rem(42);
        height: rem(42);
        @include prefix_val_inn((display: flex,
            ), webkit);
        @include prefix((flex-wrap: nowrap,
                align-items: center,
                justify-content: center), webkit);
        border: 1px solid #f0f0f0;
        border-radius: 50%;

        span {
            font-size: rem(18);
        }

        &:hover span {
            color: $blue;
        }
    }
}

.welcome_intro {
    width: 100%;
    padding: rem(50) rem(20) rem(30);
    background-color: $white;

    .intro_inner {
        width: 100%;
        max-width: rem(600);
        margin: 0 auto;

        h2 {
            font-size: rem(20);
            color: $title-color;
            text-align: center;
            line-height: rem(26.44);
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;

            span {
                display: block;
            }
        }
    }
}

.intro_inner {
    .intro_steps {
        list-style-type: none;
        margin: 0;
        padding: rem(20) 0 0 0;

        li {
            @include prefix_val_inn((display: flex,
                ), webkit);
            @include prefix((align-items: center), webkit);

            &:not(:last-of-type) {
                border-bottom: 1px solid #EAEAEA;
                margin-bottom: rem(20);
                padding-bottom: rem(20);
            }

            .step-icon {
                height: auto;
                width: rem(35);
                flex: 0 0 rem(35);

                .count_icon {
                    position: relative;
                    height: rem(35);
                    width: rem(35);
                    @include prefix_val_inn((display: flex,
                        ), webkit);
                    @include prefix((align-items: center,
                            justify-content: center), webkit);
                    border-radius: 50%;
                    background-color: $blue;
                    color: $white;
                    font-size: rem(18);
                    font-family: 'Open Sans', sans-serif;
                    font-weight: 600;
                }

                img {
                    width: 100%;
                }
            }

            .step_text {
                flex: 1;
                max-width: calc(100% - 170px);
                font-size: rem(16);
                line-height: rem(21.15);
                color: $dark;
                padding: rem(13);

                &.large-text {
                    max-width: 100%
                }
            }
        }
    }
}

.video_block {
    position: relative;
    border-radius: rem(8);
    width: rem(64px);
    height: rem(64px);
    margin-right: rem(12px);
    flex-shrink: 0;

    .play_link {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;

        .play_outer {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            height: rem(22);
            width: rem(22);
            border-radius: 50%;
            background-color: $blue;

            &:after {
                content: '\ea1f';
                font-family: sellerboard, sans-serif;
                position: absolute;
                left: 54%;
                top: 50%;
                transform: translate(-50%, -50%);
                color: $white;
                font-size: rem(8);
                line-height: 1;
            }
        }
    }
}

.intro_steps {
    .video_block {
        width: rem(135px);
        height: rem(75px);
        margin: 0 auto;
    }
}

@media (min-width: 420px) {
    .intro_steps {
        .video_block {
            margin-right: rem(16px);
        }
    }
}

@media (min-width: 576px) {
    .top_hidden_text {
        line-height: 1.5;
    }

    .video_block {
        width: rem(135px);
        height: rem(75px);
        margin-right: rem(16px);
    }
}

.data_loader_block {
    position: fixed;
    // min-height: rem(114);
    right: rem(20);
    bottom: rem(24);
    background-color: $white;
    padding: rem(12) rem(40);
    box-shadow: 0 8px 18px -6px rgba(24, 39, 75, 0.12), 0 12px 42px -4px rgba(24, 39, 75, 0.12);
    border-radius: rem(8);
    width: rem(275);
    z-index: 600;

    p {
        font-size: rem(12);
        line-height: rem(17.54);
        margin: 0;
    }

    .close-loader {
        position: absolute;
        right: rem(12);
        top: rem(11);
        font-size: rem(18);
        color: $dark_grey;
        cursor: pointer;
    }

    &.ontop-loader {
        position: absolute;
        top: rem(20);
        right: rem(20);
        bottom: auto;
    }

    &.data_loader_block_offset {
        bottom: rem(80px);
    }
}

.page_connect_head__api-key {
    padding-bottom: rem(30px);
}

@media (min-width: 768px) {
    .page_connect_head__api-key {
        padding-bottom: 0;
    }
}

.key_section {
    .key_section_head {
        padding-bottom: rem(12);
        border-bottom: 1px solid #EAEAEA;

        .key_section_title {
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            font-size: rem(24);
            line-height: rem(32.68);
            color: $title-color;
        }

        .key_section_description {
            padding-top: rem(12);
            max-width: rem(566);
        }
    }

    article p {
        font-size: rem(16);
        line-height: rem(24.51);
        margin-bottom: rem(8);
    }

    .response_block {
        padding: rem(30) 0;
        @include prefix_val_inn((display: flex,
            ), webkit);
        @include prefix((align-items: center,
            ), webkit);
        border-bottom: 1px solid #EAEAEA;

        &:first-child {
            border-top: 1px solid #EAEAEA;
        }

        .response_block_inner {
            padding-right: rem(40);
            flex: 1;
        }

        .response_title {
            font-size: rem(18);
            font-weight: 600;
            color: $dark;
            position: relative;
            padding-left: rem(50);
        }

        .response_title > span,
        .response_content > span {
            position: absolute;
            font-size: rem(18);
            font-weight: 600;
            color: $dark;
            background-color: rgba(65, 84, 241, .1);
            height: rem(35);
            width: rem(35);
            @include prefix_val_inn((display: flex,
                ), webkit);
            @include prefix((align-items: center,
                    justify-content: center), webkit);
            border-radius: 50%;
            left: 0;
            top: -7px;
        }

        .response_content {
            position: relative;
            padding-left: rem(50);

            p:last-of-type {
                margin-bottom: 0;
            }
        }

        .response_thumb {
            width: 48.133%;
            max-width: 48.133%;
            flex: 0 0 48.133%;

            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
                object-position: center;
                border-radius: rem(10px);
                // border: 1px solid $border_color;
                box-shadow: 0 0 0 1px $border_color;
            }
        }

        ul {
            list-style: disc;
            padding-left: rem(20px);
            font-size: rem(16px);
            line-height: 1.5;

            li {
                margin-bottom: rem(8px);

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        p {
            font-size: rem(16);
            line-height: 1.5;
            margin-bottom: rem(8);

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .final_block {
        padding: rem(100) 0 0;
        display: grid;
        grid-column-gap: rem(40);
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;

        .final_block_inner {
            padding-right: rem(40);
        }

        .final_title {
            font-size: rem(24);
            font-weight: 600;
            color: $title-color;
            position: relative;
        }

        .final_content {
            padding: rem(15) rem(40) rem(20) 0;

            p:last-of-type {
                margin-bottom: 0;
            }
        }



        .final_thumb {
            width: 100%;
            max-width: 100%;
            grid-column: 2/3;
            grid-row: 1/3;

            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
                object-position: center;
            }
        }
    }
}

.connection-header-desc {
    max-width: 800px;
    margin: rem(20px) auto 0;
    text-align: left;
    font-size: rem(16px);
    line-height: 1.5;
}

.connect-files-upload {
    margin: 0 auto;
    max-width: rem(420px);
}

.connect-files-upload_expenses {
    padding: rem(50px) 0;
}

.final-form {
    margin-top: rem(24px);

    .form-group {
        margin-bottom: rem(16px);
    }

    .form-control {
        height: rem(60);
        background: #FFFFFF;
        border: 1px solid #C4C4C4;
        border-radius: rem(8);
        font-size: rem(16px);

        &.form_error {
            border-color: $danger;
        }
    }

    .check_parent {
        margin-bottom: rem(12);

        .input_block {
            margin-bottom: rem(8);
        }
    }

    .key-form-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: rem(60);
        padding: rem(10) rem(50);
        border: 0 none;
        background-color: $blue;
        color: $white;
        font-size: rem(16px);
        border-radius: rem(8);
        box-shadow: 0 4px 14px rgba(0, 0, 0, 0.06);
        width: 100%;

        &:hover,
        &:focus {
            outline: none;
            background-color: $blue-hover;
        }

        .ring_loader {
            display: none;
            position: absolute;
            right: rem(20);
        }

        &.loading .ring_loader {
            display: inline-block;
        }

        .spinner-border {
            width: 1em;
            height: 1em;
            border-width: 2px;
        }

        .btn-loader {
            background-color: $blue;
            border-radius: rem(8);
        }
    }
}

.final-form__footer {
    margin: rem(25px) auto;
}

@media (min-width: 768px) {
    .final-form {
        max-width: rem(790px);
        margin: 0 auto rem(30px);

        form {
            display: flex;
        }

        .form-group {
            margin-bottom: 0;
            flex: 1;
            margin-right: rem(20px);
        }

        .key-form-button {
            width: auto;
            flex-shrink: 0;
        }
    }

    .final-form__footer {
        margin: 0 auto;
        padding: rem(50px) 0;
    }
}

.key_section__footer {
    text-align: center;
    margin: rem(50px) 0;
}

.key_section__button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: rem(60);
    padding: rem(10) rem(50);
    border: 0 none;
    background-color: $blue;
    color: $white;
    font-size: rem(15);
    border-radius: rem(8);
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.06);
    text-decoration: none;

    &:hover,
    &:focus {
        outline: none;
        background-color: $blue-hover;
        color: #fff;
        text-decoration: none;
    }

    svg {
        flex-shrink: 0;
        width: rem(16px);
        height: rem(16px);
        margin-right: rem(8px);
    }
}

//spinner
.load-ring {
    display: inline-block;
    width: 24px;
    height: 24px;
    position: absolute;
    left: rem(7);
    top: rem(12);
}

.load-ring:after {
    content: " ";
    display: block;
    width: 24px;
    height: 24px;
    margin: 0;
    border-radius: 50%;
    background: url("../img/loader1.svg") no-repeat center center / contain;
    animation: load-ring 1.2s linear infinite;
}

.data_loader_block__loading-item {
    position: relative;
    padding-left: rem(24px);

    i {
        display: none;
        margin-left: rem(4px);
        vertical-align: middle;

        &.finished-half {
            display: inline-block;
        }
    }

    .load-ring {
        width: rem(19px);
        height: rem(19px);
        top: 0;
        left: 0;
        text-align:center;

        &::after {
            width: rem(19px);
            height: rem(19px);
        }

        .feather-check {
            display: none;
            width: rem(19px);
            height: rem(19px);
            color: $success;
        }
        .feather-circle {
            display: none;
            width: rem(19px);
            height: rem(19px);
            color: $warning;
        }

        &.finished {
            &::after {
                display: none;
            }

            .feather-check {
                display: block;
            }
        }
        &.finished-half {
            &::after {
                display: none;
            }

            .feather-circle {
                display: inline-block;
            }
        }
    }
}

.connection-ico {
    color: #4451B1;
    width: 24px;
    height: 24px;
    position: absolute;
    left: rem(7);
    top: rem(12);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {

        width: rem(16);
        height: rem(16);
    }
}

.data_loader_block__actions {
    margin-top: rem(8px);
}

.ring_loader {
    display: inline-block;
    width: rem(16);
    height: rem(16);
}

.ring_loader:after {
    content: " ";
    display: block;
    width: rem(16);
    height: rem(16);
    margin: 0;
    border-radius: 50%;
    border: 2px solid $white;
    border-top-color: transparent;
    animation: load-ring 1.2s linear infinite;
}

@keyframes load-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes load-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.expenses-wrapper .table-condensed.dashboard-table tbody tr td {
    table.dashboard-table.footable-details {
        max-width: rem(500);
    }

    table.footable-details>tbody>tr>th {
        border-top: 0;
    }

    table.footable-details>tbody>tr>th,
    table.footable-details>tbody>tr>td {
        vertical-align: middle;
        border-right: 0;
        border-bottom-right-radius: 0;
        height: 30px;
    }

    table.footable-details>tbody>tr>td {
        text-align: right;
    }

    table.footable-details>tbody>tr:last-of-type th,
    table.footable-details>tbody>tr:last-of-type td {
        border-bottom: 0;
    }
}

@media screen and (max-height: 800px) {
    .mfp-iframe-holder .mfp-content {
        max-width: 900px;
    }
}

@media screen and (max-width: 1439px) {
    .expenses-wrapper .table-condensed.dashboard-table tbody tr td.footable-first-visible {
        position: relative;
        padding-left: 0;
    }

    .dashboard-table .footable-detail-row {
        display: none;
    }
}

@media screen and (max-width: 1400px) {
    .search_outer {
        max-width: 40%;
    }
}

@media screen and (max-width: 1200px) {
    .dashboard_panel .top_filter_row {
        margin-left: rem(-10);
        margin-right: rem(-10);

        >div {
            padding: 0 rem(10);
        }
    }
}

@media screen and (max-width: 1180px) {
    .day_carousel-outer {
        margin-left: rem(-10);
        margin-right: rem(-10);
    }

    .days_period_row {
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 0;

        .day_item_outer {
            width: 100%;
            flex: 0 0 100%;
            max-width: 100%;
            padding: 0 rem(10);
        }
    }

    .day_item_outer .day_block_item.active {
        border-top: 1px solid #F0F0F0;
    }

    .container_inner {
        grid-gap: rem(15);

        .inner_item .inner_text {
            font-size: rem(18);
        }

        .inner_item .inner_footer {
            font-size: rem(14);
        }
    }
}

@media screen and (max-width: 1024px) {
    .toggle_filter {
        @include prefix_val_inn((display: flex,
            ), webkit);
    }

    .dashboard_panel .top_filter_row {
        display: none;
        padding-top: rem(20px);
        @include prefix((flex-direction: column,
                align-items: stretch), webkit);

        &.active {
            @include prefix_val_inn((display: flex,
                ), webkit);
        }

        .select_outer {
            min-height: rem(43);
        }

        .search_outer {
            max-width: 100%;
            margin-bottom: 8px;

            >label {
                left: rem(20);
            }
        }

        .search_outer .select2-selection__rendered .select2-search input.select2-search__field {
            padding-left: rem(35);
        }

        .compare_outer {
            min-height: rem(43);
            @include prefix_val_inn((display: flex,
                ), webkit);
            @include prefix((align-items: center,
                ), webkit);
            border-bottom: 1px solid #e3e3e3;
        }

        .filter_button {
            margin-bottom: 8px;
            @include prefix_val_inn((display: flex,
                ), webkit);
            @include prefix((align-items: center,
                    justify-content: center), webkit);
            width: 100%;
        }
    }

    //.dropDown-parent .dropdown--menu {
    //  right: 0;
    //}
    .select2-dropdown,
    .select2-container--open .select2-dropdown--below {
        min-width: 100% !important;
    }
}

@media screen and (max-width: 991px) {
    .key_section .response_block {
        display: block;

        .response_block_inner {
            padding-right: 0;
        }

        .response_thumb {
            margin-top: rem(30);
            width: 100%;
            max-width: 100%;
            text-align: center;

            img {
                height: auto;
                width: auto;
                max-width: 100%;
            }
        }
    }

    .key_section {
        .final_block {
            display: block;

            .final_block_inner {
                padding-right: 0;
            }

            .final_thumb {
                margin-top: rem(30);
                margin-bottom: rem(30);
                width: 100%;
                max-width: 100%;
                text-align: center;

                img {
                    height: auto;
                    width: auto;
                    max-width: 100%;
                }
            }

            .final-form .form-control {
                width: 100%;
                max-width: rem(420);
            }

        }
    }

    .table-last-section {
        flex-direction: column;
        -webkit-flex-direction: column;

        .download_button {
            position: relative;
            left: auto;
            right: auto;
            margin-bottom: rem(20);
        }
    }
}

@media screen and (max-width: 840px) and (min-width: 769px) {

    table.expenses-table thead th,
    table.expenses-table tbody td {
        font-size: 11px;
    }
}

// @media screen and (max-width: 860px) {
//     .expenses-wrapper {
//         background-color: transparent;

//         .table-condensed.products-table tbody tr td:first-of-type {
//             padding-left: rem(15);
//         }

//         table.products-table {
//             display: block;
//             min-width: rem(200);

//             thead {
//                 display: none;
//             }

//             tbody,
//             tr,
//             td {
//                 display: block;
//                 width: 100%;
//             }

//             tbody td {
//                 height: auto;
//                 min-height: rem(60);
//                 padding: rem(12) rem(15);
//                 @include prefix_val_inn((display: flex,
//                     ), webkit);
//                 @include prefix(( //flex-direction: column,
//                         align-items: center,
//                         justify-content: space-between), webkit);

//                 &.inner-text-block .text_block {
//                     width: 100%;
//                 }

//                 &:before {
//                     content: attr(data-title);
//                     position: relative;
//                     line-height: 1.2;
//                     padding-right: 0;
//                     text-align: left;
//                     font-family: 'Open Sans', sans-serif;
//                     font-weight: 600;
//                     font-size: rem(16);
//                     color: $black;
//                     width: rem(160);
//                 }

//                 &:last-of-type {
//                     border-bottom: 0 none;
//                 }
//             }

//             .product_wrapper .product_content>div {
//                 overflow: visible;
//                 white-space: normal;
//             }
//         }

//         table.products-table tr {
//             background-color: $white;
//             border: 1px solid #f0f0f0;
//             border-radius: rem(8);
//             margin-bottom: rem(20);

//             &:hover {
//                 background-color: $white;
//             }
//         }

//         table.products-table tr:last-child td:first-child {
//             border-bottom-left-radius: 0;
//         }

//         table.products-table tr td:first-child {
//             border-left: 0 none;
//         }

//         table.products-table tr td {
//             border-right: 0 none;
//         }

//         .table-condensed.products-table>tbody>tr>td:last-of-type {
//             border-bottom-right-radius: 0;
//             border-bottom: 0 none;
//             justify-content: center;

//             &:before {
//                 content: none;
//             }
//         }

//         &.dashboard-wrapper {
//             background-color: $white;
//         }
//     }
// }

@media screen and (max-width: 768px) {
    .text_video_block {
        flex-direction: column;
        -webkit-flex-direction: column;
        margin-bottom: rem(30);

        .text_block {
            padding-right: 0;
            padding-bottom: rem(20);
        }

        .video_block_videoButton {
            width: 100%;
            max-width: 200px;
        }
    }

    aside.aside {
        transform: translate(-101%, 0);
        transition: transform .2s ease-in-out 0s, box-shadow .2s ease-in-out 0s;

        &.active {
            transform: translate(0, 0);
            box-shadow: 4px 0 16px 2px rgba(0, 0, 0, 0.03);
        }
    }

    footer {
        margin-left: 0;
    }

    .dash__board__container {
        margin-left: 0;
        transform: translate(0, 0);
        transition: transform .2s ease-in-out 0s;
        width: 100%;

        &.active {
            transform: translate(100px, 0);
        }
    }

    .auth_row {
        left: 0;
        transform: none;

        .auth_row_inner {
            .tf_mobile__switcher {
                display: inline-flex;
            }
        }
    }

    .container_inner {
        grid-template-columns: 1fr;
        grid-gap: rem(20);
    }

    .fixed_bottom_row {
        width: 100%;

        &.right_position {
            justify-content: center;
            -webkit-justify-content: center;
        }
    }
}

@media screen and (max-width: 760px) {

    table.settings_table {
    // table.expenses-table {
        display: block;
        min-width: rem(200);
        margin-bottom: 0;

        thead {
            display: none;
        }

        tbody,
        tr,
        td {
            display: block;
            width: 100%;
        }

        tbody td {
            padding: rem(12) 0;
            @include prefix_val_inn((display: flex,
                ), webkit);
            @include prefix((align-items: center,
                    justify-content: space-between), webkit);

            &:before {
                content: attr(data-title) " ";
                position: relative;
                width: auto;
                line-height: 1.2;
                padding-right: 10px;
                text-align: left;
            }
        }
    }

    // table.expenses-table tr {
    //     border: 1px solid #ddd;
    //     border-radius: rem(8);
    //     margin-bottom: rem(20);
    // }

    // table.expenses-table tr:last-child {
    //     margin-bottom: 0;
    // }

    // .expenses-wrapper .expenses-table tbody>tr.total_table_row {
    //     >td:first-of-type {
    //         justify-content: center;
    //         text-align: center;
    //         padding-right: rem(20);
    //     }
    // }

    .popover_block_item {
        left: auto;
        right: 0;
        z-index: 3;
    }

    table.expenses-table tr:last-child td:first-child {
        border-bottom-left-radius: 0;
    }

    table.expenses-table tr td:first-child {
        border-left: 0 none;
    }

    table.expenses-table tr td {
        border-right: 0 none;
    }

    .expenses-wrapper .table-condensed.expenses-table>tbody>tr:hover {
        background: none;
    }

    // .expenses-wrapper .table-condensed.expenses-table>tbody>tr>td:last-of-type {
    //     border-bottom-right-radius: 0;
    //     border-bottom: 0 none;
    //     justify-content: center;

    //     &:before {
    //         content: none;
    //     }
    // }

    .inner-relative {
        .dropdown-menu {
            left: 50% !important;
            transform: translate3d(-50%, 33px, 0px) !important;
        }
    }

    table.dashboard-table.footable-details .more_popover_outer {
        justify-content: flex-end;
        -webkit-justify-content: flex-end;
    }
}

@media screen and (max-width: 670px) {
    .dropDown-parent .dropdown--menu {
        width: 100%;
        max-width: 100%;

        ul li span {
            white-space: normal;
        }
    }
}

@media screen and (max-width: 600px) {
    .auth_row_inner_nav {
        li.desktop_only {
            display: none;
        }
    }

    .user_auth_dropdown li.mobile_only {
        display: block;
    }

    .modal-dialog.change-modal {
        .modal-header {
            padding: rem(15);
        }

        .modal-body {
            padding: rem(20) rem(15);
        }

        .modal-footer {
            padding-left: rem(15);
            padding-right: rem(15);
        }
    }
}

@media screen and (max-width: 520px) {
    .key_section .final_block {
        padding-top: rem(50);
    }

    .modal-dialog.feedback_modal {
        margin: 0;
        height: 100%;
        max-height: 100%;
        justify-content: flex-start;
        -webkit-justify-content: flex-start;

        .modal-content {
            border-radius: 0;
            min-height: 100%;
        }
    }

    .key_section .final_block .final-form {
        text-align: center;

        .check_parent {
            text-align: left;
            margin-bottom: rem(20);
        }
    }

    // .expenses-wrapper table.products-table tbody td {
    //     flex-direction: column;

    //     &:before {
    //         width: auto;
    //         margin-right: 0;
    //         margin-bottom: rem(20);
    //     }
    // }

    // .expenses-wrapper .expenses-table tbody>tr.total_table_row {
    //     >td {
    //         height: rem(35);
    //     }

    //     >td:first-of-type {
    //         padding-top: rem(10);
    //     }

    //     >td:last-of-type {
    //         padding-bottom: rem(10);
    //     }
    // }

    .popover,
    .popover.custom-popover {
        width: 96.5%;
        max-width: 96.5%;

        .arrow {
            display: none;
        }
    }
}

.page_nav {
    width: 100%;
}

@media screen and (max-width: 420px) {
    .user_auth_dropdown .user_account_status {
        display: none;
    }

    .modal-header {
        padding: rem(15);

        .modal-close {
            right: rem(15);
        }
    }

    .modal-body {
        padding: rem(26) rem(15) rem(18);
    }

    .expenses-table>tbody>tr>td .inner-relative .dropdown-menu>li>a {
        white-space: normal;
    }

    .intro_inner .intro_steps li {
        display: block;

        .step-icon {
            margin: 0 auto;
        }

        .step_text {
            text-align: center;
        }
    }

    .intro_inner .intro_steps li .step_text {
        font-size: rem(14);
        max-width: 100%;
    }

    .welcome_intro .intro_inner h2 span {
        display: inline;
    }

    .welcome_title_section p {
        font-size: rem(14);
        display: inline;
        line-height: 1.5;
    }

    .data_loader_block {
        height: auto;
        min-height: rem(40);
        left: 1.25rem;
        width: auto;
    }

    .select__outer .select2-container--default.modal_select .select2-selection--single {
        padding: rem(2) rem(37) rem(2) rem(2);
    }

    table.table.dashboard-table {
        .product_wrapper {
            align-items: center;
            -webkit-align-items: center;
        }
    }

    .page_nav {
        width: 100%;

        .navigation {
            // width: inherit;
            // justify-content: space-between;
            // -webkit-justify-content: space-between;

            li {
                width: auto;
                min-width: rem(30px);
                padding: rem(3);
            }
        }
    }

    .more_popover_outer {
        .more_popover_item {
            width: calc(100vw - 40px);
            right: -5px;
        }
    }
}

@media screen and (max-width: 380px) {
    table.table .product_wrapper {
        flex-direction: column;
        align-items: center;

        .product_content {
            margin-left: 0;
            width: 100%;
            flex: 0 0 auto;
        }
    }

    table.table .product_wrapper .product_content>div.name {
        white-space: normal;
        overflow: visible;
    }
}

@media screen and (max-width: 350px) {
    .buttons-row {
        flex-direction: column;
        -webkit-flex-direction: column;

        .btn-default {
            width: 100%;
        }

        >a~a {
            margin-top: rem(10);
        }
    }
}

@media (min-width: 761px) {
    .settings_table__name {
        width: 33.3333%;
    }
}


.modal-loaded .modal-body {
    padding: 0;
}

.modal-loaded .modal-content {
    padding: rem(30px) rem(40px);
    border-radius: rem(12px);
    border: 0;
}

.modal-loaded__title {
    margin-bottom: rem(25px);
    font-size: rem(17px);
    line-height: 1.5;
    text-align: center;
}

.modal-loaded .modal-footer-buttons {
    display: flex;
    justify-content: center;
}

.modal-loaded .modal-close {
    position: absolute;
    top: 0;
    right: 0;
    width: rem(48px);
    height: rem(48px);
    z-index: 1;
}

@media (min-width: 576px) {
    .modal-loaded .modal-dialog {
        max-width: 485px;
    }
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
    position: absolute!important;
    width: 1px!important;
    height: 1px!important;
    padding: 0!important;
    margin: -1px!important;
    overflow: hidden!important;
    clip: rect(0,0,0,0)!important;
    white-space: nowrap!important;
    border: 0!important;
}

.loading-wrapper__inner {
    padding: rem(48px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard-wrapper .loading-wrapper__inner {
    min-height: rem(322px);
}

.block_item_head__tooltip {
    position: absolute;
    top: 50%;
    right: rem(16px);
    margin-top: -19px;
    z-index: 3;
    font-size: rem(16px);
    width: rem(38px);
    height: rem(38px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.content-placeholder {
    width: 100%;
    max-width: 6em;
    border-radius: 4px;
    background-image: linear-gradient(
        45deg,rgba(255,255,255,.15) 25%,
        transparent 25%,transparent 50%,
        rgba(255,255,255,.15) 50%,
        rgba(255,255,255,.15) 75%,
        transparent 75%,
        transparent);

    animation: 1s linear infinite progress-bar-stripes;
    background-size: 1rem 1rem;
    background-color: $grey-200;

    display: inline-flex;
    align-items: center;
    justify-content: center;
}
@keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem;
    }
}

.day_block_item__period-btn {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    background: transparent;
    touch-action: manipulation;
}

.spinner-border.hidden {
    display: none !important;
}

.btn-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.btn-loader__inner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-default {
    .btn-loader {
        background: $white;
        border-radius: rem(4px);

        .spinner-border {
            width: 1em;
            height: 1em;
            border-width: 2px;
        }
    }
}

.btn-regular {
    .btn-loader {
        background: $blue;
    }
}

.btn-regular.disabled {
    .btn-loader {
        background: #eaeaea;
    }
}

.text-page {
    margin: rem(50px) 0 rem(25px);
}

.text-page__logo-header {
    position: relative;
    margin-bottom: rem(30px);
}

.text-page__logo {
    display: block;
    margin: 0 auto;
}

.text-page__back {
    font-size: rem(15px);
    color: #999;

    display: inline-flex;
    align-items: center;

    svg {
        width: rem(16px);
        height: rem(16px);
        flex-shrink: 0;
        margin-right: rem(5px);
    }
}

.text-page__inner {
    font-size: rem(16px);
    line-height: 1.5;
    color: #6A7C92;

    p {
        margin: 0 0 1em;

        &:last-child {
            margin-bottom: 0;
        }
    }

    h1 {
        font-size: rem(24px);
        line-height: 1.2;
        font-weight: 700;
        color: #051441;
        margin: rem(10px) 0 rem(30px);
    }

    h2 {
        font-size: rem(20px);
        line-height: 1.2;
        font-weight: 700;
        color: #051441;
        margin: rem(30px) 0 rem(10px);
    }

    h3 {
        font-size: rem(18px);
        line-height: 1.2;
        font-weight: 700;
        color: #051441;
        margin: rem(30px) 0 rem(10px);
    }

    ol,
    ul {
        margin: 0 0 1em;
        padding-left: rem(24px);

        li {
            margin-bottom: rem(10px);

            &:last-child {
                margin-bottom: 0;
            }
        }

        ::marker {
            color: #4154F1;
        }
    }

    ol {
        list-style: decimal;
    }

    ul {
        list-style: disc;
    }

}

.text-page__footer {
    margin-top: rem(25px);
    text-align: center;
    border-top: 1px solid #E8E8E8;
    padding-top: rem(25px);

    .final-form__footer {
        margin: 0 auto;
    }
}

.text-page__btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: rem(60);
    padding: rem(10) rem(50);
    border: 0 none;
    background-color: $blue;
    color: $white;
    font-size: rem(15);
    border-radius: rem(8);
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.06);
    text-decoration: none;

    &:hover,
    &:focus {
        outline: none;
        background-color: $blue-hover;
        color: #fff;
        text-decoration: none;
    }

    svg {
        flex-shrink: 0;
        width: rem(16px);
        height: rem(16px);
        margin-right: rem(8px);
    }
}

@media (min-width: 768px) {
    .text-page {
        margin: rem(50px) 0;
    }

    .text-page__footer {
        margin-top: rem(50px);
        padding-top: rem(50px);
    }

    .text-page__inner {
        h1 {
            font-size: rem(40px);
        }

        h2 {
            font-size: rem(30px);
        }

        h3 {
            font-size: rem(24px);
        }
    }

}

.page_connect_back {
    width: rem(48px);
    height: rem(48px);
    position: absolute;
    z-index: 1;
    bottom: 100%;
    right: 0;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.connecting-exit {
    margin: rem(30px) auto;
    padding-top: rem(30px);
    border-top: 1px solid rgba(0, 0, 0, .15);
    max-width: rem(240px);
    text-align: center;
}

.connecting-exit__link {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: $dark_grey;
    font-size: rem(18px);

    span {
        margin-left: rem(8px);
    }
}

.connecting-exit__link:hover {
    text-decoration: none;
    color: $black;
}

.dropList {
    .daterangepicker {
        position: static;
        border: 0;
        width: 100%;
    }

    .daterangepicker .drp-calendar.left,
    .daterangepicker .drp-calendar.right {
        padding: 0;
        width: 100%;
        margin: 0;
        max-width: none;
    }

    .daterangepicker .btn-default {
        min-width: rem(120px);
        margin: 8px;
    }

    .daterangepicker .drp-selected {
        display: block;
    }

    .daterangepicker .drp-buttons {
        border-top: 0;
    }
}

@media (min-width: 768px) {
    .dropList {
        .daterangepicker .drp-calendar.left,
        .daterangepicker .drp-calendar.right {
            width: auto;
            max-width: 270px;
        }

        .daterangepicker .btn-default {
            margin: 0 0 0 8px;
        }

        .daterangepicker .drp-selected {
            display: inline-block;
        }
    }
}








.product-summary {
    display: inline-flex;
    color: #4154f1;
    border-bottom: 1px dotted #4154f1;
    cursor: pointer;
}

.product-summary__header {
    margin: rem(5px) 0;
    display: flex;
    align-items: center;
}

.product-summary__img {
    flex-shrink: 0;
    margin-right: rem(8px);
}

.product-summary__header__inner {
    flex: 1;
    color: $dark;
    font-size: rem(13px);
    line-height: 1.46;
}

.product-summary__product-title {
    color: $title-color;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.product-summary__variation {
    margin: rem(10px) 0;
    color: $dark;
    font-size: rem(13px);
    line-height: 1.2;
}

.product-summary__variation:last-child {
    margin-bottom: 0;
}

.product-summary__variation__title {
    color: $title-color;
}



























.input {
    margin-bottom: rem(20px);

    .input__inner {
        position: relative;
    }

    .input__inner_info {
        padding-right: rem(42px);
    }

    .input__hidden {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0;
    }

    .input__radio-btn {
        margin: 0;
        font-weight: 400;
        color: #999;
        line-height: 1.2;
        text-align: center;
        padding: rem(8px) rem(20px);
        min-height: rem(40px);
        border-radius: rem(20px);
        border: 1px solid #F0F0F0;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    .input__hidden:checked + .input__radio-btn {
        background: #4154F1;
        color: #fff;
        border-color: #4154F1;
    }

    &.input_price {

        .input__text-input {
            padding: rem(8px) rem(40px) rem(8px) rem(20px);
            border-radius: rem(8px);
            border: 1px solid #F0F0F0;
            background-color: #fff;
            min-height: rem(42px);
            line-height: 1.2;
            color: #555;
            display: block;
            width: 100%;
            font-size: rem(13px);


        }
        .input__text-input.error {
            border-color: #f47171;
        }

        .input__currency {
            position: absolute;
            z-index: 1;
            right: rem(20px);
            top: 0;
            min-height: rem(42px);
            display: inline-flex;
            align-items: center;
            user-select: none;
            pointer-events: none;
        }
    }

    .input__inner_info_inline {
        padding-right: 0;

        .input__info {
            position: relative;
            top: -1px;
            width: 1em;
            height: 1em;
            transform: none;
            display: inline-flex;
        }

    }

}

.input__text-input {
    border: 1px solid #F0F0F0;
    background-color: #fff;
    font-size: rem(13px);
    padding: rem(8px) rem(20px);
    border-radius: rem(8px);
    width: 100%;
    color: #555;
    line-height: 1.2;
    display: block;
    min-height: rem(42px);

    &:disabled {
        background: #F0F0F0;
        border-color: #DEDEDE;
    }
}

.input__text-input[disabled] {
    background: #F0F0F0;
    border-color: #DEDEDE;
}

.input__ico {
    position: absolute;
    z-index: 1;
    top: 0;
    min-height: rem(42px);
    font-size: rem(24px);
    line-height: 1;
    margin: 0;
    color: #999;
    width: rem(42px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.input_date {
    .input__ico {
        right: 0;
    }

    .input__text-input {
        padding-right: rem(48px);
    }
    .input__text-input.error {
        border-color: #f47171;
    }
}

.input__info {
    font-size: rem(16px);
    vertical-align: middle;
    color: $dark_grey;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: rem(42px);
    height: rem(42px);
    display: flex;
    align-items: center;
    justify-content: center;
}


@media (min-width: 768px) {
    .input {
        &.input_price {
            .input__text-input {
                font-size: rem(13px);
            }
        }
    }

    .input__text-input {
        font-size: rem(13px);
    }
}

.options-group {
    margin: rem(-8px) 0 rem(12px);
    display: flex;
    align-items: flex-start;

    .input {
        margin: rem(8px) rem(8px) rem(8px) 0;
    }
}

/* mouse, touch pad */
@media (hover: hover) and (pointer: fine) {
    .input {
        .input__radio-btn:hover {
            background: #F0F0F0;
            color: #555;
        }

        .input__hidden:checked + .input__radio-btn:hover {
            background: #4154F1;
            color: #fff;
        }
    }
}






.costprice-modal {
    .modal-dialog {
        max-width: rem(600px);
    }

    .modal-content {
        border: 0 none;
        border-radius: rem(12);
    }

    .modal-header {
        height: rem(68);
        position: relative;
        background: linear-gradient(92.5deg, #425AF0 0.41%, #51B1FA 100%);
        padding: rem(15) rem(26);
        @include prefix((align-items: center,
            ), webkit);

        h5 {
            font-size: rem(15);
            color: $white;
            font-family: 'Open Sans', sans-serif;
            font-weight: 500;
            padding: 0 rem(20px) 0 0;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .modal-close {
            border: 0 none;
            background-color: transparent;
            height: auto;
            width: auto;
            position: absolute;
            right: rem(23);
            top: 50%;
            transform: translateY(-50%);
            padding: 0;

            &:focus,
            &:active {
                outline: none;
                box-shadow: none;
            }

            span {
                color: $white;
                font-size: rem(16);
            }
        }
    }

    .modal-body {
        padding: rem(16px);
    }

    .modal-title {
        color: #232323;
        font-size: rem(20);
        font-family: 'Open Sans', sans-serif;
        font-weight: 500;
        @include prefix_val_inn((display: flex,
            ), webkit);
        @include prefix((align-items: center,
                justify-content: space-between), webkit);
        padding-top: rem(13);
        padding-bottom: rem(18);
    }

    .drop-icon {
        position: relative;
        padding-right: rem(20);

        &:after {
            content: '\e930';
            font-family: 'sellerboard', sans-serif;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            line-height: .8;
            color: $dark;
            font-size: rem(15);
        }
    }

    .modal-body {
        .date-row {
            padding-bottom: rem(10);
            @include prefix_val_inn((display: flex,
                ), webkit);
            @include prefix((flex-wrap: wrap,
                    align-items: center,
                    justify-content: flex-start), webkit);

            > div:not(:last-of-type) {
                margin-right: rem(18);
            }

            > div {
                margin-bottom: rem(10);
            }
        }

        .icon_first_col {
            display: inline-grid;
            grid-auto-flow: column;
            grid-gap: 10px;
            justify-content: start;
            align-items: center;
        }

        .icon_select_col {
            display: inline-grid;
            grid-template-columns: auto 1fr;
            grid-gap: 10px;
            justify-content: start;
            align-items: center;
            width: 100%;
        }

        .square_icon {
            height: rem(16);
            width: rem(16);
            display: inline-block;
            border: 1px solid #999999;
            border-radius: 2px;
        }

        scrollbar-width: thin;
        scrollbar-color: #C8C8C8 #F2F3F3;
        &::-webkit-scrollbar {
            width: 7px;
            height: 5px;
        }
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 10px rgba(129, 140, 136, 0.2);
            box-shadow: inset 0 0 10px rgba(129, 140, 136, 0.2);
            border-radius: 4px;
        }
        &::-webkit-scrollbar-thumb {
            background: rgba(100,100,100,.3);
            border-radius: 4px;
            cursor: pointer;
            position: relative;
            opacity: .3;
            transition: opacity .3s ease-in-out;
        }
    }

    .modal-footer {
        padding-left: rem(26);
        padding-right: rem(26);
        background-color: $white;
        border-top: 0;
        box-shadow: 0 rem(-6px) rem(12px) rgba(0, 0, 0, .06);

        .modal-footer-buttons {
            width: 100%;
            display: grid;
            grid-template-columns: 100%;
            gap: rem(16px);
        }

        .btn-default {
            margin: 0;
        }
    }

    .daterangepicker {
        box-shadow: 0 8px 18px -6px rgba(24, 39, 75, .12), 0 12px 42px -4px rgba(24, 39, 75, .12);
    }

    .daterangepicker.single .drp-calendar.left.single {
        box-shadow: none;
        padding-bottom: 0;
    }

    .daterangepicker.show-calendar .drp-buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: rem(14px);
        border-top: 0;
        padding: 1.25rem;
    }

    .daterangepicker .drp-buttons .btn {
        margin-left: 0;
        min-width: auto;
    }


}

.costprice-modal_small {
    .modal-dialog {
        max-width: rem(450px);
    }

    .modal-body {
        padding: rem(16px) rem(26px);
    }
}

// .costprice-modal__form {
//     display: flex;
//     flex-direction: column;
//     max-height: calc(100vh - 1rem);
//     overflow: hidden;
// }

// @media (min-width: 576px) {
//     .costprice-modal__form {
//         max-height: calc(100vh - 3.5rem);
//     }
// }

@media (min-width: 768px) {
    .costprice-modal {
        .modal-footer {
            .modal-footer-buttons {
                display: flex;
                justify-content: flex-end;
                gap: 0;

                .btn-default:first-child {
                    margin-right: auto;
                    margin-left: 0;
                }
            }

            .btn-default {
                margin-left: rem(15px);
                min-width: auto;
            }
        }
    }
}


.costprice-modal__label {
    color: $title-color;
    font-size: rem(13px);
    line-height: 1.231;
    margin-bottom: rem(4px);
}

.costprice-modal__period-block {
    position: relative;
    background: $light_grey;
    padding: rem(16px);
    border-radius: rem(8px);

    display: grid;
    grid-template-columns: 100%;
    gap: rem(16px);

    .input {
        margin-bottom: 0;
    }

    .costprice-modal__initial-receipt {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: rem(16px);
        align-items: flex-end;
    }
}

.costprice-modal__block_by-period {
    display: grid;
    grid-template-columns: 100%;
    gap: rem(16px);
    padding: rem(16px) rem(20px);
    border-bottom: 1px solid $border_color;
}

.costprice-modal__period-block__close {
    position: absolute;
    z-index: 1;
    top: rem(16px);
    right: rem(16px);
    align-self: flex-start;
    width: rem(20px);
    height: rem(20px);
    padding: 0;
    border: 0;
    background: transparent;
    color: #999;
    font-size: rem(12px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.costprice-modal__block_by-period__checkbox,
.costprice-modal__block_all-time__checkbox {
    margin-bottom: 0;
}

.costprice-modal__add-date {
    .btn-default {
        width: 100%;
    }
}

.costprice-modal__add-date-footer {
    display: block;
}

@media (min-width: 992px) {
    .costprice-modal__period-block {
        grid-template-columns: 1fr 1fr 1fr rem(20px);
    }

    .costprice-modal__period-block__close {
        position: static;
    }

    .costprice-modal__period-block {
        .costprice-modal__initial-receipt {
            grid-column: span 4;
        }
    }

    .costprice-modal__add-date {
        display: none;
    }

    .costprice-modal__add-date-footer {
        display: block;
    }
}

// @media (min-width: 576px) {
//     .costprice-modal__block_all-time {
//         display: grid;
//         grid-template-columns: rem(157px) auto;
//         gap: rem(20px);
//         align-items: center;
//     }

//     .costprice-modal__block_all-time .input {
//         margin-bottom: 0;
//     }

//     .costprice-modal__block_all-time .input_price {
//         max-width: rem(157px);
//     }

//     .costprice-modal__block_all-time__checkbox {
//         padding-top: rem(27px);
//     }
// }

// @media (min-width: 768px) {
//     .costprice-modal__period-block {
//         grid-template-columns: 1fr 1fr 1fr rem(20px);
//     }

//     .costprice-modal__period-block__close {
//         position: static;
//     }

//     .costprice-modal__period-block {
//         .costprice-modal__initial-receipt {
//             grid-column: 1/3;
//         }
//     }
// }

table.table .product_wrapper_acc {
    position: relative;
    grid-template-columns: rem(40px) auto;
    padding-left: rem(20px);
}

.product_acc-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    padding: 0;
    background: transparent;

    &::before {
        content: "";
        width: 0;
        height: 0;
        border-color: $blue transparent transparent transparent;
        border-style: solid;
        border-width: rem(7px) rem(4px) 0 rem(4px);
        flex-shrink: 0;
        transition: transform .3s ease-in-out;
    }
}

table.products-table tr td.product_td_barcode {
    padding-left: rem(16px);
}


@media (min-width: 861px) {
    table.products-table tr td.product_td_barcode {
        padding-left: rem(37px);
    }
}

.product_period_status {
    padding: rem(6px);
    white-space: nowrap;
    min-width: rem(125px);
    text-align: center;
}

.product_period_status_none {
    padding: rem(6px);
    min-width: rem(125px);
    white-space: nowrap;
    background: #F0F0F0;
    text-align: center;
    border: 1px solid #f0f0f0;
    font-size: rem(16px);
    min-height: rem(42px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width: 861px) {
    .product_period_status_none {
        min-height: rem(68px);
    }

    .product_period_status {
        text-align: left;
    }
}

.checkbox-td {
    text-align: center;

    .input {
        margin: 0;
        display: inline-block;

        .input__checkbox-label {
            padding-left: rem(20px);

            &::before {
                top: 0;
            }
        }
    }
}

.product-more-btn {
    width: 100%;
    height: 100%;
    color: $blue;
    font-size: rem(16px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.products-table {
    border-collapse: separate;
    border-spacing: 0;

    th,
    td {
        display: none;

        &:last-child {
            display: table-cell;
            padding-right: rem(20px);
        }

        &:nth-child(1),
        &:nth-child(2) {
            display: table-cell;
        }
    }

    thead th {
        padding: rem(5px);
        border-top: 0;
        height: rem(64px);
        vertical-align: middle;
        border-bottom: 0;

        &:first-child {
            padding-left: rem(20px);
        }
    }

    tbody {
        background: $white;
    }

    td {
        padding: rem(5px);
        height: rem(52px);
    }

    tr:not(:last-child) td {
        border-bottom: 1px solid $border_color;
    }
}

@media (min-width: 768px) {
    .products-table {
        th,
        td {
            border-bottom: 1px solid $border_color;

            &:first-child {
                border-left: 1px solid $border_color;
            }
            &:last-child {
                border-right: 1px solid $border_color;
            }
        }

        thead th {
            border-top: 1px solid $border_color;
            background: $white;
            border-bottom: 1px solid $border_color;
            height: rem(50px);

            &:first-child {
                border-top-left-radius: rem(8px);
            }

            &:last-child {
                border-top-right-radius: rem(8px);
            }
        }
    }
}

@media (min-width: 992px) {
    .products-table {
        th,
        td {
            display: table-cell;
            border-right: 1px solid $border_color;
            border-bottom: 1px solid $border_color;

            &:first-child {
                border-left: 1px solid $border_color;
            }

            &:last-child {
                display: none;
            }
        }

        th {
            &:nth-child(5) {
                border-top-right-radius: rem(8px);
            }
        }
    }
}


.expenses-table {
    border-collapse: separate;
    border-spacing: 0;

    td,
    th {
        padding: rem(5px);
        height: rem(50px);
        display: none;
        border-bottom: 1px solid $border_color;

        &:first-child {
            padding-left: rem(20px);
        }

        &:last-child {
            padding-right: rem(20px);
        }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(6),
        &:last-child {
            display: table-cell;
        }
    }

    tr:last-child {
        td {
            border-bottom: 0;
        }
    }

    thead th {
        border: 0;
        vertical-align: middle;
    }

    .total_table_row_sm {
        display: none;
    }

    .total_table_row_desktop {
        display: none;
    }

    tbody {
        background: $white;
    }
}

@media (min-width: 768px) {
    .expenses-table {
        thead th {
            background: $white;
            border-top: 1px solid $border_color;
            border-bottom: 1px solid $border_color;
            border-right: 1px solid $border_color;

            &:first-child {
                border-top-left-radius: rem(8px);
                border-left: 1px solid $border_color;
            }

            &:last-child {
                border-top-right-radius: rem(8px);
            }
        }

        th,
        td {
            border-right: 1px solid $border_color;

            &:first-child {
                border-left: 1px solid $border_color;
                padding-left: rem(5px);
            }

            &:last-child {
                padding-right: rem(5px);
            }


            &:nth-child(4) {
                display: table-cell;
            }
        }

        .total_table_row_mob {
            display: none;
        }

        .total_table_row_sm {
            display: table-row;
        }

        .total_table_row:last-child {
            td {
                border-bottom: 1px solid $border_color;

                &:first-child {
                    border-bottom-left-radius: rem(8px);
                }

                &:last-child {
                    border-bottom-right-radius: rem(8px);
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .expenses-table {
        th,
        td {
            &:nth-child(5),
            &:nth-child(7) {
                display: table-cell;
            }
        }

        .total_table_row_sm {
            display: none;
        }

        .total_table_row_desktop {
            display: table-row;
        }
    }
}


.redeems-table {
    border-collapse: separate;
    border-spacing: 0;

    th,
    td {

        height: rem(50px);
        display: none;
        border: 0;
        border-bottom: 1px solid $border_color;
        padding: rem(5px);
        vertical-align: middle;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(5),
        &:nth-child(6),
        &:last-child {
            display: table-cell;
        }

        &:first-child {
            padding-left: rem(20px);
        }

        &:last-child {
            padding-right: rem(20px);
        }
    }

    thead th {
        vertical-align: middle;
        border-bottom: 0;
    }

    tbody {
        background: $white;

        tr:hover {
            background: $light_grey;
        }
    }

    .total_table_row {
        background: $grey-100;

        td {
            font-weight: 600;

            &:not(:last-child) {
                border-right: 0;
            }

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                display: table-cell;
            }
        }

    }

    .total_table_row_sm {
        display: none;
    }

    .total_table_row_md {
        display: none;
    }
}

@media (min-width: 768px) {
    .redeems-table {

        th,
        td {
            border-right: 1px solid $border_color;

            &:first-child {
                padding-left: rem(5px);
                border-left: 1px solid $border_color;
            }

            &:last-child {
                padding-right: rem(5px);
            }

            &:nth-child(3),
            &:nth-child(4) {
                display: table-cell;
            }
        }

        thead th {
            border-bottom: 1px solid $border_color;
            border-top: 1px solid $border_color;
            background: $white;

            &:first-child {
                border-top-left-radius: rem(8px);
            }

            &:last-child {
                border-top-right-radius: rem(8px);
            }
        }

        .total_table_row_xs {
            display: none;
        }

        .total_table_row_sm {
            display: table-row;
        }

        .total_table_row:last-child {
            td {
                border-bottom: 1px solid $border_color;

                &:first-child {
                    border-bottom-left-radius: rem(8px);
                }

                &:last-child {
                    border-bottom-right-radius: rem(8px);
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .redeems-table {
        th,
        td {
            &:nth-child(7),
            &:nth-child(8),
            &:nth-child(9) {
                display: table-cell;
            }
        }

        .total_table_row_sm {
            display: none;
        }

        .total_table_row_md {
            display: table-row;
        }
    }
}

.tree-barcodes {
    .product-summary__details {
        padding: rem(16px);
        border-radius: rem(10px);
        background: $grey-100;
    }

    .product-summary__header {
        margin: 0;
    }
}

.tree-barcodes__title {
    font-weight: 700;
    color: $title-color;
    margin: 0 0 rem(16px);
}

.tree-barcodes__container {
    position: relative;
    padding-left: rem(48px);

    &::after {
        content: "";
        display: block;
        width: 1px;
        height: 100%;
        background: #ddd;
        position: absolute;
        bottom: rem(7px);
        left: rem(20px);
    }

    .product-summary__variation {
        position: relative;

        &::after {
            content: "";
            display: block;
            width: rem(20px);
            height: 1px;
            background: #ddd;
            position: absolute;
            top: 50%;
            right: calc(100% + rem(8px));
        }
    }
}

.modal-confirm-cost .modal-footer .btn-default {
    padding: rem(5px) rem(10px);
}

.warning-text {
    margin-bottom: rem(20px);
}









.dashboard-table {
    th,
    td {
        display: none;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(7),
        &:last-child {
            display: table-cell;
        }
    }
}

@media (min-width: 576px) {
    .dashboard-table {
        th,
        td {
            &:nth-child(3) {
                display: table-cell;
            }
        }
    }
}

@media (min-width: 768px) {
    .dashboard-table {
        th,
        td {
            &:nth-child(4) {
                display: table-cell;
            }
        }
    }
}

@media (min-width: 992px) {
    .dashboard-table {
        th,
        td {
            &:nth-child(5),
            &:nth-child(6) {
                display: table-cell;
            }
        }
    }
}

@media (min-width: 1200px) {
    .dashboard-table {
        th,
        td {
            &:nth-child(8),
            &:nth-child(9) {
                display: table-cell;
            }
        }
    }
}

@media (min-width: 1400px) {
    .dashboard-table {
        th,
        td {
            &:nth-child(10),
            &:nth-child(11) {
                display: table-cell;
            }
        }
    }
}

.dashboard-table.byOrders,
.dashboard-table.noGroupping,
.dashboard-table.bySku {
    th,
    td {
        &:nth-child(7) {
            display: none;

            @media (min-width: 1200px) {
                display: table-cell;
            }
        }
    }

    @media (min-width: 1400px) {
        th,
        td {
            &:nth-child(12) {
                display: table-cell;
            }
        }
    }

    td:not(:first-child) {
        .item {
            padding-left: 0;
        }

    }

    .item__order__title {
        max-width: rem(80px);

        @media (min-width: 576px) {
            max-width: rem(120px);
        }
    }
}

.dashboard-table.byOrders {
    .item__order__title {
        max-width: rem(180px);
    }
}

.dashboard-table_orders {
    th,
    td {
        &:nth-child(11) {
            display: table-cell;
        }
    }
}

.warehouse-table {
    margin-bottom: 0;

    th,
    td {
        display: none;
        height: rem(50px);
        border: 0;
        border-bottom: 1px solid $border_color;
        padding: rem(5px);
        vertical-align: middle;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(6),
        &:last-child {
            display: table-cell;
        }
    }

    thead {
        background: $white;

        th {
            vertical-align: middle;
            border-bottom: 1px solid $border_color;

            &:first-child {
                padding-left: rem(20px);
            }

            &:last-child {
                padding-right: rem(20px);
            }
        }
    }

    tbody {
        background: $white;

        tr:hover {
            background: $light_grey;
        }
    }
}

@media (min-width: 576px) {
    .warehouse-table {
        th,
        td {
            border-right: 1px solid $border_color;

            &:first-child {
                padding-left: rem(5px);
                border-left: 1px solid $border_color;
            }

            &:last-child {
                padding-right: rem(5px);
            }

            &:nth-child(5) {
                display: table-cell;
            }
        }

        thead th {
            border-bottom: 1px solid $border_color;
            border-top: 1px solid $border_color;
            background: $white;

            &:first-child {
                border-top-left-radius: rem(8px);
            }

            &:last-child {
                border-top-right-radius: rem(8px);
            }
        }
    }
}

@media (min-width: 992px) {
    .warehouse-table {
        th,
        td {
            &:nth-child(3),
            &:nth-child(4) {
                display: table-cell;
            }
        }

        .item__data_mob {
            display: none;
        }

        .item__data_desktop {
            display: block;
        }
    }
}

@media (min-width: 1200px) {
    .warehouse-table {
        th,
        td {
            &:nth-child(7),
            &:nth-child(8) {
                display: table-cell;
            }
        }


    }
}

@media (min-width: 1400px) {
    .warehouse-table {
        th,
        td {
            &:nth-child(9),
            &:nth-child(10),
            &:nth-child(11),
            &:nth-child(12) {
                display: table-cell;
            }

            &:last-child {
                display: none;
            }
        }
    }
}

.panels-carousel-wrapper {
    margin: 0 -20px;
    overflow: hidden;

    @media (min-width: 1081px) {
        padding: 0 15px;
    }
}

.day_item_outer {
    height: 100%;
    padding: 0 rem(20px) rem(16px);

    @media (min-width: 1081px) {
        padding: 0 rem(5px) rem(16px);
    }
}

.bottom-action-panel {
    position: fixed;
    right: 0;
    bottom: 0;
    width: calc(100% - 100px);
    padding: 1rem 1.25rem ;
    border-top: 1px solid #F0F0F0;
    border-left: 1px solid #F0F0F0;
    background: #fff;
    z-index:10;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.table {
    .currency {
        white-space: nowrap;
    }
}

.btn-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: rem(4px);
    font-size: rem(13px);
    color: $blue;

    &:hover,
    &:focus {
        text-decoration: none;
        color: $blue;
    }

    /* mouse, touch pad */
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            color: $blue_hover;
        }
    }
}

.btn-link__ico {
    font-size: rem(16px);
}

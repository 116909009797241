.notice {
    padding: rem(16px) rem(20px);
    --_noticeBorderColor: #{$border_color};
    --_noticeBg: #{$light_grey};
    --_noticeColor: #{$dark};

    border: 1px solid var(--_noticeBorderColor);
    background-color: var(--_noticeBg);
    color: var(--_noticeColor);
    border-radius: rem(10px);

    &:where(._warning) {
        --_noticeBorderColor: #{$peach-200};
        --_noticeBg: #{$peach-100};
        --_noticeColor: #{$peach-800};
    }
}

.pricing-tabs {
    margin: -25px -20px 20px;
    border-bottom: 1px solid $border_color;
}

.pricing-tabs__inner {
    display: flex;
    gap: rem(16px);
    padding: 0 rem(20px);
    white-space: nowrap;

    overflow: auto;

    scroll-snap-type: x proximity;

    scroll-padding-left: 20px;
    scroll-padding-right: 20px;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    @media (min-width: 768px) {
        gap: rem(40px);
    }
}

.pricing-tabs__btn {
    position: relative;
    text-align: center;
    color: $dark_grey;
    padding: rem(16px) 0;
    background: transparent;
    border: 0;
    border-radius: 0;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: rem(4px);

    scroll-snap-align: start;

    &::after {
        content: "";
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: rem(3px);
        border-radius: rem(3px);
    }

    &.active {
        color: $blue;

        &::after {
            background: $blue;
        }
    }
}

.pricing-tabs__btn__tag {
    background: $blue;
    color: $white;
    font-size: rem(10px);
    font-weight: 600;
    line-height: 1.2;
    padding: rem(2px) rem(4px);
    border-radius: rem(4px);
    vertical-align: middle;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.pricing {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: rem(16px);
    margin: 0 -20px;

    @media (min-width: 768px) {
        grid-template-columns: rem(240px) 1fr;
    }

    @media (min-width: 992px) {
        margin: 0;
    }
}

.pricing__right {
    padding: 0 rem(20px) 0 0;
    display: grid;
    grid-template-columns: 85%;
    grid-auto-columns: 85%;
    grid-auto-flow: column;
    gap: rem(16px);
    overflow: auto;
    scroll-snap-type: x proximity;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    @media (min-width: 992px) {
        display: flex;
        gap: rem(16px);
        padding: 0;
    }
}

.pricing__pack {
    flex: 1;
    border-radius: rem(16px);
    border: 1px solid $border_color;
    text-align: center;
    padding: rem(20px) rem(16px);
    scroll-snap-align: start;

    &.current {
        background: $light_grey;
    }

    .pricing__row {
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.pricing__row {
    padding: rem(16px) 0;
    border-bottom: 1px solid $border_color;
    height: rem(50px);
    color: $title-color;
    line-height: rem(16px);

    &:last-child {
        border-bottom: 0;
    }
}


.pricing__left {
    padding: rem(142px) 0 rem(20px) rem(20px);

    .pricing__row {
        display: flex;
        align-items: flex-start;
        gap: rem(8px);
        font-size: rem(10px);

        @media (min-width: 768px) {
            font-size: rem(13px);
        }
    }

    @media (min-width: 992px) {
        padding: rem(142px) 0 rem(20px) 0;
    }
}

.pricing__ico {
    width: rem(16px);
    height: rem(16px);
    font-size: rem(16px);
}

.pricing__pack__header {
    padding-bottom: rem(16px);
}

.pricing__pack__title {
    font-size: rem(16px);
    font-weight: 600;
    color: $title-color;
    margin-bottom: rem(16px);
    line-height: 1.2;
}

.pricing__pack__price {
    font-size: rem(24px);
    font-weight: 600;
    color: $title-color;
    line-height: 1;
    margin-bottom: rem(16px);
}

.pricing__pack__price__currency {
    font-size: rem(13px);
    line-height: 1.2;
    font-weight: 400;
}

.pricing__pack__payment {
    line-height: 1.2;
    color: $dark_grey;
}

.pricing__pack__actions {
    margin-top: rem(16px);

    .btn-default {
        width: 100%;
        min-width: 0;
        cursor: pointer;
    }
}

.pricing__pack__current {
    min-height: rem(40px);
    color: $dark_grey;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pricing-notice {
    margin-top: rem(20px);
}

.pricing-notice__inner {
    margin: 0 auto;
    max-width: rem(460px);
    display: flex;
    flex-direction: column;
    gap: rem(8px);
    background: $light_grey;
    border-radius: rem(10px);
    padding: rem(20px);

    @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
        gap: rem(24px);
    }
}

.pricing-notice__body {
    flex: 1;
    display: flex;
    align-items: center;
    gap: rem(16px);
    line-height: 1.2;
}

.pricing-notice__ico {
    font-size: rem(16px);
    color: $title-color;
}

.pricing-notice__label {
    color: $dark;
    margin-bottom: rem(4px);
}

.pricing-notice__value {
    color: $title-color;
}


.pricing-notice-modal {
    p:last-child {
        margin-bottom: 0;
    }

    .modal-body {
        padding-bottom: 0;
    }

    @media (min-width: 576px) {
        .modal-dialog {
            max-width: rem(340px);
        }
    }


}

.pricing__header {
    text-align: center;
    margin-bottom: rem(20px);
    display: grid;
    gap: rem(8px);
    grid-template-columns: 100%;

    @media (min-width: 1200px) {
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
    }

    .input {
        display: inline-block;
        margin-bottom: 0;
    }

    .input__toggle-label {
        font-size: rem(18px);
        line-height: 1.1;
        font-weight: 600;
    }

    ._radioGroup {
        justify-self: flex-end;
    }
}

.pricing__header__period {
    @media (min-width: 1200px) {
        grid-column: 2/3;
    }
}

.pricing__header__currency {
    @media (min-width: 1200px) {
        text-align: right;
    }
}

.resub-notice {
    text-align: center;
    padding: rem(20px);
    background: $error-100;
    color: $danger;
    font-size: rem(16px);
    line-height: 1.5;
    margin: -25px -20px 20px;
    border-radius: 12px 12px 0 0;
}

.trial-notice {
    text-align: center;
    padding: rem(20px);
    background: $peach-100;
    color: $peach-300;
    font-size: rem(16px);
    line-height: 1.5;
    margin: -25px -20px 20px;
    border-radius: 12px 12px 0 0;
}

.pricing-payment-type {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: rem(20px);
    margin: rem(20px) auto;

    .input {
        margin: 0;
        height: 100%;
    }

    .input__inner {
        height: 100%;
    }

    .input__radio-input {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0;

        &:checked + .pricing-payment-type__item {
            border-color: $blue;
            color: $blue;
        }
    }
}

.pricing-payment-type__item {
    background: $light_grey;
    padding: rem(16px);
    border: 2px solid $border_color;
    border-radius: rem(10px);
    text-align: left;
    line-height: 1.2;
    color: $title-color;
    text-decoration: none;
    transition: color .2s ease-in-out;
    margin: 0;
    width: 100%;
    display: block;
    cursor: pointer;
    height: 100%;

    /* mouse, touch pad */
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            color: $blue;
            text-decoration: none;

            span {
                opacity: 1;
            }
        }
    }
}

.pricing-payment-type__ico {
    display: block;
    margin-bottom: rem(16px);
}

.pricing-payment-type__text {
    display: flex;
    align-items: center;

    span {
        font-size: rem(18px);
        margin-left: auto;
        opacity: 0;
        transition: opacity .2s ease-in-out;
    }
}

.pricing__pack__price_old {
    font-size: rem(14px);
    text-decoration: line-through;
    color: $dark_grey;
    font-weight: 400;
    margin-bottom: 0;
}

.pricing__pack__price_old_free {
    text-decoration: none;
}

.unsubModal {
    .modal-footer {
        flex-direction: column;
        gap: rem(8px);
        justify-content: stretch;

        .btn-default {
            margin: 0;

            @media (min-width: 576px) {
                width: auto;
            }
        }

        @media (min-width: 576px) {
            flex-direction: row;
            justify-content: space-between;
        }
    }
}

.unsubModalBlock {
    line-height: 1.5;

    hr {
        margin-block: rem(20px);
        border-color: $border_color;
    }

    ul {
        list-style: disc;
        padding-inline-start: rem(20px);
    }
}

.unsubModalBlock__section {
    margin-block-end: rem(20px);

    &:last-child {
        margin-block-end: 0;
    }
}

.unsubModalBlock__title {
    display: flex;
    align-items: center;
    gap: rem(8px);
    font-size: rem(16px);
    line-height: 1.2;
    margin: 0 0 rem(8px);
    font-weight: 600;
    color: $title-color;
}

.unsubModalBlock__ico {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1;
    width: rem(28px);
    border-radius: 50%;
    background-color: #FEF3F2;
    color: #F04438;
    font-size: rem(16px);
    line-height: 1;
}

.unsubModalBlock__smallTitle {
    line-height: 1.2;
    font-weight: 600;
    color: $title-color;
    margin: 0 0 rem(8px);
    font-size: rem(13px);
}

.unsubModalBlock__panel {
    padding: rem(16px);
    border-radius: rem(10px);
    background-color: $light_grey;

}
.pricing-payment-partners {
    display: flex;
    gap: rem(16px);
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-block: rem(20px);
}

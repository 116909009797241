@use 'sass:math';

$track-color: $grey-200 !default;
$thumb-color: $blue !default;

$thumb-radius: 12px !default;
$thumb-height: 24px !default;
$thumb-width: 24px !default;
$thumb-shadow-size: 0 !default;
$thumb-shadow-blur: 0 !default;
$thumb-shadow-color: rgba(0, 0, 0, .2) !default;
$thumb-border-width: 0 !default;
$thumb-border-color: #eceff1 !default;

$track-width: 100% !default;
$track-height: 8px !default;
$track-shadow-size: 0 !default;
$track-shadow-blur: 0 !default;
$track-shadow-color: rgba(0, 0, 0, .2) !default;
$track-border-width: 0 !default;
$track-border-color: #cfd8dc !default;

$track-radius: 5px !default;
$contrast: 5% !default;

$ie-bottom-track-color: darken($track-color, $contrast) !default;

@mixin shadow($shadow-size, $shadow-blur, $shadow-color) {
    box-shadow: $shadow-size $shadow-size $shadow-blur $shadow-color, 0 0 $shadow-size lighten($shadow-color, 5%);
}

@mixin track {
    cursor: default;
    height: $track-height;
    transition: all .2s ease;
    width: $track-width;
}

@mixin thumb {
    @include shadow($thumb-shadow-size, $thumb-shadow-blur, $thumb-shadow-color);
    background: $thumb-color;
    border: $thumb-border-width solid $thumb-border-color;
    border-radius: $thumb-radius;
    box-sizing: border-box;
    cursor: default;
    height: $thumb-height;
    width: $thumb-width;
}

[type='range'] {
    -webkit-appearance: none;
    background: transparent;
    margin: math.div($thumb-height, 2) 0;
    width: $track-width;
    cursor: pointer;

    &::-moz-focus-outer {
        border: 0;
    }

    //   &:focus {
    //     outline: 0;

    //     &::-webkit-slider-runnable-track {
    //       background: lighten($track-color, $contrast);
    //     }

    //     &::-ms-fill-lower {
    //       background: $track-color;
    //     }

    //     &::-ms-fill-upper {
    //       background: lighten($track-color, $contrast);
    //     }
    //   }

    &::-webkit-slider-runnable-track {
        @include track;
        @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
        background: $track-color;
        border: $track-border-width solid $track-border-color;
        border-radius: $track-radius;
    }

    &::-webkit-slider-thumb {
        @include thumb;
        -webkit-appearance: none;
        margin-top: (math.div((-$track-border-width * 2 + $track-height), 2) - math.div($thumb-height, 2));
    }

    &::-moz-range-track {
        @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
        @include track;
        background: $track-color;
        border: $track-border-width solid $track-border-color;
        border-radius: $track-radius;
        height: math.div($track-height, 2);
    }

    &::-moz-range-thumb {
        @include thumb;
    }

    &::-ms-track {
        @include track;
        background: transparent;
        border-color: transparent;
        border-width: math.div($thumb-height, 2) 0;
        color: transparent;
    }

    &::-ms-fill-lower {
        @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
        background: $ie-bottom-track-color;
        border: $track-border-width solid $track-border-color;
        border-radius: ($track-radius * 2);
    }

    &::-ms-fill-upper {
        @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
        background: $track-color;
        border: $track-border-width solid $track-border-color;
        border-radius: ($track-radius * 2);
    }

    &::-ms-thumb {
        @include thumb;
        margin-top: math.div($track-height, 4);
    }

    &:disabled,
    &[disabled] {
        opacity: .4;

        &::-webkit-slider-thumb,
        &::-moz-range-thumb,
        &::-ms-thumb,
        &::-webkit-slider-runnable-track,
        &::-ms-fill-lower,
        &::-ms-fill-upper {
            cursor: not-allowed;
            background: $dark_grey;
        }
    }
}

.input_range {
    .input__inner {
        padding-right: rem(48px);
    }

    .input__range-value {
        position: absolute;
        z-index: 1;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        color: $dark;
    }

}

.input__info {
    margin-left: rem(8px);
    color: $dark;
    font-size: rem(16px);
    vertical-align: middle;
}







.input {
    margin-bottom: rem(20px);

    .input__inner {
        position: relative;
    }

    .input__hidden {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0;
    }

    .input__radio-btn {
        margin: 0;
        font-weight: 400;
        color: #999;
        line-height: 1.2;
        text-align: center;
        padding: rem(8px) rem(20px);
        min-height: rem(40px);
        border-radius: rem(20px);
        border: 1px solid #F0F0F0;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    .input__hidden:checked + .input__radio-btn {
        background: #4154F1;
        color: #fff;
        border-color: #4154F1;
    }

    &:where(._radioGroup) {
        .input__inner {
            border: 1px solid $border_color;
            padding: 2px;
            display: flex;
            border-radius: 9999px;
            gap: rem(2px);
        }
    }
}

.input__btn {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    font-size: rem(16px);
    color: $blue;
    width: rem(42px);
    height: rem(42px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.input_combo {
    .input__text-input {
        padding-right: rem(48px);
    }
}

.input_combo_2 {
    .input__text-input {
        padding-right: rem(84px);
    }
}

.options-group {
    margin: rem(-8px) 0 rem(12px);
    display: flex;
    align-items: flex-start;

    .input {
        margin: rem(8px) rem(8px) rem(8px) 0;
    }
}


.input__label {
    font-size: rem(13px);
    line-height: 1.385;
    font-weight: 400;
    color: $dark;
    margin-bottom: rem(5px);
    display: inline-flex;
    align-items: center;
}

.input__label__ico {
    font-size: rem(16px);
    flex-shrink: 0;
    vertical-align: middle;
    color: $dark_grey;
    margin: rem(-11px) rem(-11px) rem(-11px) 0;
    width: rem(38px);
    height: rem(38px);
    position: relative;
    z-index: 1;
    touch-action: manipulation;
}

/* mouse, touch pad */
@media (hover: hover) and (pointer: fine) {
    .input {
        .input__radio-btn:hover {
            background: #F0F0F0;
            color: #555;
        }

        .input__hidden:checked + .input__radio-btn:hover {
            background: #4154F1;
            color: #fff;
        }
    }
}








.input__checkbox,
.input__radio {
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: -1;
}

.input__checkbox-label {
    position: relative;
    padding-left: rem(30px);
    font-size: rem(13px);
    line-height: 1.2;
    color: $dark;
    font-weight: 400;
    min-height: rem(20px);
    margin: 0;
    cursor: pointer;
}

.input__checkbox-label::before {
    content: "";
    display: block;
    width: rem(20);
    height: rem(20);
    border-radius: rem(4px);
    border: rem(2) solid #EAEAEA;
    background: $white;
    position: absolute;
    top: -2px;
    left: 0;
    transition: none;
}

.input__checkbox:checked ~ .input__checkbox-label::before {
    border-color: $blue;
    background: $white url('../img/checked-icon.svg') no-repeat 2px 2px / auto;
}

.input__checkbox:indeterminate ~ .input__checkbox-label::before {
    border-color: $blue;
    background: $white url('../img/indeterminate.svg') no-repeat center center / rem(15px) rem(15px);
}

.input__checkbox:disabled ~ .input__checkbox-label {
    filter: grayscale(100%);
    opacity: .7;
    cursor: default;
}

.input__checkbox.form_error ~ .input__checkbox-label::before {
    border-color: $danger;
}

.input__radio-label {
    position: relative;
    padding-left: rem(30px);
    font-size: rem(13px);
    line-height: 1.2;
    color: $dark;
    font-weight: 400;
    min-height: rem(20px);
    margin: 0;
    cursor: pointer;
}

.input__radio-label::before {
    content: "";
    display: block;
    width: rem(20);
    height: rem(20);
    border-radius: 50%;
    border: rem(2) solid #EAEAEA;
    background: $white;
    position: absolute;
    top: rem(-2px);
    left: 0;
    transition: none;
}

.input__radio-label::after {
    content: "";
    display: block;
    width: rem(12px);
    height: rem(12px);
    border-radius: 50%;
    background: $blue;
    position: absolute;
    top: rem(2px);
    left: rem(4px);
    transition: none;
    opacity: 0;
}

.input__radio:checked ~ .input__radio-label::before {
    border-color: $blue;
    background: $white;
}

.input__radio:checked ~ .input__radio-label::after {
    opacity: 1;
}

.input__radio:disabled ~ .input__radio-label {
    filter: grayscale(100%);
    opacity: .7;
    cursor: default;
}

.input__radio.form_error ~ .input__radio-label::before {
    border-color: $danger;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}



.multi-select {
    position: relative;
    margin-bottom: rem(20px);
}

.top_filter_row .multi-select {
    z-index: 4;
}


.multi-select__input {
    position: relative;
    border: 1px solid #F0F0F0;
    border-radius: rem(8px);
    background: #fff;
    padding: rem(10px) rem(40px) rem(10px) rem(44px);
    color: #999;
    font-size: rem(16px);
    line-height: 1.2;
    min-height: rem(42px);
    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.multi-select__input::after {
    content: "\e930";
    font-family: "sellerboard", sans-serif;
    position: absolute;
    color: #999999;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    font-size: rem(14px);
}

.multi-select__input::before {
    content: "\ea23";
    font-family: "sellerboard", sans-serif;
    position: absolute;
    color: #999999;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: rem(16px);
}

.multi-select__dropdown {
    position: absolute;
    bottom: calc(100% + rem(8px));
    left: 0;
    z-index: 3;
    background: #fff;
    box-shadow: 0 rem(8px) rem(18px) rem(-6px) rgba(24, 39, 75, 0.12), 0 rem(12px) rem(42px) rem(-4px) rgba(24, 39, 75, 0.12);
    border-radius: rem(8px);
    width: 100%;
}

.multi-select__dropdown__header {
    position: relative;
    border-bottom: 1px solid $border_color;
}

.multi-select__dropdown__header_flex {


    @media (min-width: 576px) {
        display: flex;
        padding-right: rem(20px);
    }
}

.multi-select__dropdown__header__ico {
    position: absolute;
    top: 50%;
    left: rem(20px);
    font-size: rem(16px);
    margin-top: rem(-8px);
    pointer-events: none;
    user-select: none;
    color: $dark;
    opacity: .5;
}

.top_filter_row {
    .multi-select__dropdown {
        top: calc(100% + rem(8px));
        bottom: auto;
    }
}

.multi-select__search {
    width: 100%;
    border: 0;
    padding: rem(10px) rem(20px) rem(10px) rem(44px);
    min-height: rem(42px);
    font-size: rem(16px);
    color: #6A7C92;
    border-radius: rem(8px) rem(8px) 0 0;

    &:has(+ .multi-select__dropdown__header__filterBtn) {
        padding-right: rem(44px);
    }
}

.multi-select__choose-all {
    display: none;
    white-space: nowrap;
    color: $blue;
    align-items: center;

    @media (min-width: 576px) {
        display: inline-flex;
    }
}

.multi-select__search:focus {
    outline: none;
    box-shadow: none;
    border-color: #f0f0f0;
}

.multi-select__item {
    border-bottom: 1px solid #f0f0f0;
    border-left: 2px solid transparent;

    &.active {
        border-left-color: $blue;
        background: $light_grey;
    }

    &.current {
        background: $light_grey;
    }
}

.multi-select__item:last-child {
    border-bottom: 0;
}

.multi-select__item__header {
    position: relative;
    padding: rem(5px) rem(20px) rem(5px) rem(6px);
    display: flex;
    align-items: center;
}

.multi-select__item__header .input {
    position: relative;
    z-index: 2;
}

.multi-select__item__header::before {
    content: "";
    width: 0;
    height: 0;
    border-color: transparent transparent transparent $blue;
    border-style: solid;
    border-width: rem(4px) 0 rem(4px) rem(6px);
    flex-shrink: 0;
    transition: transform .3s ease-in-out;
    flex-shrink: 0;
    margin-right: rem(8px);
}
.multi-select__item-single .multi-select__item__header::before{visibility:hidden;}

.multi-select__item__header__content {
    flex: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
}


.multi-select__dropdown .input {
    margin-bottom: 0;
}

.multi-select__item__header .input__checkbox-label {
    display: block;
}

.multi-select__item__header .input__checkbox-label::before {
    top: 0;
}

.multi-select__item__img {
    flex-shrink: 0;
    margin-right: rem(8px);
}

.multi-select__item__header__content__body {
    font-size: rem(13px);
    line-height: 1.46;
}

.multi-select__item__title {
    color: $title-color;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.multi-select__item__trigger {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.multi-select__item__body .input {
    padding: rem(5px) rem(20px);
}

.multi-select__item__body {
    display: none;
}

.multi-select__item.active .multi-select__item__body {
    display: block;
}

.multi-select__item.active .multi-select__item__header::before {
    transform: rotate(90deg);
}

.multi-select__dropdown__body {
    overflow: auto;
    max-height: rem(200px);

    scrollbar-width: thin;
    scrollbar-color: #C8C8C8 #F2F3F3;
    &::-webkit-scrollbar {
        width: 7px;
        height: 5px;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 10px rgba(129, 140, 136, 0.2);
        box-shadow: inset 0 0 10px rgba(129, 140, 136, 0.2);
        border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(100,100,100,.3);
        border-radius: 4px;
        cursor: pointer;
        position: relative;
        opacity: .3;
        transition: opacity .3s ease-in-out;
    }
}

.show-more-container {
    padding: rem(8px);
}
.show-more-container .btn-default {
    display: inline-block;
}

.multi-select__selected__count {
    position: absolute;
    z-index: 1;
    top: rem(4px);
    right: rem(38px);
    min-height: rem(34px);
    border-radius: rem(8px);
    background: #f0f0f0;
    padding: rem(4px) rem(8px);
    display: flex;
    align-items: center;

    .fm-close {
        flex-shrink: 0;
        margin-left: rem(8px);
        cursor: pointer;
    }
}

.multi-select_single {
    // .multi-select__item__trigger {
    //     width: rem(32px);
    // }

    .multi-select__item__header__content {
        cursor: initial;
    }

    .multi-select__item__body {
        padding: 0 rem(12px) rem(5px) rem(28px);
    }

    .multi-select__item__header .input {
        position: static;

        label {
            position: absolute;
            top: 0;
            left: rem(32px);
            z-index: 2;
            width: calc(100% - rem(32px));
            height: 100%;
            margin: 0;
            cursor: pointer;
        }
    }

    .multi-select__item__trigger {
        width: rem(32px);
    }

}

.multi-select_multiple {
    .multi-select__item__body {
        padding: 0 rem(12px) rem(5px) rem(48px);
    }
}

.multi-select_modal {
    .multi-select__item__body {
        padding: 0 rem(12px) rem(5px) rem(20px);
    }

    .multi-select__item {
        padding-block: rem(11px);
    }
}

/* mouse, touch pad */
@media (hover: hover) and (pointer: fine) {
    .multi-select_single {
        .multi-select__item__header:hover {
            background: $light_grey;
        }
    }
}

.multi-select__item__copy-item {
    color: $title-color;
    display: inline-flex;
    align-items: center;

    button {
        position: relative;
        z-index: 2;
        font-size: rem(15px);
        margin-right: rem(5px);
        padding: 0;
        border: 0;
        background: transparent;
        color: $blue;
        line-height: 1;

        &::after {
            left: 0;
            right: auto;
        }
    }

    a {
        position: relative;
        z-index: 2;
    }
}

.multi-select__choosen {
    line-height: 1.2;
}

.multi-select__choosen__title {
    color: $dark;
}

.multi-select__choosen__subtitle {
    color: $dark;
    font-size: rem(12px);
}

@media (min-width: 768px) {
    .multi-select__input,
    .multi-select__search {
        font-size: rem(13px);
    }

    .multi-select__dropdown {
        top: calc(100% + rem(8px));
        bottom: auto;
    }
}


body .select2-container--basic {
    .select2-selection--single {
        height: rem(42px);
        padding: rem(6px) rem(32px) rem(6px) rem(6px);
        border: 1px solid $border_color;
        line-height: rem(30px);

        .select2-selection__rendered {
            padding: 0;
        }
    }

    .select2-selection__arrow {
        display: block;
        position: absolute;
        right: rem(6px);
        top: 50%;
        margin-top: rem(-8px);
        width: rem(16px);
        height: rem(16px);
        background: url('/img/chevron-down.svg') no-repeat center center / rem(16px) rem(16px);
        opacity: .4;
    }

    .select2-dropdown {
        border: 0;
        border-radius: rem(8px);
        padding: rem(8px) 0;
        box-shadow: 0 8px 18px -6px rgba(24, 39, 75, .12), 0 12px 42px -4px rgba(24, 39, 75, .12);
        min-width: auto;
        z-index: 1000;

    }

    .select2-dropdown--below {
        border: 0;
        min-width: auto;
    }

    &.select2-container--open {
        .select2-dropdown {
            border: 0;
            min-width: auto;
        }

    }

    .select2-results__option {
        padding: rem(8px) rem(16px);

        &.select2-results__option--highlighted {
            background: $light_grey;
        }
    }
}


@media (min-width: 861px) {

    body .expenses-wrapper {
        .select2-container--basic {
            .select2-selection--single {
                min-height: rem(68px);
                line-height: calc(68px - 12px);

                .select2-selection__rendered {
                    padding: 0;
                }
            }
        }
    }
}

.multi-select__loading {
    padding: rem(20px);
    text-align: center;

    .spinner-border {
        width: 1em;
        height: 1em;
        border-width: 2px;
    }
}


.multi-select + .select_outer {
    margin-top: rem(8px);
}

@media (min-width: 1025px) {
    .multi-select + .select_outer {
        margin-top: 0;
    }
}

.multi-select__product {
    padding: rem(4px) 0;
    display: grid;
    grid-template-columns: rem(40px) auto;
    gap: rem(10px);
    align-items: center;
}

.multi-select__product__title {
    color: $title-color;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.multi-select__fake-label {
    font-size: rem(13px);
    line-height: 1.385;
    font-weight: 400;
    color: $dark;
    margin-bottom: rem(5px);
}

.input__actions {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    z-index: 1;

    .input__btn {
        position: static;
    }

    .fm-x {
        color: #555;
        opacity: .4;
    }
}

/* mouse, touch pad */
@media (hover: hover) and (pointer: fine) {
    .input__actions  {
        .fm-x:hover {
            opacity: 1;
        }
    }
}

.input__toggle {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;

    &:checked + .input__toggle-label {
        &::before,
        .input__toggle-label__switcher {
            background: $blue;
            border-color: $blue;
        }

        &::after,
        .input__toggle-label__switcher::after {
            background: $white;
            transform: translateX(100%);
        }
    }

    &:checked + .input__toggle-label_switcher {
        > span:first-child {
            color: $dark_grey;

            .pricing-tabs__btn__tag {
                background: $dark_grey;
            }
        }

        > span:last-child {
            color: $title-color;
        }
    }


}

.input__toggle-label {
    position: relative;
    padding-left: rem(42px);
    margin: 0;
    min-height: rem(20px);
    line-height: 1.5;
    user-select: none;
    cursor: pointer;
    font-size: rem(13px);

    &::before,
    &::after {
        content: "";
        position: absolute;
        transition: background .2s ease-in-out, border-color .2s ease-in-out, transform .2s ease-in-out;
    }

    &::before {
        top: 0;
        left: 0;
        width: rem(34px);
        height: rem(20px);
        background: $white;
        border: rem(2px) solid #EAEAEA;
        border-radius: rem(20px);
    }

    &::after {
        top: rem(3px);
        left: rem(3px);
        width: rem(14px);
        height: rem(14px);
        border-radius: 50%;
        background: #EAEAEA;
    }
}

.input__toggle-label_switcher {
    padding-left: 0;
    display: inline-flex;
    align-items: center;
    gap: rem(8px);
    color: $dark_grey;

    > span:first-child {
        color: $title-color;
    }

    &::before,
    &::after {
        display: none;
    }

}

.input__toggle-label__switcher {
    position: relative;
    display: inline-flex;
    width: rem(34px);
    height: rem(20px);
    background: $blue;
    border: rem(2px) solid $blue;
    border-radius: rem(20px);
    transition: background .2s ease-in-out, border-color .2s ease-in-out, transform .2s ease-in-out;

    &::after {
        content: "";
        position: absolute;
        transition: background .2s ease-in-out, border-color .2s ease-in-out, transform .2s ease-in-out;
        top: rem(1px);
        left: rem(1px);
        width: rem(14px);
        height: rem(14px);
        border-radius: 50%;
        background: $white;
    }
}

.table-input {
    width: 100%;
    height: 100%;
}

.table-input__label {
    position: relative;
    margin: 0;
    display: block;
    width: 100%;
    height: 100%;
}

.table-input__text-input {
    width: 100%;
    border: 1px solid $border_color;
    background: transparent;
    font-size: rem(13px);
    padding: rem(6px);
    min-width: 1em;
    height: 100%;

    &.error {
        border-color: #f47171 !important;
    }

    &:focus {
        outline: none;
        box-shadow: none;
        border-color: #D3CECE;
        background: $white;
    }
}


.input__select {
    display: block;
    width: 100%;
    border-radius: rem(8px);
    min-height: rem(40px);
    padding: rem(8px) rem(52px) rem(8px) rem(20px);
    border: 1px solid $border_color;
    color: $title-color;
    background: $white url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' opacity='0.4' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") no-repeat center right rem(20px) / rem(14px) rem(14px);
    appearance: none;
    margin: 0;

    &:focus {
        outline: none;
        box-shadow: none;
        border-color: $border_color;
    }
}



.input_file {
    display: grid;
    grid-template-columns: 100%;
    gap: rem(16px);

    @media (min-width: 768px) {
        display: flex;
        align-items: center;
    }

    label {
        margin: 0;
    }
}

.input__file {
    display: flex;
    align-items: center;
    gap: rem(8px);
    flex: 1;
}

.input__file__body {
    flex: 1;
}

.input__file__progress {
    margin-top: rem(4px);
    background: $light_grey;
    position: relative;
    height: rem(2px);
    border-radius: rem(2px);
    overflow: hidden;
}

.input__file__progress__bar {
    position: absolute;
    top: 0;
    left: 0;
    background: $blue;
    height: 100%;
}

.input__file-input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    max-width: 100%;
}

.input__file__title {
    max-width: 100%;
    word-break: break-all;
    line-height: 1.2;
}

.input__file__ico {
    font-size: rem(24px);
    color: $blue;
    display: flex;
    align-items: center;

    .spinner-border {
        border-width: 2px;
        width: rem(18px);
        height: rem(18px);
    }
}

.files-input {
    position: relative;

}
.dropdown-menu_form {
    padding: rem(16px);

    .input:last-child {
        margin-bottom: 0;
    }

}

.files-input__input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
}

.files-input__label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: rem(8px);
    cursor: pointer;

    min-height: rem(140px);
    padding: rem(16px);
    text-align: center;
    color: $dark;
    line-height: 1.5;
    margin: 0;
    font-weight: 400;
    font-size: rem(13px);
    background: $light_grey;
    border-radius: rem(10px);
    border: 1px dashed #ddd;

    span {
        font-size: rem(16px);
        color: $blue;
    }

    &.highlight {
        background: rgba($blue, .05);
        border-color: $blue;
    }
}

.files-input__list {
    display: grid;
    grid-template-columns: 100%;
    gap: rem(8px);
    margin-top: rem(16px);

    &:empty {
        margin-top: 0;
    }
}

.files-input__file {
    display: flex;
    align-items: center;
    gap: rem(8px);
}

.files-input__file__done {
    font-size: rem(16px);
    width: rem(16px);
    height: rem(16px);
    color: $blue;
}

.files-input__file__body {
    flex: 1;
}

.files-input__file__name {
    color: $title-color;
    line-height: 1.2;
    word-break: break-all;
}

.files-input__file__progress {
    position: relative;
    margin-top: rem(4px);
    background: $light_grey;
    border-radius: 2px;
    height: 2px;
    overflow: hidden;
}

.files-input__file__bar {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: $blue;
}

.files-input__file__remove {
    width: rem(38px);
    height: rem(38px);
    background: transparent;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    opacity: .4;
    transition: opacity .2s ease-in-out;

    /* mouse, touch pad */
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            opacity: 1;
        }
    }
}

.files-input__file__spinner {
    width: rem(16px);
    height: rem(16px);
    color: $blue;


    .spinner-border {
        border-width: 1.33px;
        width: rem(16px);
        height: rem(16px);
    }
}

.files-input__footer {
    margin-top: rem(16px);
    text-align: center;
}
.multi-select__footer {
    padding: rem(8px) rem(20px);
}

.multi-select__dropdown__header__filterBtn {
    position: absolute;
    z-index: 1;
    top: rem(2px);
    right: rem(2px);
    width: rem(40px);
    height: rem(40px);
    background-color: transparent;
    color: $blue;
    font-size: rem(16px);
    border-radius: 50%;
    transition: background-color .2s ease-in-out;

    /* mouse, touch pad */
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background-color: $light_grey;
        }
    }

    &.active::after {
        content: "";
        display: block;
        width: rem(8px);
        height: rem(8px);
        border-radius: 50%;
        background: $accent;
        position: absolute;
        top: rem(6px);
        right: rem(6px);
    }
}

.input__radioInput {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;

    &:checked + .input__radioGroupLabel {
        background-color: $blue;
        color: $white;
    }
}

.input__radioGroupLabel {
    display: inline-flex;
    align-items: center;
    gap: rem(8px);
    margin: 0;
    color: $dark;
    line-height: 1.2;
    padding: rem(4px) rem(12px);
    border-radius: 9999px;
    cursor: pointer;
    user-select: none;
}

@import 'variables';
@import 'functions';
.daterangepicker {
  position: absolute;
  color: $title-color;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 278px;
  max-width: none;
  padding: 0;
  margin-top: 7px;
  top: 100px;
  left: 20px;
  z-index: 3001;
  display: none;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  line-height: 1em;
}
.daterangepicker.single {
  width: rem(320);
  border-radius: 8px;
}
.daterangepicker:before, .daterangepicker:after {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}

.daterangepicker:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc;
}

.daterangepicker:after {
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
}

.daterangepicker.opensleft:before {
  right: 9px;
}

.daterangepicker.opensleft:after {
  right: 10px;
}

.daterangepicker.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.opensright:before {
  left: rem(20);
}

.daterangepicker.opensright:after {
  left: rem(21);
}

.daterangepicker.drop-up {
  margin-top: -7px;
}

.daterangepicker.drop-up:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #ccc;
}

.daterangepicker.drop-up:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #fff;
}

.daterangepicker.single .daterangepicker .ranges, .daterangepicker.single .drp-calendar {
  float: none;
}

.daterangepicker.single .drp-selected {
  display: none;
}

.daterangepicker.show-calendar .drp-calendar {
  display: block;
}

.daterangepicker.show-calendar .drp-buttons {
    display: flex;
    gap: rem(8px);
}

.daterangepicker.auto-apply .drp-buttons {
  display: none;
}

.daterangepicker .drp-calendar {
  display: none;
  max-width: 270px;
}

.daterangepicker .drp-calendar.left {
  padding: 13px 0 8px 8px;
  &.single {
    padding: 13px 20px 8px 20px;
  }
}

.daterangepicker .drp-calendar.right {
  padding: 13px 8px 8px;
}

.daterangepicker .drp-calendar.single .calendar-table {
  border: none;
}

.daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span {
  color: #fff;
  border: solid black;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 4px;
}

.daterangepicker .calendar-table .next span {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.daterangepicker .calendar-table .prev span {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  height: 24px;
  line-height: 24px;
  font-size: 13px;
  border-radius: 0;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;
}
.daterangepicker.single .calendar-table th, .daterangepicker.single .calendar-table td {
  min-width: 40px;
  width: 40px;
  height: 40px;
  line-height: 37px;
  font-size: 12px;
  border-radius: 0;
  white-space: nowrap;
  cursor: pointer;
}
.daterangepicker .calendar-table th.prev {
  text-align: left;
  &:hover {
    background-color: transparent;
  }
}
.daterangepicker .calendar-table th.next {
  text-align: right;
  &:hover {
    background-color: transparent;
  }
}
.daterangepicker .calendar-table tr.days th {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #232323;
  border-bottom: 1px solid #DFDFDF;
}
.daterangepicker .calendar-table td {
  border-right: 1px solid #DFDFDF;
  border-bottom: 1px solid #DFDFDF;
}
.daterangepicker .calendar-table td.today {
  background: #17B787;
  color: #fff;
}

.daterangepicker .calendar-table td.today:hover {
  background: #17B787;
  color: #fff;
}

.daterangepicker .calendar-table {
  //border: 1px solid #fff;
  //border-radius: 4px;
  background-color: #fff;
}
.daterangepicker .calendar-table tr td:first-of-type {
  border-left: 1px solid #DFDFDF;
}

.daterangepicker .calendar-table table {
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
}

.daterangepicker td.available:hover, .daterangepicker th.available:hover {
  background-color: #ebf4f8;
  color: inherit;
}

.daterangepicker td.week, .daterangepicker th.week {
  font-size: 80%;
  color: #ccc;
}

.daterangepicker {
  .calendar-table {
    tbody {
      td {
        border: 0;

        &:first-of-type {
          border-left: 0;
        }
      }
    }
  }

}

.daterangepicker td.ends {
    visibility: hidden !important;
    border:0 !important;
}
//.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  background-color: #F8F8F8;
  color: #999;
}

.daterangepicker td.in-range {
  background-color: #ebf4f8;
  color: #000;
  border-radius: 0;
}

.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px;
}

.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0;
}

.daterangepicker td.start-date.end-date {
  border-radius: 0;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: $blue;
  border-color: transparent;
  color: #fff;
}

.daterangepicker th.month {
  width: auto;
}
.daterangepicker.single th.month {
  width: auto;
  font-size: 18px;
  font-weight: 700;
  color: #000;
}
.daterangepicker td.disabled, .daterangepicker option.disabled {
  color: $grey-200;
  cursor: not-allowed;
}

.daterangepicker select.monthselect, .daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}

.daterangepicker select.yearselect {
  width: 40%;
}

.daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect, .daterangepicker select.ampmselect {
  width: 50px;
  margin: 0 auto;
  background: #eee;
  border: 1px solid #eee;
  padding: 2px;
  outline: 0;
  font-size: 12px;
}

.daterangepicker .calendar-time {
  text-align: center;
  margin: 4px auto 0 auto;
  line-height: 30px;
  position: relative;
}

.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}

.daterangepicker .drp-buttons {
  clear: both;
  text-align: center;
  padding: 8px;
  border-top: 1px solid #ddd;
  display: none;
  line-height: 1;
  vertical-align: middle;
}

.daterangepicker .drp-selected {
  display: inline-block;
  font-size: 12px;
  padding-right: 8px;
}

.daterangepicker .drp-buttons .btn {
  margin-top: 8px;
  font-size: 13px;
  font-weight: 400;
  padding: 4px 8px;
}

.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.single.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker.show-ranges.rtl .drp-calendar.right {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker .ranges {
  float: none;
  text-align: left;
  margin: 0;
}

.daterangepicker.show-calendar .ranges {
  margin-top: 8px;
}

.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 10px;
  width: 100%;
}

.daterangepicker .ranges li {
  font-size: 13px;
  height: rem(36);
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  background: #f5f5f5;
  border: 1px solid #dedede;
  color: #555;
  padding: 5px 12px;
  white-space: nowrap;
  -webkit-border-radius: rem(8);
  -moz-border-radius: rem(8);
  border-radius: rem(8);
  cursor: pointer;
  transition: background-color .2s ease-in-out 0s, border-color .2s ease-in-out 0s;
  &:not(:last-of-type) {
    margin-bottom: rem(8);
  }
}
.daterangepicker .ranges li:hover, .daterangepicker .ranges li.active {
  background-color: $blue;
  border-color: $blue;
  color: #fff;
}
/*  Larger Screen Styling */
@media (min-width: 564px) {
  .daterangepicker {
    width: auto;
  }

  .daterangepicker {
    .ranges ul {
      width: auto;
      min-width: rem(325);
    }
  }
  .daterangepicker.show-calendar {
    .ranges ul {
      width: auto;
      min-width: rem(50);
    }
  }
  .daterangepicker.single .ranges ul {
    width: 100%;
  }

  .daterangepicker.single .drp-calendar.left {
    clear: none;
    padding: rem(20);
    &.single {
      padding: 13px rem(20) rem(15);
      background: #FFFFFF;
      box-shadow: 0 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12);
      border: 0 none;
      border-radius: 8px;
    }
  }
  .daterangepicker.single .drp-calendar {
    max-width: rem(320);
  }

  // .daterangepicker.single .ranges, .daterangepicker.single .drp-calendar {
  //   float: left;
  // }

  .daterangepicker {
    direction: ltr;
    text-align: left;
  }

  .daterangepicker .drp-calendar.left {
    clear: left;
    margin-right: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .daterangepicker .drp-calendar.right {
    margin-left: 0;
  }

  .daterangepicker .drp-calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    padding-right: 8px;
  }
  .daterangepicker.single .drp-calendar.left .calendar-table {
    padding-right: 0;
  }
  .daterangepicker .ranges, .daterangepicker .drp-calendar {
    float: left;
  }
  .daterangepicker .drp-buttons {
    clear: both;
    text-align: right;
    padding: 8px;
    border-top: 1px solid #ddd;
    display: none;
    line-height: 12px;
    vertical-align: middle;
    .btn {
      margin-top: 0;
      margin-left: 0;
    }
  }
}

@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto;
  }

  .daterangepicker .ranges {
    float: left;
  }

  .daterangepicker.rtl .ranges {
    float: right;
  }

  .daterangepicker .drp-calendar.left {
    clear: none !important;
  }
}
@media screen and (max-width: 750px) {
  .daterangepicker::before,
  .daterangepicker::after {
    content: "";
  }

  .daterangepicker.single {
    // position: fixed;
    // top: 50%!important;
    // left: 50%!important;
    // transform: translate(-50%,-50%);
    box-shadow: 0 8px 18px -6px rgba(24, 39, 75, 0.12), 0 12px 42px -4px rgba(24, 39, 75, 0.12);
    border-radius: 8px
  }
  .daterangepicker .drp-calendar.single {
    max-width: 100%;
  }
}

.reports-import-modal__help-panel {
    border-radius: rem(10px);
    padding: rem(16px);
    background: $light_grey;
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 0 rem(16px);
    line-height: 1.5;
    // cursor: pointer;
    gap: rem(16px);

    /* mouse, touch pad */
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            .reports-import-modal__help-panel__link span {
                transform: translateX(rem(4px));
            }
        }
    }
}

.reports-import-modal__help-panel__title {
    font-size: rem(13px);
    line-height: 1.5;
    font-weight: 700;
    color: $title-color;
    margin: 0;
}

.reports-import-modal__help-panel__link {
    margin-left: auto;
    color: $blue;
    text-decoration: none;
    font-size: rem(16px);

    &:focus,
    &:hover {
        text-decoration: none;
        color: $blue;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: block;
    }

    span {
        display: block;
        transition: transform .2s ease-in-out;
    }
}

.reports-import-modal {
    .info-banner-outer {
        padding: 0;
        margin-bottom: rem(16px);
    }

    .info-banner__title {
        color: $title-color;
        font-size: rem(16px);
    }
}

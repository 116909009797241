.payment-success {
    position: relative;
}

.payment-success__pack {
    border-radius: rem(10px);
    background: linear-gradient(135deg, #3B31ED -2.27%, #4773F4 77.3%, #52B4FB 152.43%), #4154F1;
    padding: rem(24px);
    margin-bottom: rem(20px);
    color: $white;
}

.payment-success__pack__ico {
    width: rem(32px);
    height: rem(32px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: rgba($white, .2);
    margin-bottom: rem(24px);

    span {
        font-size: rem(16px);
    }
}

.payment-success__pack__body {
    display: flex;
    gap: rem(24px);
}

.payment-success__pack__left {
    flex: 1;

    .payment-success__pack__title + .payment-success__pack__label {
        margin-top: rem(8px);
    }
}

.payment-success__pack__label {
    font-size: rem(13px);
    line-height: 1.2;
}

.payment-success__pack__title {
    font-size: rem(24px);
    line-height: 1.2;
    font-weight: 600;
}

.payment-success__pack__right {
    text-align: right;
}

.payment-success__summary {
    width: 100%;

    th,
    td {
        line-height: 1.2;
        padding: rem(8px) 0;
    }

    th {
        font-weight: 400;
        color: $dark;
    }

    td {
        color: $title-color;
    }
}

.payment-success__hr {
    margin: rem(20px) 0;
    border-top: 1px solid $border_color;
}

.payment-success__confetti {
    position: absolute;
    top: rem(-20px);
    left: 0;
    width: 100%;
    height: auto;
    z-index: 1;
    opacity: 0;
    transform: translateY(0);
    animation: confetti-fade-in 2s cubic-bezier(0, 0.88, 0.51, 1), confetti-fade-out 2s cubic-bezier(.84, 0, 1, 1) 2s;
    pointer-events: none;
    user-select: none;
}

@keyframes confetti-fade-in {
    15% {
        opacity: 0;
        transform: translateY(50%);
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes confetti-fade-out {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(30%);
    }
}

@import 'variables';
@import 'functions';
.feedback {
    width: 100%;
    // overflow-x: hidden;
    // overflow-y: auto;
    background-color: #F9F9F9;
    .container {
        position: relative;
    }
    padding: rem(50) 0 rem(17);
}

.feedback__wrapper {
    max-width: 1400px;
    overflow: hidden;
    margin: 0 auto;
}

.feedback__inner {
    position: relative;
    z-index: 1;
    user-select: none;
}
.feedback__header {
    text-align: center;
    margin-bottom: rem(30px);
    .feedback__title {
        font-size: rem(24);
        line-height: rem(32.68);
        color: #232323;
    }
}
.feedback .leading {
    color: $dark_grey;
}



.feedback__slider {
    width: auto;
    margin: 0 rem(-24px);
    padding: rem(40px) rem(24px) rem(24px);
    overflow: visible;
}

.feedback__slider .swiper-slide {
    height: auto;
    opacity: 0;
    transition: opacity .2s ease;
}

.feedback__item {
    background: $white;
    box-shadow: 0 6px 32px rgba(15, 13, 29, 0.08);
    border-radius: rem(8);
    padding: rem(16px);
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.feedback__item__avatar {
    margin: calc(-40px - 16px) auto rem(8px);
    border-radius: 50%;
    flex-shrink: 0;
}

.feedback__item__header {
    position: relative;
    margin-bottom: rem(9);
    flex-shrink: 0;
    p {
        margin-bottom: 0;
    }
}

.feedback__item__link {
    position: absolute;
    z-index: 1;
    top: rem(-4px);
    right: 0;
    color: $dark;
    opacity: .4;
    height: rem(38px);
    width: rem(38px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.feedback__item__title {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: #232323;
    margin-bottom: rem(5);
}

.feedback__item__subtitle {
    color: $dark_grey;
    font-size: rem(15);
}

.feedback__slider__nav {
    display: flex;
    align-items: center;
    margin-top: rem(48px);
}

.feedback__slider .swiper-pagination {
    position: static;
    margin: 0 auto;
    color: $dark_grey;
    width: auto;
    z-index: 0;
    font-size: rem(15);
}

.feedback__slider .swiper-button-next::after,
.feedback__slider .swiper-button-prev::after {
    display: none;
}

.feedback__slider .swiper-button-next,
.feedback__slider .swiper-button-prev {
    position: static;
    margin-top: 0;
    width: 38px;
    height: 38px;
    padding: 0;
    color: $dark_grey;
    border-radius: 50%;
    transition: color .2s ease, background .2s ease, opacity .2s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.feedback__slider .swiper-button-next:focus,
.feedback__slider .swiper-button-prev:focus {
    outline: none;
}

.feedback__slider .swiper-button-next span,
.feedback__slider .swiper-button-prev span {
    flex-shrink: 0;
    font-size: rem(31);
}

.feedback__item__body {
    position: relative;
    max-height: 13.5em;
    overflow: hidden;
    flex: 1;
    font-size: rem(15px);
    line-height: rem(27.5);
}

.feedback__item__body::after {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 6em;
    transition: opacity .2s ease;
    background: linear-gradient(to bottom, rgba(255,255,255, 0) 0, rgba(255,255,255,1) 100%);
    background-image: -webkit-linear-gradient(top,rgba(255,255,255, 0) 0,rgba(255,255,255,1) 100%);
}

.feedback__item__body.show {
    max-height: none;
}

.feedback__item__body.show::after {
    opacity: 0;
}

.feedback__item__footer {
    flex-shrink: 0;
    margin-top: rem(12px);
    .btn-link {
        align-items: center;
        -webkit-align-items: center;
        background: transparent;
        border: 0;
        border-radius: 1.1875rem;
        color: $blue;
        display: inline-flex;
        display: -webkit-inline-flex;
        font-size: 1rem;
        justify-content: center;
        -webkit-justify-content: center;
        line-height: 1.26;
        min-height: 2.375rem;
        padding: 0.25rem 1rem;
        text-align: center;
        text-decoration: none;
        transition: background .2s ease,color .2s ease,border-color .2s ease;
        &:focus {
            outline: none;
        }
    }
}

.feedback__item__footer.hidden {
    display: none;
}

.feedback__slider .swiper-slide-active {
    opacity: 1;
}

.feedback__slider .swiper-slide-duplicate-prev,
.feedback__slider .swiper-slide-prev {
    opacity: 1;
}

.feedback__slider .swiper-slide-duplicate-next,
.feedback__slider .swiper-slide-next {
    opacity: 1;
}

.feedback__slider .swiper-slide:focus {
    outline: none;
}

@media (min-width: 768px) {
    .feedback__header {
        max-width: rem(554px);
        margin-left: auto;
        margin-right: auto;
    }

    .feedback__slider .swiper-slide {
        opacity: 1;
    }
}

@media (min-width: 992px) {

    .feedback__slider {
        margin: 0;
        padding: 0 0 rem(24px);
    }

    .feedback__slider .swiper-wrapper {
        align-items: center;
        min-height: rem(460px);
    }

    .feedback__slider .swiper-slide {
        width: 33.3333%;
        padding: 0 4%;
        opacity: 0;
    }

    .feedback__slider .swiper-slide-active {
        padding: 0;
        opacity: 1;
    }

    .feedback__slider .swiper-slide-duplicate-prev,
    .feedback__slider .swiper-slide-prev {
        padding: 0 8% 0 0;
        opacity: 1;
    }

    .feedback__slider .swiper-slide-duplicate-next,
    .feedback__slider .swiper-slide-next {
        padding: 0 0 0 8%;
        opacity: 1;
    }


    .feedback__slider .swiper-slide-active .feedback__item {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        width: 134.442%;
        height: auto;
        transform: translate(-50%, -50%);
    }

    .feedback__item__body {
        font-size: rem(13px);
        max-height: 6.5em;
        line-height: rem(17.7);
    }

    .feedback__item__footer {
        display: none;
    }
    .feedback__slider .swiper-slide-active .feedback__item__header {
        margin-bottom: rem(24);
    }
    .feedback__slider .swiper-slide-active .feedback__item__body {
        font-size: rem(16px);
        line-height: rem(21.79);
        max-height: rem(98);
    }

    .feedback__slider .swiper-slide-active .feedback__item__body.show {
        max-height: none;
        .feedback__item__body__inner {
            max-height: rem(300);
            overflow-y: auto;
        }
    }

    .feedback__slider .swiper-slide-active .feedback__item__footer {
        display: block;
    }

    .feedback__slider .swiper-slide-active .feedback__item__footer.hidden {
        display: none;
    }

    .feedback__slider .swiper-slide-active .feedback__item__avatar {
        width: rem(140px);
        height: rem(140px);
        margin: calc(-70px - 16px) auto rem(14px);
    }

    .feedback__item__title {
        font-size: rem(16px);
        margin-bottom: rem(2);
    }

    .feedback__slider .swiper-slide-active .feedback__item__title {
        font-size: rem(22px);
        margin-bottom: rem(8);
    }

    .feedback__item__subtitle {
        font-size: rem(13px);
    }

    .feedback__slider .swiper-slide-active .feedback__item__subtitle {
        font-size: rem(16px);
    }
    .feedback__slider .swiper-pagination {
        font-size: rem(18);
    }
    .feedback__slider__nav {
        margin-top: 0;
    }
}

@media (min-width: 1200px) {
    .feedback__item {
        padding: rem(16) rem(30);
    }
    .feedback__slider .swiper-slide-active .feedback__item {
        padding: rem(16) rem(35);
        width: 147.442%;
    }
    .feedback__slider .swiper-slide-duplicate-prev, .feedback__slider .swiper-slide-prev {
        padding: 0 10.8% 0 0;
    }
    .feedback__slider .swiper-slide-duplicate-next, .feedback__slider .swiper-slide-next {
        padding: 0 0 0 10.8%;
    }
    .feedback__slider .swiper-button-next,
    .feedback__slider .swiper-button-prev {
        position: absolute;
        margin-top: rem(-38px);
    }

    .feedback__slider .swiper-button-next,
    .feedback__slider.swiper-container-rtl .swiper-button-prev {
        right: rem(-40);
    }

    .feedback__slider .swiper-button-prev,
    .feedback__slider.swiper-container-rtl .swiper-button-next {
        left: rem(-40);
    }

}

@media (min-width: 1350px) {
    .feedback__slider .swiper-button-next,
    .feedback__slider.swiper-container-rtl .swiper-button-prev {
        right: rem(-60);
    }

    .feedback__slider .swiper-button-prev,
    .feedback__slider.swiper-container-rtl .swiper-button-next {
        left: rem(-60);
    }
}
/* mouse, touch pad */
@media (hover: hover) and (pointer: fine) {
    .feedback__slider .swiper-button-next:hover,
    .feedback__slider .swiper-button-prev:hover {
        color: $dark;
        opacity: 1;
    }
}

.panels-chart {
    border: 1px solid $border_color;
    padding: rem(20px);
    border-radius: rem(10px);
    margin: 0 0 rem(20px);
    background: $white;
    display: grid;
    grid-template-columns: 100%;
    gap: rem(20px);

    @media (min-width: 992px) {
        grid-template-columns: calc(100% - rem(360px)) rem(340px);
    }
}

.panels-chart__chart-summary {
    .info-list {
        margin: 0 rem(-20px) rem(-20px);

        @media (min-width: 992px) {
            margin: 0 rem(-20px) rem(-20px) 0;
        }
    }

    .info-list__accordion__inner {
        min-height: rem(28px);
    }

    .info-list__accordion__item_btn {
        .info-list__accordion__inner {
            padding: rem(2px) rem(20px) rem(2px) 0;
        }
    }

    .info-list__accordion__ico {
        margin: rem(-5px) 0;
    }

    .info-list__accordion__group_bg:last-child {
        padding-bottom: rem(18px);
    }
}

.panels-chart__summary-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid $border_color;
    min-height: rem(48px);

    @media (min-width: 768px) {
        margin: rem(-20px) rem(-20px) 0 0;
    }

    .header-tabs {
        margin: 0;
        gap: rem(24px);
    }
}


.panels-chart__chart-container {
    position: relative;
    height: rem(300px);

    @media (min-width: 768px) {
        height: rem(460px);
        .h-full-on-mobile {
            height: rem(460px);
        }
    }
    @media (max-width: 768px) {
        .h-full-on-mobile {
            height: 100%;
        }
    }
}

.panels-chart__configuration {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    .dropdown-menu {
        padding: rem(16px);
        border: 0;

        .input:last-child {
            margin: 0;
        }
    }
}

.panels-chart__configuration__btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: rem(8px);
    background: transparent;
    border: 0;
    color: $blue;
    font-size: rem(13px);
    line-height: 1.2;

    span {
        font-size: rem(16px);
    }
}

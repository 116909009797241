.header-tabs {
    display: flex;
    margin-left: 0;

    @media (min-width: 768px) {
        margin-left: rem(24px);
        gap: rem(24px);
    }
}

.header-tabs__btn {
    position: relative;
    color: $dark;
    font-size: rem(13px);
    line-height: 1.2;
    gap: rem(8px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: rem(38px);
    min-width: rem(38px);

    &::after {
        content: "";
        position: absolute;
        top: calc(100% + rem(3px));
        left: 0;
        width: 100%;
        height: 3px;
        border-radius: 2px;
        background: $blue;
        opacity: 0;
    }

    &.active {
        color: $blue;

        &::after {
            opacity: 1;
        }
    }
}

.header-tabs__ico {
    font-size: rem(16px);
}

.header-tabs__text {
    display: none;

    @media (min-width: 768px) {
        display: inline;
    }
}

body .select2-container--sd_select {
    .select2-selection--single {
        height: rem(42px);
        padding: rem(6px) rem(32px) rem(6px) rem(20px);
        border: 1px solid $border_color;
        border-radius: rem(8px);
        line-height: rem(30px);
        background: #fff;
        color: $title-color;
        font-size: rem(16px);

        .select2-selection__rendered {
            padding: 0;
        }
    }

    .select2-selection__arrow {
        display: block;
        position: absolute;
        right: rem(12px);
        top: 50%;
        margin-top: rem(-7px);
        width: rem(14px);
        height: rem(14px);
        background: url('/img/chevron-down.svg') no-repeat center center / rem(14px) rem(14px);
        opacity: .4;
    }

    .select2-dropdown {
        border: 0;
        border-radius: rem(8px);
        padding: rem(8px) 0;
        box-shadow: 0 8px 18px -6px rgba(24, 39, 75, .12), 0 12px 42px -4px rgba(24, 39, 75, .12);
        min-width: auto;
        z-index: 1000;

    }

    .select2-dropdown--below {
        border: 0;
        min-width: auto;
    }

    &.select2-container--open {
        .select2-dropdown {
            border: 0;
            min-width: auto;
        }

    }

    .select2-results__option {
        padding: rem(8px) rem(16px);

        &.select2-results__option--highlighted {
            background: $light_grey;
        }
    }

    &.sd_select_modal {
        z-index: 1060;
    }

    &.sd_select_add {
        z-index: 1060;

        .select2-search--dropdown {
            padding: 0;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: rem(12px);
                width: rem(16px);
                height: rem(16px);
                margin-top: rem(-8px);
                background: url('/img/plus.svg') no-repeat center center / rem(16px) rem(16px);
                pointer-events: none;
            }
        }

        .select2-search__field {
            width: 100%;
            border: 0;
            border-bottom: 1px solid $border_color;
            padding: rem(10px) rem(20px) rem(10px) rem(44px);
            min-height: rem(42px);
            font-size: rem(16px);
            border-radius: rem(8px) rem(8px) 0 0;

            &:focus {
                box-shadow: none;
            }
        }
    }
}

@media (min-width: 768px) {
    body .select2-container--sd_select {
        .select2-selection--single {
            font-size: rem(13px);
        }
    }
}



body .select2-container--sd_select_narrow {
    .select2-selection--single {
        height: rem(42px);
        padding: 0 rem(20px) 0 0;
        border: 0;
        border-radius: 0;
        line-height: rem(42px);
        background: transparent;
        color: $title-color;
        font-size: rem(13px);

        .select2-selection__rendered {
            padding: 0;
        }
    }

    .select2-selection__arrow {
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: rem(-5px);
        width: rem(14px);
        height: rem(14px);
        background: url('/img/chevron-down.svg') no-repeat center center / rem(14px) rem(14px);
        opacity: .4;
    }

    .select2-dropdown {
        border: 0;
        border-radius: rem(8px);
        padding: rem(8px) 0;
        box-shadow: 0 8px 18px -6px rgba(24, 39, 75, .12), 0 12px 42px -4px rgba(24, 39, 75, .12);
        min-width: rem(160px) !important;
        z-index: 1000;

    }

    .select2-dropdown--below {
        border: 0;
    }

    &.select2-container--open {
        .select2-dropdown {
            border: 0;
        }

    }

    .select2-results__option {
        padding: rem(8px) rem(16px);

        &.select2-results__option--highlighted {
            background: $light_grey;
        }
    }
}

@media (min-width: 768px) {
    body .select2-container--sd_select {
        .select2-selection--single {
            font-size: rem(13px);
        }
    }
}

.group-options {
    position: relative;

    .select2-selection--single {
        // text-transform: lowercase;
    }

    > .select2-container--sd_select_narrow {
        left: auto !important;
        right: 0;
        width: rem(160px);
    }
}

.group-options-title {
    margin-right: .25em;
    line-height: rem(42px);
    font-size: rem(13px);
}

.group-options-wrapper {
    display: flex;
    align-items: center;
}

.disabled .select2-selection {
    background: $grey-200 !important;
}

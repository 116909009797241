.modal-fullscreen {
    height: 100%;
    height: 100dvh;

    .modal-dialog {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
        max-height: none;
    }

    .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }

    .modal-header {
        min-height: rem(50px);
        padding: rem(20px);
        color: $white;
        border-radius: 0;
        background: linear-gradient(92.5deg, #425AF0 0.41%, #51B1FA 100%);
        border: 0;
        justify-content: flex-start;
        align-items: center;
    }

    .modal-back,
    .modal-close {
        width: rem(38px);
        height: rem(38px);
        background: transparent;
        color: $white;
        border: 0;
        padding: 0;
        font-size: rem(16px);
        margin: rem(-11px);
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    .modal-back {
        margin-right: 0;
    }

    .modal-close {
        margin-left: rem(16px);
        display: none;
    }

    .modal-title {
        margin: 0 rem(16px) 0 0;
        font-weight: 700;
        font-size: rem(16px);
        line-height: 1.2;
        flex: 1;
    }

    .modal-body {
        padding: rem(20px);
        line-height: 1.5;
        overflow-y: auto;

        .embed-responsive {
            margin: rem(-20px) rem(-20px) rem(20px) rem(-20px);
            width: auto;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    .modal-content_grey {
        background: $light_grey;

        .modal-footer {
            background: $white;
        }
    }

    .modal-footer {
        padding: rem(20px);
        border: 0;

        .btn-default {
            width: 100%;
        }
    }

    .modal-footer_center {
        justify-content: center;

        .btn-default {
            width: auto;
        }
    }

    .modal-footer > :not(:last-child) {
        margin-right: rem(8px);
    }

    .modal-footer > :not(:first-child) {
        margin-left: rem(8px);
    }

    .modal-header__inner {
        flex: 1;
        margin: 0 rem(16px) 0 0;

        .modal-title {
            margin: 0;
        }
    }

    .modal-footer_center {
        justify-content: center;

        .btn-default {
            width: auto;
        }
    }
}

@media (min-width: 576px) {
    .modal-fullscreen {
        .modal-dialog {
            max-width: rem(460px);
            min-height: calc(100% - 3.5rem);
            margin: 1.75rem auto;
            max-height: calc(100% - 3.5rem);
        }

        .modal-content {
            height: auto;
            border-radius: rem(8px);
        }

        .modal-header {
            border-radius: rem(8px) rem(8px) 0 0;
        }

        .modal-back:not(.modal-back_show) {
            display: none;
        }

        .modal-close {
            display: inline-flex;
        }
    }
}

.cost-price-modal {
    @media (max-width: 991px) {
        .modal-footer {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: rem(8px);

            > :not(:last-child) {
                margin-right: 0;
            }

            > :not(:first-child) {
                margin-left: 0;
            }

            .costprice-modal__add-date-footer {
                grid-column: span 2;
            }
        }
    }

    @media (min-width: 992px) {
        .modal-dialog {
            max-width: rem(600px);
        }

        .info-list__input_comment {
            display: none;
        }

        .modal-footer {
            .btn-default {
                width: auto;
            }
        }
    }
}


.redeem-create-modal .item__product-id [data-bm-tooltip]::after {
    right: auto;
    left: 0;
}


.logout-confirm {
    .modal-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.logout-confirm__title {
    color: $title-color;
    margin: auto 0;
    font-weight: 400;
}

.modal-fullscreen__select-all-btn {
    @media (min-width: 576px) {
        display: none;
    }
}
